const personalInfoInputs = (defaults) => {
    return {
        has_alergies: {
            elementType: "switch",
            elementConfig: {
                label: "آلرژی دارم",
                variant: "outlined",
                value: defaults.has_alergies,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        alergies: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                variant: "outlined",
                value: defaults.alergies ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    }}

    export default personalInfoInputs;