import { Grid, Button, makeStyles, IconButton } from "@material-ui/core";
import { ErrorOutline, DeleteOutline } from "@material-ui/icons";
import useRecordingsList from "../../Hooks/UseRecordingsList";
import "./styles.css";

const styles = makeStyles({
  cancelBtn: {
    backgroundColor: "#fee5ea",
    color: "#f62d56",
    marginRight: 5,
  },
});

export default function RecordingsList({ audio }) {
  const classes = styles();
  const { recordings, deleteAudio } = useRecordingsList(audio);
  return (
    <Grid container style={{marginTop: 20}}>
      {recordings && (
        <Grid item xs={12} style={{marginBottom: 50}}>
          <Grid container>
            <Grid
              item
              xs={11}
              style={{
                padding: "0 5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <audio
                style={{ width: "100%", borderRadius: 20 }}
                controls
                src={recordings.audio}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.cancelBtn}
                onClick={() => deleteAudio(recordings.key)}
              >
                <DeleteOutline color="secondary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
