import axios from "axios";

export const siteStatistics = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/contents/site-statistics")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });