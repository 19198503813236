import axios from "axios";

export const addTicketApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/ticket", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getTicketApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/ticket", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

    export const addMessageApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/ticket/message/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getMessagesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/ticket/message", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });