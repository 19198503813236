import React from "react";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MainContainer = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Grid container style={{ padding: isMobile ? "50px 20px" : "50px 10%" }}>
            {props.children}
        </Grid>
    );
};

export default MainContainer;
