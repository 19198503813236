import React from "react";
import {
    Grid,
    TextField,
    Card,
    CardContent,
    CardActions,
    Button,
} from "@material-ui/core";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

import useApi from "../../../../Hooks/UseApi";
import { getAllCatApi, addPostApi } from "../../../../API/product";
import articleInputs from "./articleInputs";
import formCreator from "../../../../shared/formCreator";
import getFormData from "../../../../shared/getFormData";

const SingleArticle = () => {
    const [formData, setFormData] = React.useState({});
    const [Categories, setCategories] = React.useState([]);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllCatApi);
        let cats = [];
        data.map((d) => {
            cats = [...cats, { label: d.name, value: d.id }];
        });
        setCategories(cats);
    }, []);

    const inputChangedHandler = (value, inputIdentifier) => {
        console.log(value, inputIdentifier);
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(
        articleInputs({ ...formData, Categories }),
        inputChangedHandler
    );

    const submitForm = () => {
        callApi(addPostApi, getFormData(formData));
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={9}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="عنوان"
                                    onChange={(e) =>
                                        inputChangedHandler(
                                            e.target.value,
                                            "title"
                                        )
                                    }
                                ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="خلاصه"
                                    multiline
                                    rows={2}
                                    onChange={(e) =>
                                        inputChangedHandler(
                                            e.target.value,
                                            "extra_content"
                                        )
                                    }
                                ></TextField>
                            </Grid>
                            <Grid item xs={12} style={{ direction: "ltr" }}>
                                <SunEditor
                                    setOptions={{
                                        height: 500,
                                        buttonList: [
                                            ["undo", "redo"],
                                            ["fontSize", "formatBlock"],
                                            ["bold", "underline", "italic"],
                                            ["fontColor", "hiliteColor"],
                                            [
                                                "align",
                                                "horizontalRule",
                                                "list",
                                                "table",
                                            ],
                                            ["link", "image"],
                                            [
                                                "fullScreen",
                                                "showBlocks",
                                                "codeView",
                                            ],
                                        ],
                                        //buttonList: buttonList.complex //[['font', 'align'], ['image']]
                                        // plugins: [font] set plugins, all plugins are set by default
                                        // Other option
                                    }}
                                    onChange={(content) =>
                                        inputChangedHandler(content, "content")
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Card>
                            <CardContent>{formElements}</CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}
                                >
                                    ذخیره
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SingleArticle;
