import React from "react";
import { Grid, TextField } from "@material-ui/core";
import moment from "moment-jalaali";
import { useDispatch } from "react-redux";

import Button from "../../../Components/UI/Buttons/Button";
import useApi from "../../../Hooks/UseApi";
import SelectInput from "../../../Components/UI/Inputs/SelectInput";
import { getMyRequestsApi } from "../../../API/request";
import { addTicketApi } from "../../../API/ticket";
import { hideDialog } from "../../../Redux/actions/dialogActions";

const AddTicket = ({ add, history }) => {
    const dispatch = useDispatch();
    const [data, loading, callApi] = useApi();
    const [requests, setRequests] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    React.useEffect(async () => {
        let data = await callApi(getMyRequestsApi);
        let req = data.map((req) => ({
            label: `${req.request_code} ( ${moment(
                req.created_at,
                "YYYY/MM/DD"
            ).format("jYYYY/jMM/jDD")} )`,
            value: req.id,
        }));
        setRequests(req);
    }, []);
    const handleChange = (value, id) => {
        setFormData({...formData, [id]: value});
    };

    const handleSubmit = async () => {
        let data = await callApi(addTicketApi, formData);
        history.push(`/dashboard/tickets/${data.data.id}`);
        dispatch(hideDialog());
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="عنوان"
                    variant="outlined"
                    onChange={(e) => handleChange(e.target.value, "title")}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SelectInput
                    fullWidth
                    options={requests}
                    style={{ fontFamily: "arial" }}
                    onChange={(event) => handleChange(event.target.value, "request_id")}
                    label="درخواست"
                    variant="outlined"
                />
            </Grid>
            <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <Button title="ایجاد تیکت" onClick={() => handleSubmit()} />
            </Grid>
        </Grid>
    );
};

export default AddTicket;
