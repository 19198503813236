import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { addMeasuresApi } from "../../../API/request";
import useApi from "../../Hooks/UseApi";
import formCreator from "../../shared/formCreator";
import Inputs from "./Inputs";

const ContactUsForm = () => {
    // const classes = styles();
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({ request_id: requestId });
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(Inputs(formData), inputChangedHandler);

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        // callApi(
        //     addMeasuresApi,
        //     formData,
        //     `/profile/request/${requestId}/alergies`
        // );
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {formElements}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                >
                    ارسال
                </Button>
            </Grid>
        </Grid>
    );
};

export default ContactUsForm;
