import React, { useState } from "react";
import { Container, Card, CardMedia, Typography, makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import DownloadIcon from "@material-ui/icons/GetApp";

import pdfFile from "../../../assets/images/pdfFile.png";
import file from "../../../assets/images/file.png";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "100%",
        padding: theme.spacing(0.5),
        borderRadius: "2px",
        boxShadow: "none",
        position: "relative",
    },
    image: {
        height: "70%",
        width: "100%",
        cursor: "pointer",
    },
    iconContainer: {
        position: "absolute",
        width: "98%",
        height: "70%",
        display: "flex",
    },
    icon: {
        margin: "auto",
        color: "#d9213f",
    },
    captionContainer: {
        width: "100%",
        textAlign: "right",
        padding: 0,
    },
    caption: {
        width: "100%",
        color: "#b8bdcc",
    },
}));

const FilePreview = (props) => {
    const classes = useStyles();
    const { src, label, type, icon, onClick, height } = props;
    const [remove, setRemove] = useState(false);
    let imageOpacity = remove ? 0.1 : 1;
    let finalSrc = src;

    // if (!type.includes("image")) {
    //     switch (type) {
    //         case "application/pdf":
    //             finalSrc = pdfFile;
    //             break;
    //         default:
    //             finalSrc = file;
    //             break;
    //     }
    // }

    return (
        <Card className={classes.container} style={{ maxHeight: height ?? 200 }}>
            {remove && (
                <Container className={classes.iconContainer}>
                    {icon === "download" ? (
                        <DownloadIcon className={classes.icon} style={{ color: "green" }} />
                    ) : (
                        <CancelIcon className={classes.icon} />
                    )}
                </Container>
            )}
            <CardMedia
                component="img"
                alt="uploaded"
                image={finalSrc}
                className={classes.image}
                style={{ opacity: imageOpacity }}
                onMouseEnter={() => setRemove(true)}
                onMouseLeave={() => setRemove(false)}
                onClick={onClick}
            />
            <Container className={classes.captionContainer}>
                <Typography variant="caption" noWrap className={classes.caption}>
                    {label}
                </Typography>
            </Container>
        </Card>
    );
};

export default FilePreview;
