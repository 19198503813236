import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showDialog } from "../../../Redux/actions/dialogActions";

import Button from "../../../Components/UI/Buttons/Button";
import RadioButton from "../../../Components/UI/Inputs/RadioButton";
import { fetchRequestData } from "../../../Redux/actions/requestActions";
import { checkoutApi, getAmountApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import Item from "./Item";
import PaymentTable from "../../../Components/Tables/PaymentTable";
import currencySeparator from "../../../shared/currencySeparator";
import styles from "../style";

const Payment = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const request = useSelector((state) => state.request);
  const { requestId } = useParams();
  const [formData, setFormData] = React.useState({
    request_id: requestId,
    days_per_week: 3,
  });
  const [amounts, setAmounts] = React.useState({
    exercise_amount: 0,
    diet_amount: 0,
    other_amount: 0,
    exercise_base_amount: 0,
  });

  const [data, loading, callApi] = useApi();
  // React.useEffect(async () => {
  // }, []);
  React.useEffect(async () => {
    let res = await callApi(getAmountApi, formData);
    setAmounts(res);
    dispatch(fetchRequestData(requestId));
  }, []);
  React.useEffect(() => {
    let hasOtherDiet = request?.types?.find(
      (item) => item.id === "NEW_YEAR" || item.id === "RAMEZAN"
    );
    console.table(request?.types);
    setFormData({
      ...formData,
      exercise: request.exercise,
      diet: request?.types,
      // diet: request.diet,
      // other_diet: hasOtherDiet ? true : false
    });
  }, [request]);

  const onChange = (item) => {
    setFormData({ ...formData, days_per_week: item });
  };

  const submitForm = async () => {
    let res = await callApi(checkoutApi, formData);
    let cartNumber = "5022291331184505";
    let whatsappNumber = "09111368417";
    let text = `لطفا جهت تکمیل پرداخت مبلغ ${currencySeparator(
        res.amount
      )} تومان به شماره کارت ${cartNumber} به نام فهیمه جمالی واریز و فیش واریزی را به شماره واتساپ ${whatsappNumber} ارسال نمایید`
    dispatch(
      showDialog(
        <Typography variant="h3" component="h3" >{text}</Typography>,
        true,
        "md"
      )
    );
    // window.location = res.redirect_uri;
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              پرداخت
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Item title="برنامه تمرین" value={formData.exercise} />
                  </Grid>
                  <Grid item xs={6}>
                    <Item title="رژیم غذایی" value={formData.diet} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                {formData.exercise ? (
                  <RadioButton
                    fullWidth
                    options={[
                      { value: "3", label: "3 جلسه" },
                      { value: "4", label: "4 جلسه" },
                      {
                        value: "5",
                        label: "5 جلسه یا بیشتر",
                      },
                    ]}
                    id="perWeek"
                    label="تعداد جلسات تمرین در هفته"
                    value={formData.days_per_week}
                    onChange={(item) => onChange(item)}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <PaymentTable
                  data={formData}
                  amounts={amounts}
                  daysPerWeek={formData.days_per_week}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              fullWidth
              disableElevation
              onClick={submitForm}
              variant="contained"
              color="primary"
              title="پرداخت"
              loading={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: "red" }}>
              نکته: مبلغ دریافتی در ازای طراحی رژیم برای ۱ ماه میباشد
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Payment;
