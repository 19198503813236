import React from "react";
import { Grid, Button, TextField, MenuItem } from "@material-ui/core";
import { Stage, Layer, Line, Text } from "react-konva";

import URLImage from "./URLImage";
import image1 from "../../assets/images/forms.jpg";
import axios from "axios";
import { useHistory } from "react-router";

const Drowing = ({ image, templateId, reponsesId }) => {
    const history = useHistory();
    const stageRef = React.useRef(null);
    const [tool, setTool] = React.useState("pen");
    const [lines, setLines] = React.useState([]);
    const isDrawing = React.useRef(false);

    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (e) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };
    const handleExport = () => {
        const uri = stageRef.current.toDataURL();
        console.log(uri);

        ////////////////////////
        const file = DataURIToBlob(uri);
        const formData = new FormData();
        formData.append("response", file, "image.png");
        formData.append("request_id", reponsesId); //other param
        formData.append("template_id", templateId); //other param
        axios
            .post("/requests/response/", formData)
            .then((response) => {
                console.log(response);
                history.goBack();
            })
            .catch((error) => {
                console.log(error.response);
            });
        /////////////////////////

        // we also can save uri as file
        // but in the demo on Konva website it will not work
        // because of iframe restrictions
        // but feel free to use it in your apps:
        // downloadURI(uri, "form.jpg");
    };

    function DataURIToBlob(dataURI) {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i);

        return new Blob([ia], { type: mimeString });
    }

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const penTypes = [
        { value: "pen", label: "قلم" },
        { value: "eraser", label: "پاک کن" },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} style={{ padding: "0 7.5%" }}>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            select
                            label="نوع قلم"
                            value={tool}
                            onChange={(e) => setTool(e.target.value)}
                        >
                            {penTypes.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={handleExport}
                        >
                            ذخیره
                        </Button>
                    </Grid>
                </Grid>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                    }}
                >
                    <Stage
                        width={window.innerWidth - 400}
                        height={1800}
                        onMouseDown={handleMouseDown}
                        onMousemove={handleMouseMove}
                        onMouseup={handleMouseUp}
                        ref={stageRef}
                    >
                        <Layer>
                            <URLImage src={image} x={0} />
                        </Layer>
                        <Layer>
                            {lines.map((line, i) => (
                                <Line
                                    key={i}
                                    points={line.points}
                                    stroke="#df4b26"
                                    strokeWidth={1}
                                    tension={0.5}
                                    lineCap="round"
                                    globalCompositeOperation={
                                        line.tool === "eraser"
                                            ? "destination-out"
                                            : "source-over"
                                    }
                                />
                            ))}
                        </Layer>
                    </Stage>
                </div>
            </Grid>
        </Grid>
    );
};

export default Drowing;
