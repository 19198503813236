import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { showDialog } from "../../../Redux/actions/dialogActions";
import useApi from "../../../Hooks/UseApi";
import { getTicketApi } from "../../../API/ticket";
import DataTable from "../../../Components/Tables/DataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../Components/UI/Buttons/AddButton";
import translator from "../../../shared/translator";
import AddTicket from "./AddTicket";

const Tickets = () => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    // const [page, setPage] = React.useState(1);
    const [tickets, setTickets] = React.useState([]);
    const queryString = window.location;
    const url = new URL(queryString);
    const page = url.searchParams.get("page");
    const HeaderData =
        user.role === "user"
            ? [
                  { field: "ticket_number", title: "شماره تیکت" },
                  { field: "title", title: "عنوان" },
                  { field: "status", title: "وضعیت" },
                  { field: "created_at", title: "تاریخ", type: "dateTime" },
                  { field: "action", title: "مشاهده" },
              ]
            : [
                { field: "ticket_number", title: "شماره تیکت" },
                { field: "title", title: "عنوان" },
                { field: "fixName", title: "نام" },
                { field: "mobile", title: "موبایل" },
                { field: "status", title: "وضعیت" },
                { field: "created_at", title: "تاریخ", type: "dateTime" },
                { field: "action", title: "مشاهده" },
              ];

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getTicketApi, { page });
        setTickets(data);
    }, [page]);
    const showModal = () => {
        dispatch(showDialog(<AddTicket history={history} add={(item) => AddNewTicket(item)} />, false, "md"));
    };
    const AddNewTicket = (item) => {};
    return (
        <Grid container>
            <Grid item xs={12}>
                <AddButton title="تیکت جدید" onClick={() => showModal()} />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title="لیست تیکت ها"
                    headData={HeaderData}
                    data={tickets.data ?? []}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                show: () => history.push(`/dashboard/tickets/${item.id}`),
                            }}
                            show={true}
                        />
                    )}
                    state={(item) => translator(item.status)}
                    fixName={(item) => `${item.name} ${item.surname}`}
                    lastPage={tickets.meta?.last_page ?? 1}
                    currentPage={page}
                    route="/dashboard/tickets"
                />
            </Grid>
        </Grid>
    );
};

export default Tickets;
