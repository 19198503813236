import axios from "axios";

export const getUsersApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/admin/users", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getBlockedUsersApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/admin/blocked-users", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const triggerBlockUsersApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/users/block/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getUserProfileApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/admin/user_profile", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

    export const resetPasswordApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/users/reset_password/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });