import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { showAlert } from "../Redux/actions/snackbarActions";
import {
    showLoading,
    hideLoading,
    showBtnLoading,
    hideBtnLoading,
} from "../Redux/actions/loadingActions";
import { addPersonalApi } from "../API/profile";
import { Typography } from "@material-ui/core";

const useApi = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [Data, setData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const callApi = (func, data, redirect, routeData, fullLoading) => {
        setLoading(true);
        if(fullLoading){
            dispatch(showLoading())
        }
        return func(data)
            .then((res) => {
                setLoading(false);
                dispatch(hideLoading())
                if (redirect) {
                    if (redirect === "back"){
                        history.goBack();
                    } else {
                        history.push({
                            pathname: redirect,
                            state: routeData,
                        });
                    }
                }
                return res;
            })
            .catch((err) => {
                setLoading(false);
                dispatch(hideLoading())
                if (err?.data.errors) {
                    let errorData = Object.keys(err.data.errors).map(error => (
                        <Typography>{err.data.errors[error]}</Typography>
                    ));
                    dispatch(showAlert( errorData, "error"));
                }
                // dispatch(showAlert( err ? err.data.message : "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.", "error"));
                console.log(err);
            });
    };
    return [Data, loading, callApi];
};

export default useApi;
