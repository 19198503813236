import React from "react";
import {
    makeStyles,
    IconButton,
    Button,
    Typography,
    Collapse,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useLocation, NavLink, useHistory } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    active: {
        backgroundColor: "#bbee92 !important",
        borderLeft: "solid 10px #6cb532",
    },
    extendable: {
        backgroundColor: "#bbee92 !important",
        paddingLeft: 10,
    },
}));

const MenuItem = ({ data, Icon, active, externalLink }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);

    const handleClick = (path) => {
        history.push(path);
        setOpen(!open);
    };
    React.useEffect(() => {
        if (location.pathname.includes(data.path)) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    });
    const createUrlParams = (params) => {
        let UrlParams = "";
        Object.keys(params).map(param => {
            UrlParams = UrlParams + `?${param}=${params[param]}`
        })
        return(UrlParams)
    }
    return data.extendable === undefined ? (
        <ListItem
            button
            classes={{ selected: classes.active }}
            selected={active}
            component={NavLink}
            target={externalLink ? "_blank" : ""}
            to={{
                pathname: data.path,
                search: data.params ? createUrlParams(data.params) : "",
                state: { fromDashboard: true }
               }}
        >
            <ListItemIcon>
                <Icon style={{ width: 40, fontSize: 30 }} />
            </ListItemIcon>
            <ListItemText primary={data.name} />
        </ListItem>
    ) : (
        <>
            <ListItem
                button
                classes={{ selected: classes.active }}
                selected={location.pathname.includes(data.path)}
                onClick={() => handleClick(data.path)}
            >
                <ListItemIcon>
                    <Icon style={{ width: 40, fontSize: 30 }} />
                </ListItemIcon>
                <ListItemText primary={data.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List
                    component="div"
                    disablePadding
                    className={classes.extendable}
                >
                    {data.extendable.map((ExtendItem) => (
                        <ListItem
                            button
                            classes={{ selected: classes.active }}
                            selected={location.pathname === ExtendItem.path}
                            component={NavLink}
                            to={ExtendItem.path}
                        >
                            <ListItemIcon>
                                <ExtendItem.Icon style={{ width: 40, fontSize: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={ExtendItem.name} />
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

const Menu = ({ nav }) => {
    const location = useLocation();
    const classes = useStyles();
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            {nav.map(
                (item) =>
                    item.show && (
                        <MenuItem
                            data={item}
                            Icon={item.icon}
                            // active={location.pathname === item.path}
                            active={location.pathname.includes(item.path)}
                            externalLink={item.external}
                        />
                    )
            )}
        </List>
    );
};

export default Menu;
