import axios from "axios";

export const addPersonalApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/personal/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getPersonalApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/personal")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addMarridInfoApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/marriage/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getMarridInfoApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/marriage/")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addWomenInfoApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/women/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getWomenInfoApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/women/")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addRoutineApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/routine/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getRoutineApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/routine/")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addGoalApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/goal/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getGoalApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/goal/")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addDietApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/diet/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getDietApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/diet/")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const updateProfileApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/user_profile/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });
