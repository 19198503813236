import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import HomeIcon from "@material-ui/icons/Home";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import AssignmentIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 9999,
    boxShadow: "0 0 10px 3px #0000004d",
    "& .Mui-selected": {
      color: "#559B1C",
    },
  },
});

export default function SimpleBottomNavigation() {
  const user = useSelector((state) => state.user);
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState("/");
  const changeTab = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };
  return (
    <BottomNavigation
      value={value}
      onChange={changeTab}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction value="/" label="صفحه نخست" icon={<HomeIcon />} />
      <BottomNavigationAction
        value={
          user.confirm_profile
            ? "/profile/request/new/types"
            : "/profile/personal-info"
        }
        label="ثبت درخواست"
        icon={<AssignmentIcon />}
      />
      <BottomNavigationAction
        value="/dashboard/diet-requests"
        label="مشاهده رژیم"
        icon={<OndemandVideoIcon />}
      />
    </BottomNavigation>
  );
}
