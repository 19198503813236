import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import Accordion from "../../../Components/Accordion/Accordion";

import Button from "../../../Components/UI/Buttons/Button";
import { getAllRequestTypesApi, addTypesApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import styles from "../style";
import AccordionContent from "./AccordionContent";

const Diet = () => {
    const classes = styles();
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({
        request_id: requestId,
        types: [],
    });
    const [types, setTypes] = React.useState([]);
    React.useEffect(() => {
        getAllRequestTypesApi()
            .then((res) => {
                console.log({ res });
                setTypes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const selectItem = (id, active) => {
        let types 
        if (!active) {
            types = [...formData.types, id];
        } else {
            types = formData.types.filter(type => type !== id);
        }
        setFormData({ ...formData, types });
    };

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(addTypesApi, formData, `/profile/request/${requestId}/measures`);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            نوع درخواست
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion
                            data={types}
                            contentField="types"
                            content={(item) => <AccordionContent addType={(id, active) => selectItem(id, active)} data={item} />}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Diet;
