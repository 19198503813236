import {
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Inputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام",
                variant: "outlined",
                value: defaults.name,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        surname: {
            elementType: "text-field",
            elementConfig: {
                label: "نام خانوادگی",
                variant: "outlined",
                value: defaults.surname,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        mobile: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره موبایل",
                variant: "outlined",
                value: defaults.mobile,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        password: {
            elementType: "text-field",
            elementConfig: {
                label: "رمز عبور",
                variant: "outlined",
                value: defaults.password,
                InputProps: {
                    endAdornment: (
                        <InputAdornment
                            style={{ margin: 0 }}
                            position="start"
                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    defaults.setShowPass(!defaults.showPass)
                                }
                            >
                                {defaults.showPass ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
                type: defaults.showPass ? "text" : "password"
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        password_confirmation: {
            elementType: "text-field",
            elementConfig: {
                label: "تکرار رمز عبور",
                variant: "outlined",
                value: defaults.password_confirmation,
                InputProps:{
                    endAdornment: (
                        <InputAdornment
                            style={{ margin: 0 }}
                            position="start"
                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    defaults.setShowRePass(!defaults.showRePass)
                                }
                            >
                                {defaults.showRePass ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
                type: defaults.showRePass ? "text" : "password"
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
    };
};

export default Inputs;
