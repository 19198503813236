import React from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Lightbox from "react-image-lightbox";

import CustomAccordion from "../../../Components/Surfaces/CustomAccordion/CustomAccordion";
import RequestDetiles from "../SingleRequest/RequestDetiles";
import RequestDetilesImages from "../SingleRequest/RequestDetilesImages";

import { getAllGroupsApi } from "../../../API/Forms";
import { getSummaryApi, getResponsesApi } from "../../../API/response";
import { getMyRequestsApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import { showDialog, hideDialog } from "../../../Redux/actions/dialogActions";
import AudioItem from "./components/AudioItem";

const styles = makeStyles({
  title: {
    margin: 20,
  },
  summaryContainer: {
    border: "solid 1px #ccc",
    margin: 10,
    padding: 10,
  },
  summary: {
    margin: 10,
    padding: 10,
  },
  dietForms: {
    backgroundColor: "#4B778D",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  exerciseForms: {
    backgroundColor: "#28B5B5",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  recomendationForms: {
    backgroundColor: "#8FD9A8",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  prescriptionForms: {
    backgroundColor: "#D2E69C",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
});

const SingleReponse = () => {
  const classes = styles();
  const { requestId } = useParams();
  const history = useHistory();
  //   const [open, setOpen] = React.useState(false);
  //   const [photoIndex, setPhotoIndex] = React.useState(0);
  //   const [images, setImages] = React.useState([]);

  //   const openImage = (index) => {
  //     setOpen(!open);
  //     setPhotoIndex(index);
  //   };

  const [Responses, setResponses] = React.useState({});

  const [data, loading, callApi] = useApi();
  React.useEffect(async () => {
    let responsesData = await callApi(getResponsesApi, { id: requestId });
    setResponses(responsesData);
    console.log({ responsesData });
    // let imagesData = [];
    // responsesData.map((image) => {
    //   imagesData = [...imagesData, image.path];
    // });
    // setImages(imagesData);
  }, []);

  const dispatch = useDispatch();

  const handleNavigate = (formId) => {
    dispatch(hideDialog());
    history.push(`/dashboard/reponses/${requestId}/form/${formId}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h3" component="h3">
                  صوتها
                </Typography>
              </Grid>
              {Responses?.response_audio?.length === 0 &&
                "هیچ صوتی برای این درخواست ثبت نشده است"}
              {Responses?.response_audio?.map((Audio, index) => (
                <AudioItem path={Audio.path} key={index} />
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Typography variant="h3" component="h3">
                  تصاویر
                </Typography>
              </Grid>
              {Responses?.response?.length === 0 &&
                "هیچ تصویری برای این درخواست ثبت نشده است"}
              {Responses?.response?.map((Response, index) => (
                <Grid item md={6} xs={12}>
                  <a href={Response.path} target="_blank">
                    <img
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        aspectRatio: "3/2",
                        objectFit: "cover",
                      }}
                      src={Response.path}
                    />
                  </a>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SingleReponse;
