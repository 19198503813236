import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import MuiSnackbar from "@material-ui/core/Snackbar";
import { hideAlert } from "../../Redux/actions/snackbarActions";

const Snackbar = () => {
    const dispatch = useDispatch();
    const alert = useSelector((store) => store.snackbar);

    return (
        <MuiSnackbar
            open={alert.show}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => dispatch(hideAlert())}
        >
            <Alert variant="filled" elevation={6} severity={alert.alertType}>
                {alert.alertMsg}
            </Alert>
        </MuiSnackbar>
    );
};

export default Snackbar;
