import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import BlockOutlinedIcon from "@material-ui/icons/BlockOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import Add from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core";

const Styles = makeStyles({
    actionButton: {
        margin: "0 5px",
        minWidth: "unset",
    },
    userInfoButton: {
        backgroundColor: "#ccf9ee",
        color: "#03debb",
        width: "75%",
        "&:hover": {
            backgroundColor: "#ccf9ee",
        },
    },
});

const ActionButtons = ({ block, unblock, remove, edit, show, add, resetPass, functions }) => {
    const classes = Styles();
    return (
        <div>
            {block && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#f9e0e0", color: "#c80101" }}
                    onClick={(e) => functions.block()}
                    title="بلاک"
                >
                    <BlockOutlinedIcon />
                </IconButton>
            )}

            {unblock && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#ccf9ee", color: "#03debb" }}
                    onClick={(e) => functions.unblock()}
                    title="آنبلاک"
                >
                    <CheckCircleOutlineIcon />
                </IconButton>
            )}

            {edit && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#e0f7fb", color: "#03b9de" }}
                    onClick={(e) => functions.edit()}
                    title="ویرایش"
                >
                    <EditOutlinedIcon />
                </IconButton>
            )}

            {remove && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#fee5ea", color: "#f62d56" }}
                    onClick={(e) => functions.remove()}
                    title="حذف"
                >
                    <DeleteOutlinedIcon />
                </IconButton>
            )}

            {show && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#BBEE92", color: "#559F19" }}
                    onClick={(e) => functions.show()}
                    title="مشاهده"
                >
                    <RemoveRedEyeIcon />
                </IconButton>
            )}

            {add && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#7FB5FF", color: "#fff" }}
                    onClick={(e) => functions.add()}
                    title="اضافه کردن درخواست"
                >
                    <Add />
                </IconButton>
            )}

            {resetPass && (
                <IconButton
                    className={classes.actionButton}
                    style={{ backgroundColor: "#FFC54D", color: "#fff" }}
                    onClick={(e) => functions.resetPass()}
                    title="تغییر پسورد"
                >
                    <LockOpenOutlinedIcon />
                </IconButton>
            )}
        </div>
    );
};

export default ActionButtons;
