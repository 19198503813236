import React from "react";
import { Card, CardContent, Grid, Typography, TextField } from "@material-ui/core";

import { getAllRequestTypesApi, addTypesApi } from "../../../../API/request";
import Accordion from "../../../../Components/Accordion/Accordion";
import AccordionContent from "./AccordionContent";

const DietTypes = (props) => {
    const [types, setTypes] = React.useState([]);
    React.useEffect(() => {
        getAllRequestTypesApi()
            .then((res) => {
                console.log({ res });
                setTypes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const selectItem = (id, active) => {
        props.selectItem(id, active)
        // setFormData({ ...formData, types: [...formData.types, id] });
    };
    return (
        <Grid item xs={12}>
            <Accordion data={types} contentField="types" content={(item) => <AccordionContent selected={props.data} addType={(id, active) => selectItem(id, active)} data={item} />} />
        </Grid>
    );
}

export default DietTypes;
