import React from "react";
import { Grid } from "@material-ui/core";


import Tab from "../../../Components/Tabs/Tab";
import BlockedUsers from "./BlockedUsers";
import Users from "./Users";

const UsersContainer = () => {
    const tabData = [
        { title: "کاربران", component: <Users /> ,route: "/dashboard/users/unBlocked"},
        { title: "کاربران بلاک شده", component: <BlockedUsers />,route: "/dashboard/users/blocked" },
    ];
    return (
        <Grid container>
            <Grid item xs={12}>
                <Tab data={tabData} />
            </Grid>
        </Grid>
    );
};

export default UsersContainer;
