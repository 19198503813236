import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, InputLabel, FormControl, Select, FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
}));

const SelectInput = (props) => {
    const classes = useStyles();
    const { label, options, value, onChange, ...otherProps } = props;

    return (
        <FormControl className={classes.formControl} {...otherProps}>
            <InputLabel style={{ backgroundColor: "#fff", paddingRight: 5 }}>
                {label}
            </InputLabel>
            <Select value={value} onChange={onChange} variant="outlined">
                {options.map((option, index) => (
                    <MenuItem value={option.value} key={index}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{otherProps.helperText}</FormHelperText>
        </FormControl>
    );
};

export default SelectInput;
