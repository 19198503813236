import React from "react";
import { Grid, Button, Typography, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { hideDialog } from "../../../Redux/actions/dialogActions";
import useApi from "../../../Hooks/UseApi";
import { resetPasswordApi } from "../../../API/users";
import { showAlert } from "../../../Redux/actions/snackbarActions";

const ChangePassword = ({ id, userName }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({});
    const [syncError, setSyncError] = React.useState(false);
    const changeInput = (e) => {
        setSyncError(false);
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };
    const [data, loading, callApi] = useApi();
    const submitForm = async () => {
        if (formData.password === formData.repeatPassword) {
            let data = await callApi(resetPasswordApi, { id, newPasswod: formData.password });
            if (data) {
                dispatch(showAlert("رمز عبور با موفقیت تغییر کرد", "success"));
            }
            dispatch(hideDialog());
        } else {
            setSyncError(true);
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h3" component="h3" style={{ padding: 10, fontFmily: "IRANSansBold", textAlign: "center", marginBottom: 20 }}>
                    تغییر رمز عبور {userName}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            id="password"
                            label="رمز عبور جدید"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            value={formData.password}
                            onChange={changeInput}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="repeatPassword"
                            label="تکرار رمز عبور جدید"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            value={formData.repeatPassword}
                            onChange={changeInput}
                            helperText={syncError ? "رمز عبور با تکرار آن برابر نیست!" : ""}
                            error={syncError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth disableElevation onClick={() => dispatch(hideDialog())} variant="contained" color="secondary">
                            بستن
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth disableElevation onClick={submitForm} variant="contained" color="primary">
                            ذخیره
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;
