import axios from "axios";

export const getVideosApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/contents/videos/", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addVideoApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/contents/videos", data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });
