import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  direction: "rtl",
  typography: {
    fontFamily: "IRANSans, tahoma",
    color: "#5b5b5b",
  },
  palette: {
    primary: { main: "#BBEE92" },
    secondary: { main: "#ff4081" },
    // textSecondary: {main: "#5b5b5b"}
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "0.875rem",
      },
    },
    MuiButton: {
        root: {
            height: 50,
            fontFamily: "IRANSans, tahoma",
        }
    },
    MuiTypography: {
      h1: {
        fontSize: 50,
        fontFamily: "IRANSansBold"
      },
      h2: {
        fontSize: 30,
        fontFamily: "IRANSansBold"
      },
      h3: {
        fontSize: 20,
        fontFamily: "IRANSansBold"
      },
      h4: {
        fontSize: 18,
        fontFamily: "IRANSansBold"
      },
      h5: {
        fontSize: 16,
        fontFamily: "IRANSansBold"
      },
      h6: {
        fontSize: 16,
        fontFamily: "IRANSans",
        color: "#5b5b5b",
      },
      p: {
        fontSize: 16,
        fontFamily: "IRANSans"
      }
    },
    MuiCard: {
      root: {
        boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.25)"
      }
    }
  },
});
