import { dateFixer } from "./toEnNumber";

const getFormData = (object, mapper) => {
    const formData = new FormData();

    for (let key in object) {
        console.log(object, object[key])
        if (object[key] === undefined || object[key] === null || object[key] === "" || object[key] === "null") continue;
        if (object[key]?._isAMomentObject) {
            formData.append(key, dateFixer(object[key]));
            continue;
        }
        if (typeof object[key] === "object" && object[key] !== null && !(object[key] instanceof File || object[key] instanceof Blob)) {
            if (Array.isArray(object[key])) {
                object[key].forEach((item) => formData.append(`${key}[]`, item ?? ''));
            } else {
                for (const objectKey in object[key]) {
                    formData.append(key + `[${objectKey}]`, object[key][objectKey] ?? '');
                }
            }
            continue;
        }
        let finalKey = key;
        if (mapper) finalKey = mapper(key);
        formData.append(finalKey, object[key] ?? '');
    }
    return formData;
};

export default getFormData;