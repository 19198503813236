import React from "react";
import {
    Card,
    CardActionArea,
    Grid,
    Typography,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    makeStyles,
} from "@material-ui/core";

import { getTemplatesApi } from "../../../API/Forms";
import useApi from "../../../Hooks/UseApi";

const styles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 200,
    },
});

const ModalForms = ({ groupId, navigate }) => {
    const [data, loading, callApi] = useApi();
    const [templates, setTemplates] = React.useState([]);
    React.useEffect(async () => {
        let data = await callApi(getTemplatesApi, { group_id: groupId });
        setTemplates(data.data);
    }, []);
    const classes = styles();
    return (
        <Grid container spacing={3}>
            {templates.map((form) => (
                <Grid item xs={3}>
                    <Card>
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={form.path}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h5"
                                >
                                    {form.name}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => navigate(form)}
                            >
                                انتخاب
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default ModalForms;
