const Inputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "عنوان",
                variant: "outlined",
                value: defaults.name,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        template: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "بارگذاری فرم",
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.template,
        },
    };
};

export default Inputs;
