const Inputs = (defaults) => {
    return {
        back_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "پشت با دست باز",
            },
            gridConfig: {
                xs: 6,
            },
            value: defaults.back_image,
        },
        front_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "جلو",
            },
            gridConfig: {
                xs: 6,
            },
            value: defaults.front_image,
        },
        side_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "بغل",
            },
            gridConfig: {
                xs: 6,
            },
            value: defaults.side_image,
        },
        front_open_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "جلو با دست باز",
            },
            gridConfig: {
                xs: 6,
            },
            value: defaults.front_open_image,
        },
        side_open_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "بغل با دست باز",
            },
            gridConfig: {
                xs: 6,
            },
            value: defaults.side_open_image,
        },
    };
};

export default Inputs;
