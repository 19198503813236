import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Title from "../UI/Title";
import MainContainer from "../Container/MainContainer";

const Section2 = () => {
    return (
        <MainContainer>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <Title label="چرا رادایت؟" />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            component="h6"
                            style={{ textAlign: "justify" }}
                        >
                            وقتی صحبت از تناسب اندام میشه، لزوم مثلث تندرستی
                            یعنی رژیم غذایی، فعالیت بدنی و سلامت روان خودنمایی
                            میکنه. ما در رادایت، برای شما برنامه ریزی میکنیم، تا
                            در کمترین زمان ممکن و به سالم ترین حالت ممکن به
                            تناسب اندام مطلبوبتون برسین. داشتن یه پروفایل کامل
                            از شما به ما کمک میکنه که با ارزیابی و تحلیل شاخص
                            های تن سنجی، سابقه پزشکی و بیماری، میزان فعالیت بدنی
                            و در صورت ورزشکار بودن نوع تمریناتی که به صورت روتین
                            انجام میدین، یه رژیم غذایی منحصر بفرد برای شما طراحی
                            کنیم. ورزشکارانی که در رادایت عوض میشن این امکان رو
                            دارن که از ما برنامه تمرینی هم دریافت کنن. من،
                            امیرحسین راد، مشاور تغذیه و رژیم درمانی، فیزیولوژیست
                            ورزش و مربی رسمی فدراسیون بدنسازی در رادایت، همسفر
                            شما در مسیر سلامت و تناسب اندام شما هستم.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default Section2;
