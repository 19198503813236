import React from "react";
import { Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import { getTemplatesApi } from "../../../API/Forms";
import useApi from "../../../Hooks/UseApi";
import Drowing from "../../../Components/Drowing/Drowing";

const WriteForm = () => {
    const { templateId, reponsesId } = useParams();
    const [template, setTemplate] = React.useState(null);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getTemplatesApi, { template_id: templateId });
        console.log({ data });
        setTemplate(data);
    }, []);
    return (
        <Grid container>{template && <Drowing templateId={templateId} reponsesId={reponsesId} image={template?.path} />}</Grid>
    );
};

export default WriteForm;
