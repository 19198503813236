import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { useParams } from "react-router";

import CustomAccordion from "../../../Components/Surfaces/CustomAccordion/CustomAccordion";
import RequestProfileDetiles from "./RequestProfileDetiles";
import RequestDetiles from "./RequestDetiles";
import RequestDetilesImages from "./RequestDetilesImages";
import RequestDetilesForms from "./RequestDetilesForms";
import PastRequestes from "./PastRequestes";
import SendNotification from "./SendNotification";
import useApi from "../../../Hooks/UseApi";
import { getMyRequestsApi, addSummaryApi } from "../../../API/request";

const SingleRequest = () => {
    const { requestId } = useParams();
    const [request, setRequest] = React.useState({});
    const [formData, setFormData] = React.useState({ request_id: requestId });

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getMyRequestsApi, { id: requestId });
        setRequest(data.data);
    }, []);
    const changeInput = (e) => {
        setFormData({ ...formData, summary: e.target.value });
    };

    const submitForm = () => {
        callApi(addSummaryApi, formData, "/dashboard/requests");
    };

    return (
        <Grid container spacing={3}>
            <CustomAccordion title="ارسال نقص اطلاعات" active={false}>
                <SendNotification data={request} />
            </CustomAccordion>
            <CustomAccordion title="مشخصات پروفایل" active={false}>
                <RequestProfileDetiles data={request?.user ?? {}} />
            </CustomAccordion>

            <CustomAccordion title="سوابق قبلی" active={false}>
                {request?.user && <PastRequestes requestId={request.id} mobile={request?.user?.mobile} />}
            </CustomAccordion>

            <CustomAccordion title="مشخصات درخواست" active={false}>
                <RequestDetiles data={request} />
            </CustomAccordion>

            <CustomAccordion title="تصاویر" active={false}>
                <RequestDetilesImages data={request} />
            </CustomAccordion>

            {/* <CustomAccordion title="فرم ها" active={false}>
                <RequestDetilesForms request={request} />
            </CustomAccordion> */}
            
            <Grid item xs={12}>
                <TextField
                    label="خلاصه وضعیت"
                    variant="outlined"
                    fullWidth
                    onChange={changeInput}
                    multiline
                    rows={5}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={submitForm}
                    color="primary"
                >
                    ذخیره
                </Button>
            </Grid>
        </Grid>
    );
};

export default SingleRequest;
