import React from "react";
import {Grid, Typography} from "@material-ui/core";

const SingleField = ({label, value}) => {
    return (
        <Grid item xs={3} style={{ display: "flex" }}>
            <Typography style={{ fontFamily: "IRANSansBold" }}>
                {`${label} : `}
            </Typography>
            <Typography>{value}</Typography>
        </Grid>
    );
};

export default SingleField;
