import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Title from "./FooterTitle";
import FooterSocial from "../SocialMedia/footerSocial";
import enamad from "../../assets/images/enamad.jpg";
import samandehi from "../../assets/images/samandehi.png";

const style = makeStyles({
    footerContainer: {
        backgroundColor: "#2F2F2F",
        padding: "50px 10%",
    },
    footerCol: {
        paddingRight: 30,
    },
    abouteUsText: {
        color: "#fff",
        lineHeight: 2,
        textAlign: "justify",
    },
    footerLink: {
        display: "flex",
        width: "100%",
        textDecoration: "none",
        color: "#fff",
        fontFamily: "IRANSansBold",
        fontSize: 18,
        marginBottom: 10,
    },
    namadContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    enamad: {
        borderRadius: 25,
        width: 170,
    },
});
const Footer = () => {
    const classes = style();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Grid item xs={12} className={classes.footerContainer}>
            <Grid container>
                <Grid item md={4} xs={12} className={classes.footerCol}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Title label="رادایت" />
                            <Typography
                                component="p"
                                variant="p"
                                className={classes.abouteUsText}
                            >
                                امیرحسین خلخالی راد مشاور تغذیه، رژیم درمانگر
                                بالینی و ورزشی، فیزیولوژیست ورزش، مربی رسمی
                                فدراسیون بدنسازی جمهوری اسلامی ایران، محقق و
                                دارای بیش از ۱۵ عنوان مقاله داخلی و خارجی
                                (مدلاین و isi) در زمینه سندرم متابولیک و بیماری
                                های مرتبط با چاقی، شماره نظام پزشکی ۴۷۶۲-ت عضو
                                هیئت تحریریه نشریه تناسب
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Title label="شبکه های اجتماعی"></Title>
                            <FooterSocial />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12} className={classes.footerCol}>
                    <Title label="دسترسی سریع" />
                    <Link className={classes.footerLink} to="/aboute-us">
                        درباره ما
                    </Link>
                    <Link className={classes.footerLink} to="/contact-us">
                        تماس با ما
                    </Link>
                    <Link className={classes.footerLink} to="/video-categories">
                        ویدیو ها
                    </Link>
                    <Link className={classes.footerLink} to="/rules">
                        قوانین و مقررات
                    </Link>
                </Grid>
                <Grid
                    item
                    md={4}
                    xs={12}
                    className={classes.namadContainer}
                    style={{ marginBottom: isMobile ? 20 : 10 }}
                >
                    <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=246202&amp;Code=DCEBjNeCrgP6ZzMkmhNg"><img referrerPolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=246202&amp;Code=DCEBjNeCrgP6ZzMkmhNg" alt="" style={{cursor:"pointer"}} id="DCEBjNeCrgP6ZzMkmhNg" /></a>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Footer;
