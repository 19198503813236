import React from "react";
import {
    Grid,
    Typography,
    Collapse,
    TextField,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Button from "../../../Components/UI/Buttons/Button"
import { showAlert } from "../../../Redux/actions/snackbarActions";
import { addSicknessApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import ShowableSwitch from "../../../Components/UI/Inputs/ShowableSwitch";
import styles from "../style";

const Sickness = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = styles();
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({
        request_id: requestId,
        showDescription: false,
    });
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        if (formData.showDescription) {
            if (formData.diseases) {
                callApi(
                    addSicknessApi,
                    formData,
                    `/profile/request/${requestId}/exerciseing`
                );
            } else {
                dispatch(showAlert("لطفا اطلاعات را کامل نمایید", "error"));
            }
        } else {
            history.push(`/profile/request/${requestId}/exerciseing`);
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            بیماری
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ShowableSwitch
                                    label="بیماری دارم"
                                    style={{
                                        diplay: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    labelStyle={{ margin: 0 }}
                                    value={formData.showDescription}
                                    onChange={(checked) =>
                                        inputChangedHandler(
                                            checked,
                                            "showDescription"
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={formData.showDescription}>
                                    <Grid container spacing={3}>
                                        <TextField
                                            id="diseases"
                                            label="توضیحات"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) =>
                                                inputChangedHandler(
                                                    e.target.value,
                                                    e.target.id
                                                )
                                            }
                                            multiline
                                            rows={5}
                                        />
                                    </Grid>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                    <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Sickness;
