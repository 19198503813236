import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import avatar1 from "../../assets/images/avatar01.jpg";
import avatar2 from "../../assets/images/avatar02.jpg";

const style = makeStyles({
  cardRoot: {
    borderRadius: 20,
  },
  avatarContainer: {
    display: "flex",
    alignItems: 'center',
    flexDirection: "column"
  },
  avatar: {
    borderRadius: "50%",
    width: 100,
  },
  messageContainer: {
    display: "flex",
    justifyContent: 'center',
    alignContent: 'center',
  },
  message: {
      display: "grid",
      alignContent: 'center',
  }
});
const CarouselComment = ({ data }) => {
  const classes = style();
  return (
    <Card className={classes.cardRoot}>
      <CardContent>
        <Grid container>
          <Grid item xs={2} className={classes.avatarContainer}>
            <img src={data.avatar} className={classes.avatar} />
            <Typography variant="h5" component="h5" style={{textAlign: "center"}}>
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.messageContainer}>
            <Typography className={classes.message}>{data.message}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CarouselComment;
