const personalInfoInputs = (defaults) => {
    return {
        height: {
            elementType: "text-field",
            elementConfig: {
                label: "قد همسر",
                variant: "outlined",
                value: defaults.height ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 4,
            },
        },
        weight: {
            elementType: "text-field",
            elementConfig: {
                label: "وزن همسر",
                variant: "outlined",
                value: defaults.weight ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 4,
            },
        },
        children: {
            elementType: "text-field",
            elementConfig: {
                label: "تعداد فرزند",
                variant: "outlined",
                value: defaults.children ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 4,
            },
        },
        child_above_4kg: {
            elementType: "switch",
            elementConfig: {
                label: "آیا هیچ یک از فرزندان شما هنگام تولد وزن بالای ۴ کیلو داشته است؟",
                variant: "outlined",
                value: defaults.child_above_4kg,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        partner_complains: {
            elementType: "switch",
            elementConfig: {
                label: "همسرتان از وضعیت اندامتان شکایت دارد؟",
                variant: "outlined",
                value: defaults.partner_complains,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default personalInfoInputs;
