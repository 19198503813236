import React from "react";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const MyButton = (props) => {
    return (
        <Button disabled={props.loading} {...props}>
            {props.loading ? <CircularProgress size={18} style={{ color: "rgb(42, 113, 16)" }} /> : props.title}
        </Button>
    );
};

export default MyButton;
