import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxShadow: "none",
        backgroundColor: "none",
        border: "none !important",
        margin: "10px 0",
        boxShadow: "0 0 20px 0 #d7dee366",
        "&:before": {
            display: "none",
        },
        [theme.breakpoints.down("sm")]: {
            // border: "1px solid #000 !important",
        },
    },
    heading: {
        fontFamily: "IRANSansBold",
        fontSize: theme.typography.pxToRem(20),
        color: "#5c5f66",
    },
    icon: {
        fontSize: 20,
        color: "#5c5f66",
        marginRight: 5,
    },
    details: {
        // marginTop: -25,
        padding: 0,
    },
}));

const CustomAccordion = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const { title, active } = props;

    const toggleCollapse = () => {
        setExpanded((prevState) => (prevState ? false : true));
    };

    return (
        <Accordion expanded={expanded} onChange={() => toggleCollapse()} className={classes.root}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/* {expanded ? <RemoveIcon className={classes.icon} /> : <AddIcon className={classes.icon} />} */}
                <Badge color="primary" badgeContent="" variant="dot" invisible={!active}>
                    <Typography className={classes.heading}>{title}</Typography>
                </Badge>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>{props.children}</AccordionDetails>
        </Accordion>
    );
};

export default CustomAccordion;
