import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import Accordion from "../../Components/Accordion/Accordion";

import headerImg from "../../assets/images/headerImg1.jpg";
import { videoCateories } from "./data";
import AccordionContent from "./AccordionContent";

const style = makeStyles({
  pagination: {
    margin: 20,
    display: "flex",
    justifyContent: "center",
  },
});
const VideoCats = () => {
  const classes = style();
  return (
    <Grid container>
      <PageHeader title="دسته بندی ویدیو ها" image={headerImg} />
      <MainContainer>
        <Accordion data={videoCateories} contentField="data" content={(item) => <AccordionContent data={item} />} />
      </MainContainer>
    </Grid>
  );
};

export default VideoCats;
