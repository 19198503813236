import React from "react";
import { Grid, Typography, makeStyles, TextField } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import Button from "../../../Components/UI/Buttons/Button";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import { setRequestData } from "../../../Redux/actions/requestActions";
import { addRequestTypesApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import exercise from "../../../assets/images/exercise.png";
import diet from "../../../assets/images/diet.png";
import styles from "../style";

const Diet = () => {
    const classes = styles();
    const dispatch = useDispatch();
    const request = useSelector((state) => state.request);
    console.log({ request });
    const history = useHistory();
    const { requestId } = useParams();
    React.useEffect(() => {
        if (requestId === "new") {
            dispatch(setRequestData({}));
        }
    }, []);
    React.useEffect(() => {
        setFormData({ exercise: request.exercise ?? false, diet: request.diet ?? false });
    }, [request]);
    const [formData, setFormData] = React.useState({
        exercise: false,
        diet: false,
    });
    const selectItem = (item) => {
        setFormData({ ...formData, [item]: !formData[item] });
    };

    const [data, loading, callApi] = useApi();
    const submitForm = async () => {
        if (formData.exercise || formData.diet) {
            let res = await callApi(addRequestTypesApi, formData);
            history.push(formData.diet ? `/profile/request/${res.data.id}/diet-types` : `/profile/request/${res.data.id}/measures`);
            dispatch(setRequestData(res));
        } else {
            dispatch(showAlert("لطفا یک مورد را انتخاب نمایید", "error"));
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            نوع درخواست
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6} className={classes.itemContainer}>
                                <Grid
                                    container
                                    className={formData.exercise ? clsx(classes.item, classes.active) : classes.item}
                                    onClick={(e) => selectItem("exercise")}
                                >
                                    <img className={classes.img} src={exercise} />
                                </Grid>
                                <Typography variant="h4" component="h4" className={classes.itemTitle}>
                                    تمرین
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.itemContainer}>
                                <Grid
                                    container
                                    className={formData.diet ? clsx(classes.item, classes.active) : classes.item}
                                    onClick={(e) => selectItem("diet")}
                                >
                                    <img className={classes.img} src={diet} />
                                </Grid>
                                <Typography variant="h4" component="h4" className={classes.itemTitle}>
                                    رژیم
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Diet;
