import { makeStyles } from "@material-ui/core";

export default makeStyles({
    pageContainer: {
        padding: "10px 10%",
        margin: 0,
        width: "100%",
    },
    title: {
        textAlign: "center",
        margin: 10,
    },
    itemContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    item: {
        display: "flex",
        justifyContent: "center",
        border: "solid 2px #363636",
        display: "flex",
        borderRadius: 30,
        padding: 15,
        width: "70%",
        cursor: "pointer",
        transition: "all .3s",
    },
    active: {
        backgroundColor: "#BBEE92",
        boxShadow: "0 5px 7px 2px #00000040",
        transition: "all .3s",
    },
    itemTitle: {
        marginTop: 10,
    },
    subtitle: {
        textAlign: "center",
        margin: 10,
        fontSize: 18,
    },
    BtnContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    nextBtn: {
        width: 100,
    },
    infoBtn: {
        backgroundColor: "#558CC0",
        color: "#fff",
        height: 40,
        border: "solid 1px #558CC0",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#558CC0",
            border: "solid 1px #558CC0",
        },
    },
    img: {
        width: 150,
    },
    row: {
        display: "flex",
        alignItems: "center",
    },
    rowTitle: {
        marginRight: 10,
    },
    input: {
        width: 150,
    },
    meal: {
        marginTop: 40,
        border: "solid 1px #ccc",
        padding: 20,
        position: "relative",
    },
    mealTitle: {
        position: "absolute",
        backgroundColor: "#fff",
        top: -15,
        padding: "0 10px",
    },
    uploadContainer: {
        border: "solid 1px #ccc",
        position: "relative",
    },
    uploadContainerTitle: {
        position: "absolute",
        top: -14,
        left: 15,
        backgroundColor: "#fff",
        padding: "0 10px",
    },
});
