import React from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  Typography,
  CardMedia,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import TheatersIcon from "@material-ui/icons/Theaters";

const useStyles = makeStyles((theme) => ({
  card: {
    border: "2px dashed #b8bdcc",
    color: "#b8bdcc",
    boxShadow: "none",
    height: 120,
  },
  actionArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // padding: theme.spacing(1),
    height: "100%",
    width: "100%",
  },
  input: {
    display: "none",
  },
  media: {
    height: "100%",
    width: "100%",
  },
}));

const UploadCard = (props) => {
  const classes = useStyles();
  const { label, onChange, value, id, error, helperText, accept, style } = props;
  return (
    <Card
      className={classes.card}
      style={{ borderColor: error ? "#f44336" : "#b8bdcc", ...style }}
    >
      <input
        accept={accept ? accept : "image/*"}
        className={classes.input}
        id={id}
        multiple
        type="file"
        onChange={(e) => onChange(e.target.files[0])}
      />
      <label htmlFor={id} style={{ width: "100%", height: "100%" }}>
        <CardActionArea className={classes.actionArea} component="div">
          {value ? (
            accept === "video/*" ? (
              <>
                <TheatersIcon color="inherit" style={{ fontSize: 40 }} />{" "}
                <Typography>{value.name}</Typography>
              </>
            ) : (
              <CardMedia
                className={classes.media}
                image={value.type ? URL.createObjectURL(value) : value}
                title={label}
              />
            )
          ) : (
            <>
              <AddIcon color="inherit" style={{ fontSize: 40 }} />
              <Typography variant="body2" color="inherit">
                {label}
              </Typography>
              {error && (
                <Typography style={{ color: "#f44336" }}>
                  تصویر انتخاب نشده است
                </Typography>
              )}
            </>
          )}
        </CardActionArea>
      </label>
    </Card>
  );
};

export default UploadCard;
