import React from "react";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";

import { showAlert } from "../../../Redux/actions/snackbarActions";
import { updateProfileApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import PersonalInputs from "./PersonalInputs";
import MarridInputs from "./MarridInputs";
import menstruationInputs from "./menstruationInputs";
import DietInputs from "./DietInputs";
import RoutineInputs from "./RoutineInputs";
import GoalInputs from "./GoalInputs";

function Profile() {
    const [formData, setFormData] = React.useState({
        smoking: false,
        drinking: false,
        menstruation: false,
        regular_menstruation: false,
        had_diet: false,
        experienced_desire_weight: false,
        child_above_4kg: false,
        partner_complains: false,
    });
    const inputChangedHandler = (value, inputIdentifier) => {
        if (inputIdentifier === "marrid") {
            setFormData({
                ...formData,
                [inputIdentifier]: value,
                child_above_4kg: false,
                partner_complains: false,
            });
        } else {
            setFormData({ ...formData, [inputIdentifier]: value });
        }
    };
    let PersonalFormElements = formCreator(PersonalInputs(formData), inputChangedHandler);
    let MarridFormElements = formCreator(MarridInputs(formData), inputChangedHandler);
    let menstruationElements = formCreator(menstruationInputs(formData), inputChangedHandler);
    let DietFormElements = formCreator(DietInputs(formData), inputChangedHandler);
    let RoutineFormElements = formCreator(RoutineInputs(formData), inputChangedHandler);
    let GoalInputsFormElements = formCreator(GoalInputs(formData), inputChangedHandler);

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(updateProfileApi, formData);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    اطلاعات شخصی
                                </Typography>
                            </Grid>
                            {PersonalFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    اطلاعات ازدواج
                                </Typography>
                            </Grid>
                            {MarridFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    اطلاعات زنانگی
                                </Typography>
                            </Grid>
                            {menstruationElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    اطلاعات رژیم
                                </Typography>
                            </Grid>
                            {DietFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    عادات روزانه
                                </Typography>
                            </Grid>
                            {RoutineFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4">
                                    هدف
                                </Typography>
                            </Grid>
                            {GoalInputsFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={submitForm}>
                    ویرایش
                </Button>
            </Grid>
        </Grid>
    );
}

export default Profile;
