import React from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  makeStyles,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import MicIcon from "@material-ui/icons/Mic";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";

import Button from "../../../Components/UI/Buttons/Button";
import CustomAccordion from "../../../Components/Surfaces/CustomAccordion/CustomAccordion";
import RequestProfileDetiles from "../SingleRequest/RequestProfileDetiles";
import RequestDetiles from "../SingleRequest/RequestDetiles";
import RequestDetilesImages from "../SingleRequest/RequestDetilesImages";

import { getAllGroupsApi } from "../../../API/Forms";
import {
  getSummaryApi,
  getResponsesApi,
  submitResponseApi,
} from "../../../API/response";
import { getMyRequestsApi, addSummaryApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import { showDialog, hideDialog } from "../../../Redux/actions/dialogActions";
import ModalForms from "./ModalForms";
import UploadResponce from "./UploadResponce";
import UploadResponceVoice from "./UploadResponceVoice";
import RemoveResponse from "./RemoveResponse";
import RemoveAudio from "./RemoveAudio";
import PastRequestes from "./PastRequestes";
import { showAlert } from "../../../Redux/actions/snackbarActions";

const styles = makeStyles({
  title: {
    margin: 20,
  },
  summaryContainer: {
    border: "solid 1px #ccc",
    margin: 10,
    padding: 10,
  },
  summary: {
    margin: 10,
    padding: 10,
  },
  dietForms: {
    backgroundColor: "#4B778D",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  exerciseForms: {
    backgroundColor: "#28B5B5",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  recomendationForms: {
    backgroundColor: "#8FD9A8",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
  prescriptionForms: {
    backgroundColor: "#D2E69C",
    "&:hover": {
      backgroundColor: "#97AAD9",
    },
  },
});

const SingleReponse = () => {
  const user = useSelector((state) => state.user);
  const classes = styles();
  const { reponsesId } = useParams();
  const history = useHistory();

  const [groups, setGroups] = React.useState([]);
  const [summary, setSummary] = React.useState({});
  const [Responses, setResponses] = React.useState([]);
  const [ResponsesAudio, setResponsesAudio] = React.useState([]);
  const [request, setRequest] = React.useState([]);
  const [data, loading, callApi] = useApi();
  React.useEffect(async () => {
    // let data = await callApi(getAllGroupsApi, false, false, false, true);
    // setGroups(data);

    let requestData = await callApi(
      getMyRequestsApi,
      { id: reponsesId },
      false,
      false,
      true
    );
    setRequest(requestData.data);

    let SummaryData = await callApi(
      getSummaryApi,
      { id: reponsesId },
      false,
      false,
      true
    );
    setSummary(SummaryData.summary);
    let responsesData = await callApi(
      getResponsesApi,
      { id: reponsesId },
      false,
      false,
      true
    );
    setResponses(responsesData.response);
    setResponsesAudio(responsesData.response_audio);
    // let imagesData = [];
    // responsesData?.data?.map((image) => {
    //   imagesData = [...imagesData, image.path];
    // });
  }, []);

  const dispatch = useDispatch();
  const showForms = (groupId) => {
    dispatch(
      showDialog(<ModalForms groupId={groupId} navigate={handleNavigate} />)
    );
  };

  const showUpload = () => {
    dispatch(
      showDialog(
        <UploadResponce
          requestId={reponsesId}
          addResponse={(res) => setResponses([...Responses, res])}
        />,
        false,
        "sm"
      )
    );
  };

  const showVoice = () => {
    dispatch(
      showDialog(
        <UploadResponceVoice
          requestId={reponsesId}
          addResponse={(res) => setResponsesAudio([...ResponsesAudio, res])}
        />,
        false,
        "sm"
      )
    );
  };

  const handleNavigate = (form) => {
    dispatch(hideDialog());
    history.push(`/dashboard/reponses/${reponsesId}/form/${form.id}`);
  };

  const handleSubmit = async () => {
    let responsesData = await callApi(submitResponseApi, {
      request_id: reponsesId,
    });
    history.goBack();
  };

  const removeResponseModal = (id) => {
    dispatch(
      showDialog(
        <RemoveResponse id={id} remove={(id) => submitRemove(id)} />,
        false,
        "sm"
      )
    );
  };

  const removeAudioModal = (id) => {
    dispatch(
      showDialog(
        <RemoveAudio
          id={id}
          remove={(id) =>
            setResponsesAudio(ResponsesAudio.filter((res) => res.id != id))
          }
        />,
        false,
        "sm"
      )
    );
  };

  const submitRemove = (id) => {
    setResponses(Responses.filter((res) => res.id != id));
  };

  const changeSummary = (e) => {
    setSummary(e.target.value);
  };

  const submitSummary = async () => {
    let data = await callApi(addSummaryApi, {
      request_id: reponsesId,
      summary,
    });
    if (data.data) {
      dispatch(showAlert("خلاصه وضعیت با موفقیت به روزرسانی شد."));
    }
  };

  return (
    <Grid container spacing={3}>
      {user.role !== "assistant" && (
        <CustomAccordion title="مشخصات پروفایل" active={false}>
          <RequestProfileDetiles data={request.user ?? {}} />
        </CustomAccordion>
      )}

      <CustomAccordion title="سوابق قبلی" active={false}>
        {request?.user && (
          <PastRequestes
            requestId={request.id}
            mobile={request?.user?.mobile}
          />
        )}
      </CustomAccordion>

      <CustomAccordion title="مشخصات درخواست" active={false}>
        <RequestDetiles data={request} />
      </CustomAccordion>

      <CustomAccordion title="تصاویر" active={false}>
        <RequestDetilesImages data={request} />
      </CustomAccordion>

      <Grid item xs={12}>
        <TextField
          fullWidth
          onChange={changeSummary}
          label="خلاصه وضعیت"
          multiline
          value={summary ?? ""}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <Button
                style={{ width: 150 }}
                variant="contained"
                color="primary"
                onClick={submitSummary}
                title="ثبت تغییرات"
                loading={loading}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} component="h2" variant="h2">
          ایجاد پاسخ
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {user.role === "writer" ? null : (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.dietForms}
                    startIcon={<BackupOutlinedIcon />}
                    onClick={() => showUpload()}
                    title="آپلود تصویر"
                  />
                </Grid>
              )}
              {Responses?.map((Response, index) => (
                <Grid item xs={6} style={{ position: "relative" }}>
                  <a href={Response.path} target="_blank">
                    <img
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        aspectRatio: "3/2",
                        objectFit: "cover",
                      }}
                      src={Response.path}
                    />
                  </a>
                  <IconButton
                    onClick={() => removeResponseModal(Response.id)}
                    style={{
                      position: "absolute",
                      top: 20,
                      right: 20,
                      backgroundColor: "#ccc",
                    }}
                  >
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {user.role === "writer" ? null : (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.dietForms}
                    startIcon={<MicIcon />}
                    onClick={() => showVoice()}
                    title="آپلود صوت"
                  />
                </Grid>
              )}
              {ResponsesAudio?.map((Response, index) => (
                <Grid item xs={12} style={{ position: "relative" }}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        // size="small"
                        onClick={() => removeAudioModal(Response.id)}
                        style={{
                          backgroundColor: "#ccc",
                        }}
                      >
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      {/* <a href={Response.path} target="_blank"> */}
                      <audio
                        style={{ width: "100%", borderRadius: 20 }}
                        controls
                        // controlsList="nodownload"
                        src={Response.path}
                      />
                      {/* </a> */}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {user.role === "writer" ? null : (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => handleSubmit()}
            title="ثبت نهایی"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SingleReponse;
