import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import bannerImg from "../../assets/images/homeBanner.jpg";
import Vector from "./Vector";

const style = makeStyles({
    bannerImg: {
        position: "relative",
        backgroundImage: `url(${bannerImg})`,
        height: "calc(100vh + 252px)",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundPosition: "left",
    },
    ButtonContainer: {
        display: "flex",
        justifyContent: "center",
    },
    startNow: {
        borderRadius: 35,
        fontFamily: "IRANSansBold",
        padding: "35px 50px",
        color: "#196B3C",
    },
    icon: {
        fontSize: "35px !important",
    },
    Vector: {
        position: "absolute",
        bottom: -5,
        width: "100%",
    },
});
const HomeBanner = () => {
    const classes = style();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const handleStart = () => {
        if (!user) {
            history.push("/auth");
        } else {
            history.push(user.confirm_profile ? '/profile/request/new/types' : '/profile/personal-info');
        }
    };

    return (
        <Grid
            item
            xs={12}
            className={classes.bannerImg}
            style={{ height: isMobile && 400 }}
        >
            <div
                className={classes.ButtonContainer}
                style={{ width: isMobile ? "100%" : "50%" }}
            >
                <Button
                    className={classes.startNow}
                    style={{ fontSize: isMobile ? 25 : 30 }}
                    variant="contained"
                    startIcon={<AccessAlarmIcon className={classes.icon} />}
                    onClick={handleStart}
                >
                    همین حالا شروع کن
                </Button>
            </div>
            <Vector
                style={{ position: "absolute", bottom: -5, width: "100%" }}
            />
        </Grid>
    );
};

export default HomeBanner;
