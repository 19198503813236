const personalInfoInputs = (defaults) => {
    return {
        birthday: {
            elementType: "date-picker",
            elementConfig: {
                label: "تاریخ تولد",
                variant: "outlined",
                value: defaults.birthday ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 3,
            },
            value: defaults.birthday ?? "",
        },
        job: {
            elementType: "text-field",
            elementConfig: {
                label: "شغل",
                variant: "outlined",
                value: defaults.job ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 3,
            },
        },
        sex: {
            elementType: "select-input",
            elementConfig: {
                label: "جنسیت",
                options: [
                    { label: "مرد", value: "MALE" },
                    { label: "زن", value: "FEMALE" },
                ],
                variant: "outlined",
                value: defaults.sex ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 3,
            },
            value: defaults.sex ?? "MALE",
        },
        phone: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره موبایل",
                variant: "outlined",
                value: defaults.phone ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 6,
                md: 3,
            },
        },
        address: {
            elementType: "text-field",
            elementConfig: {
                label: "آدرس",
                variant: "outlined",
                value: defaults.address ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default personalInfoInputs;
