import React from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

import useApi from "../../Hooks/UseApi";
import { getVideosApi } from "../../API/video";
import MainContainer from "../Container/MainContainer";
import HomeVideo from "../Video/HomeVideo";
import background from "../../assets/images/Vector2.svg";

const style = makeStyles({
    sectionRoot: {
        position: "relative",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        height: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    videoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        marginBottom: 20,
    },
    description: {
        color: "#fff",
    },
});
const Section3 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const classes = style();
    const [Video, setVideo] = React.useState({});
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getVideosApi, { id: 1 });
        setVideo(data ? data.data : {});
    }, []);
    return (
        <Grid item xs={12} className={classes.sectionRoot}>
            <MainContainer>
                <Grid container className={classes.videoContainer} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.title}
                                    style={{
                                        color: isMobile ? "#196B3C" : "#fff",
                                    }}
                                    variant="h2"
                                    component="h2"
                                >
                                    ویدیوهای منتخب
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                                <Button
                                    component={Link}
                                    to="/videos"
                                    endIcon={<ArrowBackIosNewIcon />}
                                    variant="contained"
                                    color="primary"
                                >
                                    همه ویدیوها
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <HomeVideo link={Video.link} />
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default Section3;
