import React from "react";
import axios from "axios";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import UpdateIcon from "@material-ui/icons/Update";

import theme from "./Theme";

import store from "./Redux/store";
import MainRoute from "./Routes/MainRoute";
import Snackbar from "./Components/Snackbar/Snackbar";
import Backdrop from "./Components/Backdrop/Backdrop";
import { Grid, Typography } from "@material-ui/core";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

const UpgaredMode = () => {
    return (
        <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <UpdateIcon style={{ fontSize: 50 }} />
            <Typography style={{ fontSize: 25 }}>رادایتی گرامی</Typography>
            <Typography style={{ fontSize: 25 }}>وبسایت رادایت درحال بروزرسانی میباشد</Typography>
            <Typography style={{ fontSize: 25 }}>لطفا در زمان دیگری مراجعه نمایید</Typography>
        </div>
    );
};
const App = (props) => {
    const [csrf, setCsrf] = React.useState(null);
    React.useEffect(() => {
        axios
            .get("/csrf-cookie")
            .then((res) => {
                setCsrf(true);
            })
            .catch((error) => setCsrf(false));
    }, []);
    return (
        <StylesProvider jss={jss}>
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <Backdrop />
                    <Snackbar />
                    <MainRoute />
                    {/* {csrf ? <MainRoute /> : csrf !== null && <UpgaredMode />} */}
                </Provider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

export default App;
