import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  CardActionArea,
  CardActions,
  Button,
} from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import moment from "moment-jalaali";

import currencySeparator from "../../shared/currencySeparator";

const Styles = makeStyles({
  rowContainer: {
    border: "solid 1px #ccc",
    margin: "5px 0 5px 0",
    borderRadius: 4,
  },
  header: {
    backgroundColor: "#A7D397",
    padding: 10,
  },
  content: {
    backgroundColor: "#fff",
    padding: 10,
  },
  pagination: {
    margin: 20,
    "& ul": {
      justifyContent: "flex-end",
    },
  },
});

const MobileDataTables = (props) => {
  const {
    headData,
    data,
    title,
    action,
    changeLimit,
    limit,
    arrayFields,
    currentPage,
    route,
    headerMobileField,
  } = props;
  const classes = Styles();
  const arrayToString = (row, field) => {
    let arrayField = arrayFields.filter((item) => item.name === field)[0];
    let string = "";
    row[field].map((item, key) => {
      let text = "";
      arrayField.field.map((f) => {
        text = text + " " + (item[f] ? item[f] : "");
      });
      string =
        string +
        text +
        (key !== row[field].length - 1 ? arrayField.seprator : "");
    });
    return string;
  };

  const fixFieldValue = (row, fieldName) => {
    let fieldType = headData.filter((head) => head.field === fieldName)[0].type;
    switch (fieldType) {
      case "array":
        return arrayToString(row, fieldName);
      case "date":
        return row[fieldName]
          ? moment(row[fieldName], "YYYY/MM/DD").format("jYYYY/jMM/jDD")
          : "----";
      case "dateTime":
        return row[fieldName]
          ? moment(row[fieldName], "YYYY/MM/DD HH:mm:ss").format(
              "jYYYY/jMM/jDD HH:mm:ss"
            )
          : "----";
      case "currency":
        return row[fieldName] === null
          ? "0"
          : currencySeparator(row[fieldName]);
      default:
        return row[fieldName] ? row[fieldName] : "----";
    }
  };

  return (
    <Card style={{ marginTop: 20, padding: 10 }}>
      <Grid container>
        <Grid item xs={12}>
          {title && (
            <Typography
              variant="h3"
              component="h3"
              style={{ textAlign: "center", margin: 20 }}
            >
              {title}
            </Typography>
          )}
        </Grid>
        {data.map((row, index) => (
          <Grid
            item
            xs={12}
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
            key={index}
          >
            <Card style={{ backgroundColor: "rgb(237 247 231)" }}>
              <CardContent>
                {headData.map((cell, key) => (
                  <Grid container className={classes.rowContainer} key={key}>
                    <Grid item xs={12} className={classes.header}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="h5"
                        component="h5"
                      >
                        {cell.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                      <Typography style={{ textAlign: "center" }}>
                        {row[cell.field] === undefined &&
                          props[cell.field](row, index + 1)}
                        {row[cell.field] !== undefined &&
                          fixFieldValue(row, cell.field)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </CardContent>
              <CardActions style={{ padding: 0 }}>
                {action(row)}
              </CardActions>
            </Card>
          </Grid>
        ))}
        {props.lastPage && (
              <Grid container>
                {/* <Grid item xs={6}>
                      <PerPage
                        limit={limit}
                        changeLimit={(limit) => changeLimit(limit)}
                      />
                    </Grid> */}
                {data.length === 0 ? (
                  <Typography
                    style={{ padding: 10, width: "100%", textAlign: "center" }}
                  >
                    هیچ اطلاعاتی برای نمایش وجود ندارد
                  </Typography>
                ) : (
                  <Grid item xs={12} style={{display: "flex", justifyContent: "center"}}>
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          to={`${route}?page=${item.page}`}
                          {...item}
                        />
                      )}
                      defaultPage={parseInt(currentPage)}
                      className={classes.pagination}
                      count={props.lastPage}
                    />
                  </Grid>
                )}
              </Grid>
            )}
      </Grid>
    </Card>
  );
};

export default MobileDataTables;
