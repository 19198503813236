import React from "react";

const Vector = ({ style, color }) => {
  return (
    <svg
      width="1920"
      viewBox="0 0 1920 252"
      fill="none"
      style={style}
    >
      <path
        d="M1080.44 59.6183C514.384 -63.1252 548.044 119.237 0 0V252H1921V97.1928C1829.5 125.583 1533.29 157.813 1080.44 59.6183Z"
        fill={color ? color : "white"}
      />
    </svg>
  );
};

export default Vector;
