import React from "react";
import {
    Grid,
    Typography,
    makeStyles,
    Button,
    TextField,
    IconButton,
    InputAdornment,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { registerApi } from "../../../API/auth";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import Inputs from "./inputs";

const styles = makeStyles({
    title: {
        textAlign: "center",
        margin: 10,
    },
    subtitle: {
        textAlign: "center",
        margin: 10,
        fontSize: 18,
    },
});

const Register = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    const [formData, setFormData] = React.useState({});
    const [showPass, setShowPass] = React.useState(false);
    const [showRePass, setShowRePass] = React.useState(false);

    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(
        Inputs({
            ...formData,
            showPass,
            showRePass,
            setShowPass,
            setShowRePass,
        }),
        inputChangedHandler
    );

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        if (formData.rules){

            if (formData.password === formData.password_confirmation) {
                callApi(registerApi, formData, "/auth/verification", {
                    mobile: formData.mobile,
                    type: "register",
                }, "full");
            } else {
                dispatch(showAlert("تکرار رمز عبور نادرست است", "error"));
            }
        } else {
            dispatch(showAlert("تایید قوانین و مقررات الزامی میباشد", "error"));
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={3}
                    style={{ padding: isMobile ? "100px 10%" : "100px 25%" }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            ثبت نام
                        </Typography>
                    </Grid>
                    {formElements}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.rules}
                                    onChange={() => inputChangedHandler(!formData.rules, 'rules')}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label='قوانین و مقررات را میپذیرم'
                        />
                        <Link to="/rules" target="_blank">قوانین و مقررات</Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={submitForm}
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            ثبت نام
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Register;
