const requestTypeInputs = (defaults) => {
    return {
        diet: {
            elementType: "switch",
            elementConfig: {
                label: "رژیم",
                variant: "outlined",
                value: defaults.diet,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 3,
            },
        },
        exercise: {
            elementType: "switch",
            elementConfig: {
                label: "تمرین",
                variant: "outlined",
                value: defaults.exercise,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 3,
            },
        },
    };
};

export default requestTypeInputs;
