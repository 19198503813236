import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useParams, useHistory } from "react-router-dom";

import styles from "../style";

const PaymentStatus = () => {
    const classes = styles();
    const { requestId } = useParams();
    const history = useHistory();
    const queryString = window.location;
    const url = new URL(queryString);
    const code = url.searchParams.get("code");
    const refId = url.searchParams.get("ref_id");
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    variant="h2"
                    component="h2"
                    className={classes.title}
                >
                    وضعیت پرداخت
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {code == 0 ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CheckCircleOutlineIcon
                            style={{ fontSize: "3vw", color: "#81a717" }}
                        />
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            پرداخت با موفقیت انجام شد
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            className={classes.title}
                        >
                            کد پیگیری تراکنش: {refId}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                history.push(
                                    `/dashboard/diet-requests`
                                )
                            }
                        >درخواست های من</Button>
                    </div>
                ) : (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CancelOutlinedIcon
                            style={{ fontSize: "3vw", color: "#e01212" }}
                        />
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            پرداخت با خطا مواجه شد
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                history.push(
                                    `/profile/request/${requestId}/payment`
                                )
                            }
                        >بازگشت به صفحه پرداخت</Button>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

export default PaymentStatus;
