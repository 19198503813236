import React from "react";
import { Grid, Typography, Collapse, TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { showAlert } from "../../../Redux/actions/snackbarActions";
import Button from "../../../Components/UI/Buttons/Button";
import { showDialog } from "../../../Redux/actions/dialogActions";
import DialogContent from "./DialogContent";
import { addBodyImagesApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import getFormData from "../../../shared/getFormData";
import Inputs from "./Inputs";
import styles from "../style";

const BodyImages = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({ request_id: requestId });
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(Inputs({ ...formData }), inputChangedHandler);

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        if (formData.back_image &&
            formData.front_image &&
            formData.side_image &&
            formData.front_open_image &&
            formData.side_open_image){
                callApi(addBodyImagesApi, getFormData(formData), `/profile/request/${requestId}/payment`);
            } else {
                dispatch(showAlert( "بارگذاری تمامی تمام عکسهای بدن الزامی است.", "error"));
            }
    };

    const showModal = () => {
        dispatch(showDialog(<DialogContent />));
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            تصویر بدن
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            disableElevation
                            size="small"
                            className={classes.infoBtn}
                            onClick={showModal}
                            variant="contained"
                            startIcon={<InfoOutlinedIcon />}
                            title="راهنما تصویر بدن"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formElements}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">
                            نکته
                        </Typography>
                        <Typography>بانوان : در بالاتنه با نیمتنه و پایین تنه شلوارک جذب و کوتاه که ران مشخص باشد.</Typography>
                        <Typography>آقایان : بالاتنه برهنه و پایین تنه شلوارک جذب و کوتاه که ران مشخص باشد</Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BodyImages;
