import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
    Grid,
    makeStyles,
    Card,
    Typography,
    CardContent,
    IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from "react-router-dom";

import FooterSocial from "../Components/SocialMedia/footerSocial";
import PersonalInfo from "../Pages/Profile/PersonalInfo/PersonalInfo";
import MarridInfo from "../Pages/Profile/MarridInfo/MarridInfo";
import WomanDetails from "../Pages/Profile/WomanDetails/WomanDetails";
import Routine from "../Pages/Profile/Routine/Routine";
import Diet from "../Pages/Profile/Diet/Diet";
import Goal from "../Pages/Profile/Goal/Goal";

import RequestTypes from "../Pages/Profile/RequestTypes/RequestTypes";
import DietType from "../Pages/Profile/DietType/DietType";
import Measures from "../Pages/Profile/Measures/Measures";
import Alergies from "../Pages/Profile/Alergies/Alergies";
import Documents from "../Pages/Profile/Documents/Documents";
import Sickness from "../Pages/Profile/Sickness/Sickness";
import Exerciseing from "../Pages/Profile/Exerciseing/Exerciseing";
import Analyze from "../Pages/Profile/Analyze/Analyze";
import BodyImages from "../Pages/Profile/BodyImages/BodyImages";
import Payment from "../Pages/Profile/Payment/Payment";
import PaymentStatus from "../Pages/Profile/PaymentStatus/PaymentStatus";

import backImg from "../assets/images/Rectangle29.jpg";

const styles = makeStyles({
    backImg: {
        backgroundImage: `url(${backImg})`,
        height: "100vh",
        backgroundPosition: "left",
    },
    cardRoot: {
        height: "100%",
        position: "absolute",
        // width: "50%",
        transition: "all 1s",
        borderRadius: 0,
    },
    socialText: {
        textAlign: "center",
        color: "#8b8b8b",
    },
    socialContainer: {
        // position: "absolute",
        bottom: 0,
    },
    social: {
        justifyContent: "center",
    },
    CloseButton: {
      position: "absolute",
      left: 10,
      top: 10,
    }
});
const ProfileRoute = () => {
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    return (
        <Grid container>
            <Grid item xs={12} className={classes.backImg}>
                <IconButton onClick={() => history.push("/")} className={classes.CloseButton} ><CloseIcon /></IconButton>
                <Card
                    className={classes.cardRoot}
                    style={{
                        right: isMobile ? "0%" : "50%",
                        width: isMobile ? "100%" : "50%",
                    }}
                >
                    <CardContent>
                        <Grid
                            container
                            style={{
                                overflow: "scroll",
                                height: "calc(100vh - 100px)",
                                margin: 0,
                                width: "100%",
                            }}
                        >
                            <Switch>
                                <Route
                                    path="/profile/personal-info"
                                    component={PersonalInfo}
                                />
                                <Route
                                    path="/profile/marrid-info"
                                    component={MarridInfo}
                                />
                                <Route
                                    path="/profile/women-info"
                                    component={WomanDetails}
                                />
                                <Route
                                    path="/profile/routine-info"
                                    component={Routine}
                                />
                                <Route
                                    path="/profile/diet-info"
                                    component={Diet}
                                />
                                <Route path="/profile/goal" component={Goal} />

                                <Route
                                    path="/profile/request/:requestId/types"
                                    component={RequestTypes}
                                />
                                <Route
                                    path="/profile/request/:requestId/diet-types"
                                    component={DietType}
                                />
                                <Route
                                    path="/profile/request/:requestId/measures"
                                    component={Measures}
                                />
                                <Route
                                    path="/profile/request/:requestId/alergies"
                                    component={Alergies}
                                />
                                <Route
                                    path="/profile/request/:requestId/documents"
                                    component={Documents}
                                />
                                <Route
                                    path="/profile/request/:requestId/sickness"
                                    component={Sickness}
                                />
                                <Route
                                    path="/profile/request/:requestId/exerciseing"
                                    component={Exerciseing}
                                />
                                <Route
                                    path="/profile/request/:requestId/analyze"
                                    component={Analyze}
                                />
                                <Route
                                    path="/profile/request/:requestId/body-images"
                                    component={BodyImages}
                                />
                                <Route
                                    path="/profile/request/:requestId/payment"
                                    component={Payment}
                                />
                                <Route
                                    path="/profile/request/:requestId/payment-status"
                                    component={PaymentStatus}
                                />
                                <Route path="/profile">
                                    <Redirect to="/profile/personal-info"></Redirect>
                                </Route>
                            </Switch>
                        </Grid>
                        <Grid container className={classes.socialContainer}>
                            <Grid item xs={12}>
                                <Typography className={classes.socialText}>
                                    ما را در شبکه های اجتماعی دنبال کنید
                                </Typography>
                            </Grid>
                            <FooterSocial className={classes.social} />
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ProfileRoute;
