const Inputs = (defaults) => {
    return {
        examinations: {
            elementType: "file-uploader",
            elementConfig: {
                label: "بارگذاری آزمایش ها",
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.examinations ?? [],
            onRemove: (item) => defaults.onRemove(item, "examinations"),
        },
        ultrasounds: {
            elementType: "file-uploader",
            elementConfig: {
                label: "بارگذاری سونوگرافی",
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.ultrasounds ?? [],
            onRemove: (item) => defaults.onRemove(item, "ultrasounds"),
        },
    };
};

export default Inputs;
