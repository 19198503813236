import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";

import useApi from "../../Hooks/UseApi";
import { getAllPostsApi } from "../../API/product";
import SingleArticleHeader from "../../Components/Header/SingleArticleHeader";
import MainContainer from "../../Components/Container/MainContainer";
import { articles } from "../Articles/data";

const style = makeStyles({
    body: {
        margin: "50px 0",
    },
    row: {
        margin: "20px 0",
        textAlign: "center",
    },
});
const SingleArticle = () => {
    const classes = style();
    const { articleId } = useParams();
    const [articleData, setArticleData] = React.useState({});
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllPostsApi, { id: articleId });
        setArticleData(data.data);
    }, []);

    return (
        <Grid container>
            <SingleArticleHeader
                title={articleData.title}
                image={articleData.image_path}
            />
            <MainContainer>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.row}>
                        <Typography variant="h3" component="h3">
                            {articleData.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: articleData.content,
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default SingleArticle;
