import React from "react";
import { Grid, Typography, makeStyles, Collapse } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { addUserData } from "../../../Redux/actions/userActions";
import Button from "../../../Components/UI/Buttons/Button";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import { addMarridInfoApi, getMarridInfoApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import marridInfoInputs from "./marridInfoInputs";
import ShowableSwitch from "../../../Components/UI/Inputs/ShowableSwitch";
import styles from "../style";

const MarridInfo = () => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = React.useState({
    ...user.marrage_info,
    height: user.marrage_info.partner_height,
    weight: user.marrage_info.partner_weight,
  });
  React.useEffect(() => {
    if (
      user.marrage_info.partner_height ||
      user.marrage_info.partner_weight ||
      user.marrage_info.children ||
      user.marrage_info.child_above_4kg ||
      user.marrage_info.partner_complains
    ) {
      setFormData({ ...formData, marrid: true });
    }
  }, [user]);
  const inputChangedHandler = (value, inputIdentifier) => {
    if (inputIdentifier === "marrid") {
      setFormData({
        ...formData,
        [inputIdentifier]: value,
        child_above_4kg: false,
        partner_complains: false,
      });
    } else {
      setFormData({ ...formData, [inputIdentifier]: value });
    }
  };
  let formElements = formCreator(
    marridInfoInputs(formData),
    inputChangedHandler
  );

  const [data, loading, callApi] = useApi();
  const submitForm = async () => {
    if (formData.marrid) {
      if (
        !formData.partner_height &&
        !formData.partner_weight &&
        !formData.children
      ) {
        dispatch(showAlert("لطفا اطلاعات را کامل نمایید", "error"));
      } else {
        const data = await callApi(
          addMarridInfoApi,
          formData,
          user.personal_info.sex === "FEMALE"
            ? "/profile/women-info"
            : "/profile/routine-info"
        );
        if (data) {
          dispatch(addUserData({ marrage_info: data.data }));
        }
      }
    } else {
      history.push("FEMALE" ? "/profile/women-info" : "/profile/routine-info");
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              اطلاعات تاهل
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ShowableSwitch
              label="متاهل هستم"
              style={{
                diplay: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              labelStyle={{ margin: 0 }}
              value={formData.marrid}
              onChange={(checked) => {
                if (!checked) {
                  setFormData({});
                } else {
                  inputChangedHandler(checked, "marrid");
                }
              }}
            />
          </Grid>
          <Collapse in={formData.marrid}>
            <Grid container spacing={3}>
              {formElements}
            </Grid>
          </Collapse>
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              className={classes.nextBtn}
              disableElevation
              onClick={() => submitForm()}
              variant="contained"
              color="primary"
              title="بعدی"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MarridInfo;
