import React from "react";
import {
    Grid,
    Typography,
    IconButton,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import AddForm from "./AddForm";
import RemoveForm from "./RemoveForm";
import { showDialog } from "../../../../Redux/actions/dialogActions";
import { getTemplatesApi } from "../../../../API/Forms";
import useApi from "../../../../Hooks/UseApi";
import AddButton from "../../../../Components/UI/Buttons/AddButton";
import styles from "../style";

const Forms = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const { groupId } = useParams();
    const [forms, setForms] = React.useState([]);

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getTemplatesApi, { group_id: groupId });
        setForms(data.data);
    }, []);
    const showAddModal = () => {
        dispatch(showDialog(<AddForm groupId={groupId} add={(item) => addItem(item)} />, false));
    };

    const addItem = (item) => {
        setForms([...forms, item])
    }

    const deleteForm = (id) => {
        dispatch(showDialog(<RemoveForm templateId={id} remove={(id) => removeItem(id)} />, false, "xs"));
    }

    const removeItem = (id) => {
        setForms(forms.filter(form => form.id != id))
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <AddButton
                            title="اضافه کردن فرم جدید"
                            onClick={showAddModal}
                        />
                    </Grid>
                    {forms.map((form) => (
                        <Grid item xs={3}>
                            <Card style={{position: "relative"}}>
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        image={form.path}
                                    />
                                    <CardContent>
                                        <Typography>{form.name}</Typography>
                                    </CardContent>
                                </CardActionArea>
                                <IconButton
                                    color="secondary"
                                    variant="contained"
                                    style={{position: "absolute", top: 10, right: 10, backgroundColor: "rgba(255, 255, 255, 0.75)"}}
                                    onClick={() => deleteForm(form.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Forms;
