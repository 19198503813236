import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import headerImg from "../../assets/images/headerImg1.jpg";
import ContactForm from "./ContactUsForm";
import Map from "./Map";

const style = makeStyles({
    pagination: {
        margin: 20,
        display: "flex",
        justifyContent: "center",
    },
});
const ContactUs = () => {
    const classes = style();
    return (
        <Grid container>
            <PageHeader title="تماس با ما" image={headerImg} />
            <MainContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    آدرس
                                </Typography>
                                <Typography>
                                    رشت-چهارراه گلسار-خیابان بخت پسند-ساختمان
                                    ماهرو-طبقه دوم_واحد پنج
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    تلفن
                                </Typography>
                                <Typography>01332124461</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    پشتیبانی
                                </Typography>
                                <Typography>09111368417</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h5">
                                    ایمیل
                                </Typography>
                                <Typography>
                                    radiet.4762@gmail.com
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContactForm />
                    </Grid>
                    <Grid item xs={12}>
                        <Map />
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default ContactUs;
