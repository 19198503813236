const Inputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "عنوان",
                variant: "outlined",
                value: defaults.name,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        description: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                variant: "outlined",
                value: defaults.description,
                multiline: true,
                rows: 4,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default Inputs;
