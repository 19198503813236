import React from "react";
import { FormGroup, FormControlLabel, Checkbox, Typography } from "@material-ui/core";

import CheckBox from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxFilled from "@material-ui/icons/CheckBox";

const CheckBoxGroup = (props) => {
    const { label, options, value, onChange, controlProps, checkboxProps, ...otherProps } = props;

    return (
        <FormGroup column="true" {...otherProps}>
            <Typography style={{marginTop: 20}} component="h3" variant="h3">{label}</Typography>
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    value={option.value}
                    {...controlProps}
                    control={
                        <Checkbox
                            color="#000"
                            icon={<CheckBox />}
                            checkedIcon={<CheckBoxFilled />}
                            {...checkboxProps}
                            disableRipple
                            checked={value.indexOf(option.value) !== -1}
                            onChange={() => {
                                if (value.indexOf(option.value) === -1) onChange([...value, option.value]);
                                else onChange(value.filter((item) => item !== option.value));
                            }}
                        />
                    }
                    label={option.label}
                />
            ))}
        </FormGroup>
    );
};

export default CheckBoxGroup;
