import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { hideDialog } from "../../Redux/actions/dialogActions";

const style = makeStyles({
    root: {
        "& .MuiDialogContent-root": {
            padding: "20px 24px !important",
        },
    },
});
const MainDialog = () => {
    const classes = style();
    const dispatch = useDispatch();
    const dialog = useSelector((state) => ({ ...state.dialog }));
    const handleClose = () => {
        dispatch(hideDialog());
    };

    return (
        <Dialog
            open={dialog.show}
            onClose={handleClose}
            maxWidth={dialog.size}
            fullWidth={true}
            className={classes.root}
        >
            {/* <DialogTitle>test</DialogTitle> */}
            <DialogContent>{dialog.data}</DialogContent>
            {dialog.footer && (
                <DialogActions style={{ padding: "10px 24px" }}>
                    <Button
                        style={{ width: 110, height: 40 }}
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                    >
                        بستن
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default MainDialog;
