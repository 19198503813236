import React from "react";
import { Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { getAllGroupsApi } from "../../../../API/Forms";
import useApi from "../../../../Hooks/UseApi";
import styles from "../style";

import DataTable from "../../../../Components/Tables/DataTables";
import ActionButtons from "../../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../../Components/UI/Buttons/AddButton";


const Forms = () => {
    const history = useHistory();
    const HeaderData = [
        { field: "name", title: "گروه" },
        { field: "action", title: "ویرایش/حذف" },
    ];
    const [groups, setGroups] = React.useState([]);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllGroupsApi);
        setGroups(data);
    }, []);
    const editModal = () => {};
    const deleteModal = () => {};
    return (
        <Grid container>
            {/* <Grid item xs={12}>
                <AddButton title="درخواست رژیم جدید" link="/profile/personal-info" />
            </Grid> */}
            <Grid item xs={12}>
                <DataTable
                    title="لیست گروه فرم ها"
                    headData={HeaderData}
                    data={groups}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                edit: () => history.push(`/dashboard/forms/${item.id}`),
                            }}
                            edit
                        />
                    )}
                    arrayFields={[{ name: "types", field: ["name"], seprator: "،" }]}
                    // changeLimit={(limit) => setLimit(limit)}
                    // limit={limit}
                    // lastPage={lastPage}
                    // changePage={(page) => setPage(page)}
                />
            </Grid>
        </Grid>
    );
};

export default Forms;


