import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import Coworker from "./CoWorker";
import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import Title from "../../Components/UI/Title";
import headerImg from "../../assets/images/headerImg1.jpg";
import background from "../../assets/images/background.svg";
import coworker03 from "../../assets/images/avatar/01.jpg";
import coworker02 from "../../assets/images/avatar/02.jpg";
import coworker01 from "../../assets/images/avatar/03.jpg";

import medical from "../../assets/images/documents/medical.jpg";
import coach from "../../assets/images/documents/coach.jpg";
import coach3 from "../../assets/images/documents/coach3.jpg";

const style = makeStyles({
    AboutUsText: {
        textAlign: "justify",
    },
    coworkersContainer: {
        position: "relative",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        // height: 500,
    },
    documents: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});
const AbouteUs = () => {
    const classes = style();
    const data = [
        {
            name: "فهیمه جمالی",
            position: "مدیر سایت",
            image: coworker01,
        },
        {
            name: "امیر حسین راد",
            position: "متخصص تغذیه",
            image: coworker02,
        },
        {
            name: "امیر حسین راد",
            position: "مربی بدنساز",
            image: coworker03,
        },
    ];
    return (
        <Grid container>
            <PageHeader title="درباره ما" image={headerImg} />
            <MainContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body1"
                            className={classes.AboutUsText}
                        >
                            رادایت سایت تخصصی رژیم درمانی برای پشتیبانی از تمام
                            گروه های سنی و با هر نوع بیماری ای زمینه ای است.
                            همچنین با تمرینات ویژه بدنسازی در خدمت شما می باشد
                            این سایت زیر نظر مشاور تغذیه و رژیم درمانی و
                            فیزیولوژیست ورزشی است
                        </Typography>
                        ‍
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.documents}>
                        <a href={medical} target="_blank">
                            <img style={{ width: 300 }} src={medical} />
                        </a>
                    </Grid>
                    
                    <Grid item xs={12} md={4} className={classes.documents}>
                        <a href={coach3} target="_blank">
                            <img style={{ width: 300 }} src={coach3} />
                        </a>
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.documents}>
                        <a href={coach} target="_blank">
                            <img style={{ width: 300 }} src={coach} />
                        </a>
                    </Grid>
                </Grid>
            </MainContainer>
            <Grid container className={classes.coworkersContainer}>
                <Title label="تیم رادایت" />
                <MainContainer>
                    {data.map((coworker) => (
                        <Grid item xs={12} md={4}>
                            <Coworker data={coworker} />
                        </Grid>
                    ))}
                </MainContainer>
            </Grid>
        </Grid>
    );
};

export default AbouteUs;
