import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { hideDialog } from "../../../Redux/actions/dialogActions";
import useApi from "../../../Hooks/UseApi";
import { triggerBlockUsersApi } from "../../../API/users";

const RemoveUser = ({ id, remove, unblock }) => {
    const dispatch = useDispatch();

    const [data, loading, callApi] = useApi();
    const submitForm = async () => {
        let data = await callApi(triggerBlockUsersApi, { id, action: unblock ? "unblock" : "block" });
        if (data) {
            remove(data.id);
        }
        dispatch(hideDialog());
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            component="h4"
                            style={{ textAlign: "center" }}
                        >{`آیا از ${
                            unblock ? "لغو بلاک" : "بلاک"
                        } کردن این کاربر اطمینان دارید؟`}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disableElevation
                            onClick={() => dispatch(hideDialog())}
                            variant="contained"
                            color="primary"
                        >
                            بستن
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            disableElevation
                            onClick={submitForm}
                            variant="contained"
                            color="secondary"
                        >
                            {unblock ? "لغو بلاک" : "بلاک"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RemoveUser;
