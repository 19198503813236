import React from "react";
import { Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";
import momentj from "moment-jalaali";
import translator from "../../../shared/translator";
import SingleField from "./SingleField";

const styles = makeStyles({
    root: {
        padding: 20,
    },
    rowContainer: {
        // border: "solid 1px #ccc",
        position: "relative",
        padding: 20,
    },
    rowTitle: {
        backgroundColor: "#ccc",
        // position: "absolute",
        padding: "10px 10px",
    },
});
const RequestDetiles = ({ title, data }) => {
    const classes = styles();
    const setValue = (value) => {
        if (value === true) {
            return "بله";
        }
        if (value === false) {
            return "خیر";
        }
        if (value == null || value == "") {
            return "ندارم";
        }
        return value;
    };
    return (
        <Grid container spacing={2} className={classes.rowContainer}>
            <Grid item xs={12}>
                <Typography className={classes.rowTitle} variant="h4" component="h4">
                    اطلاعات شخصی
                </Typography>
            </Grid>
            <SingleField label="نام" value={`${data.name} ${data.surname}`} />
            <SingleField label="موبایل" value={`${data.mobile}`} />

            {data.personal_info && (
                <>
                    <SingleField label="تاریخ تولد" value={`${momentj(data.personal_info.birthday, "YYYY-MM-DD").format("jYYYY/jMM/jDD")}`} />
                    <SingleField label="سن" value={`${moment().diff(data.personal_info.birthday, "years")}`} />
                    <SingleField label="شغل" value={`${data.personal_info.job}`} />
                    <SingleField label="تلفن" value={`${data.personal_info.phone}`} />
                    <SingleField label="جنسیت" value={`${data.personal_info.sex}`} />
                    <SingleField label="آدرس" value={`${data.personal_info.address}`} />
                </>
            )}

            <Grid item xs={12}>
                <Typography className={classes.rowTitle} variant="h3" component="h3">
                    اطلاعات همسر
                </Typography>
            </Grid>
            {data.marrage_info &&
                (data.marrage_info.partner_weight ? (
                    <>
                        <SingleField label="وزن همسر" value={`${data.marrage_info.partner_weight}`} />
                        <SingleField label="قد همسر" value={`${data.marrage_info.partner_height}`} />
                        <SingleField label="همسرتان از مضعیت جسمانیتان راضی است؟" value={`${data.marrage_info.partner_complains ? "بله" : "خیر"}`} />
                        <SingleField label="تعداد فرزند" value={`${data.marrage_info.children}`} />
                        <SingleField label="فرزند بالای ۴ کیلو" value={`${data.marrage_info.child_above_4kg ? "بله" : "خیر"}`} />
                    </>
                ) : (
                    <SingleField label="وضعیت تاهل" value="مجرد" />
                ))}

            <Grid item xs={12}>
                <Typography className={classes.rowTitle} variant="h3" component="h3">
                    اطلاعات رژیم
                </Typography>
            </Grid>
            {data.diet_info && (
                <>
                    <SingleField label="چه وزنی را برای خود مناسب میدانید" value={`${data.diet_info.desire_weight}`} />
                    <SingleField label="تاکنون آن را تجربه کرده اید؟" value={`${setValue(data.diet_info.experienced_desire_weight)}`} />
                    <SingleField label="قبلا رژیم گرفته اید؟" value={`${setValue(data.diet_info.had_diet)}`} />
                    <SingleField label="نمره بدن در آینه" value={`${data.diet_info.self_score}`} />
                </>
            )}
            {data.personal_info?.sex === "FEMALE" && (
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.rowTitle} variant="h3" component="h3">
                            اطلاعات زنانگی
                        </Typography>
                    </Grid>
                    <SingleField label="آیا قاعده میشوید؟" value={`${setValue(data.woman_info.menstruation)}`} />
                    <SingleField label="قاعدگیتان منظم است؟" value={`${setValue(data.woman_info.regular_menstruation)}`} />
                </>
            )}
            <Grid item xs={12}>
                <Typography className={classes.rowTitle} variant="h3" component="h3">
                    عادات روزانه
                </Typography>
            </Grid>
            {data.routine_info && (
                <>
                    <SingleField label="صبحانه" value={`${data.routine_info.breakfast}`} />
                    <SingleField label="نهار" value={`${data.routine_info.lunch}`} />
                    <SingleField label="شام" value={`${data.routine_info.dinner}`} />
                    <SingleField label="سیگار میکشم" value={`${setValue(data.routine_info.smoking)}`} />
                    <SingleField label="مشروب میخورم" value={`${setValue(data.routine_info.drinking)}`} />
                    <SingleField label="میان وعده" value={`${data.routine_info.description}`} />
                </>
            )}

            <Grid item xs={12}>
                <Typography className={classes.rowTitle} variant="h3" component="h3">
                    هدف
                </Typography>
            </Grid>
            {data.goal && (
                <>
                    <SingleField label="هدف" value={`${data.goal}`} />
                </>
            )}
        </Grid>
    );
};

export default RequestDetiles;
