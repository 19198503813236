import {InputAdornment} from "@material-ui/core";

const MeasuresInputs = (defaults) => {
    return {
        disablity: {
            elementType: "switch",
            elementConfig: {
                label: "معلولیت دارم",
                variant: "outlined",
                value: defaults.disablity,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        height: {
            elementType: "text-field",
            elementConfig: {
                label: "قد",
                variant: "outlined",
                value: defaults.height,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            CM
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        },
        weight: {
            elementType: "text-field",
            elementConfig: {
                label: "وزن",
                variant: "outlined",
                value: defaults.weight,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            KG
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        },
        waist: {
            elementType: "text-field",
            elementConfig: {
                label: "دور شکم",
                variant: "outlined",
                value: defaults.waist,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            CM
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        },
        hip: {
            elementType: "text-field",
            elementConfig: {
                label: "دور باسن",
                variant: "outlined",
                value: defaults.hip,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            CM
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        },
        wrist: {
            elementType: "text-field",
            elementConfig: {
                label: "دور مچ",
                variant: "outlined",
                value: defaults.wrist,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            CM
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        },
        ulna: {
            elementType: "text-field",
            elementConfig: {
                label: "طول ساعد",
                variant: "outlined",
                value: defaults.ulna,
                InputProps: {
                    startAdornment: (
                        <InputAdornment position="start">
                            CM
                        </InputAdornment>
                    ),
                }
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 2,
            },
        }
    }
};

export default MeasuresInputs;
