import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import useApi from "../../../../Hooks/UseApi";
import {getAllCatApi} from "../../../../API/product";
import { showDialog } from "../../../../Redux/actions/dialogActions";
import DataTable from "../../../../Components/Tables/DataTables";
import ActionButtons from "../../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../../Components/UI/Buttons/AddButton";
import AddCategory from "./AddCategory"

const ArticlesCategories = () => {
    const dispatch = useDispatch();
    const [Categories, setCategories] = React.useState([]);
    const HeaderData = [
        { field: "name", title: "عنوان" },
        { field: "description", title: "توضیحات" },
        { field: "action", title: "حذف" },
    ];

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllCatApi);
        setCategories(data);
    }, []);
    const editModal = () => {};
    const showAddModal = () => {
        dispatch(showDialog(<AddCategory add={(item) => AddNewCat(item)} />, false, 'sm'));
    };
    const AddNewCat = (item) => {
        setCategories([item,...Categories])
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <AddButton title="اضافه کردن دسته بندی جدید" onClick={showAddModal} />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title="لیست دسته بندی مقالات"
                    headData={HeaderData}
                    data={Categories}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                edit: () => editModal(item),
                            }}
                            remove
                        />
                    )}
                    // changeLimit={(limit) => setLimit(limit)}
                    // limit={limit}
                    // lastPage={lastPage}
                    // changePage={(page) => setPage(page)}
                />
            </Grid>
        </Grid>
    );
};

export default ArticlesCategories;


