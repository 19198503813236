import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  makeStyles,
  Typography,
  Card,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import moment from "moment-jalaali";

import currencySeparator from "../../shared/currencySeparator";


const Styles = makeStyles({
  TableContainer: {
    marginTop: 20,
    boxShadow: "0 0 20px 0 #d7dee366",
    width: "100%",
  },
  tabelTitle: {
    fontSize: 20,
    padding: "20px 50px",
  },
  Thead: {
    backgroundColor: "#f9fafc",
    "& th": {
      color: "#a6aec9",
      textAlign: "center",
      fontSize: 16,
    },
  },
  TRow: {
    backgroundColor: "#fff",
    "& td": {
      textAlign: "center",
      fontSize: 16,
    },
  },
  pagination: {
    margin: 20,
    "& ul": {
      justifyContent: "flex-end",
    },
  },
});

const DataTable = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = Styles();
  const {
    headData,
    data,
    title,
    changeLimit,
    limit,
    arrayFields,
    currentPage,
    route,
    headerMobileField,
  } = props;

  const arrayToString = (row, field) => {
    let arrayField = arrayFields.filter((item) => item.name === field)[0];
    let string = "";
    row[field].map((item, key) => {
      let text = "";
      arrayField.field.map((f) => {
        text = text + " " + (item[f] ? item[f] : "");
      });
      string =
        string +
        text +
        (key !== row[field].length - 1 ? arrayField.seprator : "");
    });
    return string;
  };

  const fixFieldValue = (row, fieldName) => {
    let fieldType = headData.filter((head) => head.field === fieldName)[0].type;
    switch (fieldType) {
      case "array":
        return arrayToString(row, fieldName);
      case "date":
        return row[fieldName]
          ? moment(row[fieldName], "YYYY/MM/DD").format("jYYYY/jMM/jDD")
          : "----";
      case "dateTime":
        return row[fieldName]
          ? moment(row[fieldName], "YYYY/MM/DD HH:mm:ss").format(
              "jYYYY/jMM/jDD HH:mm:ss"
            )
          : "----";
      case "currency":
        return row[fieldName] === null
          ? "0"
          : currencySeparator(row[fieldName]);
      default:
        return row[fieldName] ? row[fieldName] : "----";
    }
  };

  return (

        <Card className={classes.TableContainer}>
          {title && (
            <Typography className={classes.tabelTitle}>{title}</Typography>
          )}
          <TableContainer style={{ padding: 1 }}>
            <Table>
              <TableHead>
                <TableRow className={classes.Thead}>
                  {headData.map((cell, index) => (
                    <TableCell key={index}>{cell.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    style={{
                      opacity: row?.banned ? 0.6 : 1,
                      backgroundColor: row?.banned ? "#ccc" : "#fff",
                    }}
                    className={classes.TRow}
                    key={index}
                  >
                    {headData.map((cell, key) => (
                      <TableCell key={key} className={classes.rowTitle}>
                        {row[cell.field] === undefined &&
                          props[cell.field](row, index + 1)}
                        {row[cell.field] !== undefined &&
                          fixFieldValue(row, cell.field)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {props.lastPage && (
              <Grid container>
                {/* <Grid item xs={6}>
                      <PerPage
                        limit={limit}
                        changeLimit={(limit) => changeLimit(limit)}
                      />
                    </Grid> */}
                {data.length === 0 ? (
                  <Typography
                    style={{ padding: 10, width: "100%", textAlign: "center" }}
                  >
                    هیچ اطلاعاتی برای نمایش وجود ندارد
                  </Typography>
                ) : (
                  <Grid item xs={6}>
                    <Pagination
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          to={`${route}?page=${item.page}`}
                          {...item}
                        />
                      )}
                      defaultPage={parseInt(currentPage)}
                      className={classes.pagination}
                      count={props.lastPage}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </TableContainer>
        </Card>
  );
};

export default DataTable;
