import Dashboard from "../Pages/Dashboard/Dashboard/Dashboard";
import DietRequests from "../Pages/Dashboard/DietRequests/DietRequests";
import ExerciseRequests from "../Pages/Dashboard/ExerciseRequests/ExerciseRequests";

import Requests from "../Pages/Dashboard/Requests/Requests";
import UsersContainer from "../Pages/Dashboard/Users/UsersContainer";
import Users from "../Pages/Dashboard/Users/Users";
import WriteForm from "../Pages/Dashboard/WriteForm/WriteForm";
import Reponses from "../Pages/Dashboard/Reponses/Reponses";
import SingleReponse from "../Pages/Dashboard/SingleReponse/SingleReponse";
import Reports from "../Pages/Dashboard/Reports/Reports";
import SingleRequest from "../Pages/Dashboard/SingleRequest/SingleRequest";
import MySingleResponse from "../Pages/Dashboard/DietRequests/MySingleResponse";
import EditRequest from "../Pages/Dashboard/EditRequest/EditRequest";
import Tickets from "../Pages/Dashboard/Tickets/Tickets";
import TicketMessages from "../Pages/Dashboard/TicketMessages/TicketMessages";
import Profile from "../Pages/Dashboard/Profile/Profile";
import AddRequest from "../Pages/Dashboard/Admin/AddRequest/AddRequest";
import EditProfile from "../Pages/Dashboard/Admin/EditProfile/EditProfile";

//cms
import Videos from "../Pages/Dashboard/CMS/Videos/Videos";
import VideosCategories from "../Pages/Dashboard/CMS/Videos/VideosCategories";
import Articles from "../Pages/Dashboard/CMS/Article/Articles";
import ArticlesCategories from "../Pages/Dashboard/CMS/Article/ArticlesCategories";
import SingleArticle from "../Pages/Dashboard/CMS/Article/SingleArticle";
import AbouteUs from "../Pages/Dashboard/CMS/AbouteUs/AbouteUs";
import ContactUs from "../Pages/Dashboard/CMS/ContactUs/ContactUs";
import HomeSetting from "../Pages/Dashboard/CMS/Home/HomeSetting";
import Forms from "../Pages/Dashboard/CMS/Forms/Forms";
import SingleForm from "../Pages/Dashboard/CMS/Forms/SingleForm";

//icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PieChartIcon from "@material-ui/icons/PieChart";
import GroupIcon from "@material-ui/icons/Group";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import SettingsIcon from "@material-ui/icons/Settings";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@material-ui/icons/ViewList';
import BallotIcon from '@material-ui/icons/Ballot';
import VideocamIcon from '@material-ui/icons/Videocam';
import ChatIcon from '@material-ui/icons/Chat';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

export const userRoutes = [
    {
        path: "/dashboard/statistic",
        component: Dashboard,
        show: true,
        icon: DashboardIcon,
        name: "داشبورد",
    },
    {
        path: "/dashboard/diet-requests/edit/:requestId",
        component: EditRequest,
        show: false,
        icon: LocalDiningIcon,
        name: "درخواست ها",
    },
    {
        path: "/dashboard/diet-requests/:requestId",
        component: MySingleResponse,
        show: false,
        icon: LocalDiningIcon,
        name: "جواب درخواست",
    },
    {
        path: "/dashboard/diet-requests",
        params: {page: 1},
        component: DietRequests,
        show: true,
        icon: LocalDiningIcon,
        name: "درخواست ها",
    },
    {
        path: "/dashboard/tickets/:ticketId",
        component: TicketMessages,
        show: false,
        icon: ChatIcon,
        name: "پیام ها",
    },
    {
        path: " https://wa.me/+989111368417",
        external: true,
        component: Tickets,
        show: true,
        icon: ChatIcon,
        name: "پشتیبانی",
    },
    {
        path: "/profile/personal-info",
        component: Profile,
        show: true,
        icon: AccountBoxIcon,
        name: "پروفایل",
    },
];

export const writerRoute = [
    {
        path: "/dashboard/statistic",
        component: Dashboard,
        show: true,
        icon: DashboardIcon,
        name: "داشبورد",
    },
    {
        path: "/dashboard/users/unBlocked/:id",
        component: EditProfile,
        show: false,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/users/unBlocked",
        params: {page: 1},
        component: UsersContainer,
        show: true,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/users/blocked",
        params: {page: 1},
        component: UsersContainer,
        show: false,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/requests/add/:userId/:requestId",
        component: AddRequest,
        show: false,
        icon: ListAltIcon,
        name: "درخواست",
    },
    {
        path: "/dashboard/requests/:requestId",
        component: SingleRequest,
        show: false,
        icon: ListAltIcon,
        name: "درخواست",
    },
    {
        path: "/dashboard/requests",
        params: {page: 1},
        component: Requests,
        show: true,
        icon: ListAltIcon,
        name: "درخواست ها",
    },
    {
        path: "/dashboard/reponses/unAnswred",
        params: {page: 1},
        component: Reponses,
        show: true,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    {
        path: "/dashboard/reponses/answred",
        params: {page: 1},
        component: Reponses,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    // {
    //     path: "/dashboard/reponses/:reponsesId/form/:templateId",
    //     component: WriteForm,
    //     show: false,
    //     icon: ConfirmationNumberIcon,
    //     name: "نوشتن روی فرم",
    // },
    {
        path: "/dashboard/reponses/:reponsesId",
        component: SingleReponse,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ",
    },
    
    {
        path: "/dashboard/tickets/:ticketId",
        component: TicketMessages,
        show: false,
        icon: ChatIcon,
        name: "پیام ها",
    },
    {
        path: "/dashboard/tickets",
        params: {page: 1},
        component: Tickets,
        show: true,
        icon: ChatIcon,
        name: "پشتیبانی",
    },
];

export const assistantRoute = [
    {
        path: "/dashboard/statistic",
        component: Dashboard,
        show: true,
        icon: DashboardIcon,
        name: "داشبورد",
    },
    {
        path: "/dashboard/reponses/unAnswred",
        params: {page: 1},
        component: Reponses,
        show: true,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    {
        path: "/dashboard/reponses/answred",
        params: {page: 1},
        component: Reponses,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    {
        path: "/dashboard/reponses/:reponsesId",
        component: SingleReponse,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ",
    },
];

export const adminRoute = [
    {
        path: "/dashboard/statistic",
        component: Dashboard,
        show: true,
        icon: DashboardIcon,
        name: "داشبورد",
    },
    {
        path: "/dashboard/users/unBlocked/:id",
        component: EditProfile,
        show: false,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/users/unBlocked",
        params: {page: 1},
        component: UsersContainer,
        show: true,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/users/blocked",
        params: {page: 1},
        component: UsersContainer,
        show: false,
        icon: GroupIcon,
        name: "کاربران",
    },
    {
        path: "/dashboard/requests/add/:userId/:requestId",
        component: AddRequest,
        show: false,
        icon: ListAltIcon,
        name: "درخواست",
    },
    {
        path: "/dashboard/requests/:requestId",
        component: SingleRequest,
        show: false,
        icon: ListAltIcon,
        name: "درخواست",
    },
    {
        path: "/dashboard/requests",
        params: {page: 1},
        component: Requests,
        show: true,
        icon: ListAltIcon,
        name: "درخواست ها",
    },
    {
        path: "/dashboard/reponses/unAnswred",
        params: {page: 1},
        component: Reponses,
        show: true,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    {
        path: "/dashboard/reponses/answred",
        params: {page: 1},
        component: Reponses,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ ها",
    },
    {
        path: "/dashboard/reponses/:reponsesId/form/:templateId",
        component: WriteForm,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "نوشتن روی فرم",
    },
    {
        path: "/dashboard/reponses/:reponsesId",
        component: SingleReponse,
        show: false,
        icon: ConfirmationNumberIcon,
        name: "پاسخ",
    },
    
    {
        path: "/dashboard/tickets/:ticketId",
        component: TicketMessages,
        show: false,
        icon: ChatIcon,
        name: "پیام ها",
    },
    {
        path: "/dashboard/tickets",
        params: {page: 1},
        component: Tickets,
        show: true,
        icon: ChatIcon,
        name: "پشتیبانی",
    },
    // {
    //     path: "/dashboard/reports",
    //     component: Reports,
    //     show: true,
    //     icon:  PieChartIcon,
    //     name: "گزارش",
    // },
];

export const CMSRoute = [
    {
        path: "/dashboard/forms/:groupId",
        component: SingleForm,
        show: false,
        icon: AssignmentIcon,
        name: "فرم",
    },
    {
        path: "/dashboard/forms",
        component: Forms,
        show: true,
        icon: AssignmentIcon,
        name: "فرم ها",
    },
    {
        path: "/dashboard/videos/video-categories",
        component: VideosCategories,
        show: false,
        icon: VideoLibraryIcon,
        name: "دسته بندی ها",
    },
    {
        path: "/dashboard/videos",
        component: Videos,
        show: true,
        icon: VideocamIcon,
        name: "ویدیو ها",
        extendable: [
            {
                path: "/dashboard/videos",
                Icon: VideoLibraryIcon,
                name: "ویدیو ها",
            },
            {
                path: "/dashboard/videos/video-categories",
                Icon: BallotIcon,
                name: "دسته بندی ویدیو ها",
            },
        ],
    },
    {
        path: "/dashboard/articles/article-categories",
        component: ArticlesCategories,
        show: false,
        icon: VideoLibraryIcon,
        name: "دسته بندی مقالات",
    },
    {
        path: "/dashboard/articles/:id",
        component: SingleArticle,
        show: false,
        icon: VideoLibraryIcon,
        name: "مقاله",
    },
    {
        path: "/dashboard/articles",
        component: Articles,
        show: true,
        icon: PostAddIcon,
        name: "مقالات",
        extendable: [
            {
                path: "/dashboard/articles",
                Icon: ViewListIcon,
                name: "لیست مقالات",
            },
            {
                path: "/dashboard/articles/new-article",
                Icon: AddIcon,
                name: "مقاله جدید",
            },
            {
                path: "/dashboard/articles/article-categories",
                Icon: BallotIcon,
                name: "دسته بندی مقالات",
            },
        ],
    },
    {
        path: "/dashboard/aboute-us",
        component: AbouteUs,
        show: true,
        icon: AssignmentIndIcon,
        name: "درباره ما",
    },
    {
        path: "/dashboard/contact-us",
        component: ContactUs,
        show: true,
        icon: ContactMailIcon,
        name: "تماس با ما",
    },
    {
        path: "/dashboard/home-setting",
        component: HomeSetting,
        show: true,
        icon: SettingsIcon,
        name: "تنظیمات صفحه اصلی",
    },
];
