import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        "& label ": {
            fontSize: 14,
        },
    },
    option: {
        fontSize: 14,
        paddingLeft: theme.spacing(4),
    },
    input: {
        fontSize: 15,
    },
    paper: {
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    listbox: {
        // maxHeight: 150,
    },
}));

const SearchListInput = (props) => {
    const classes = useStyles();
    const {
        value,
        label,
        options,
        onChange,
        inputChange,
        getOptionLabel,
        inputParams,
        required,
        noOptionsText,
        loading,
        multiple,
        onEnterPress,
        defaultValue,
        ...otherProps
    } = props;

    const addInput = (value) => {
        onEnterPress && onEnterPress(value);
    };
    const localOnChange = (value) => {
        onChange(value);
    };
    const setDefaultValue = () => {
        return defaultValue?.map((def) => {
            return options.find((option) => option.value === def.value);
        });
    };

    return (
        <>
            {multiple ? (
                <Autocomplete
                    multiple
                    options={options}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => localOnChange(value)}
                    value={setDefaultValue()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={props.className}
                            required={required}
                            label={label}
                            helperText={otherProps?.helperText}
                            error={otherProps?.error}
                            disabled={otherProps?.disabled}
                            variant={props.variant}
                            {...inputParams}
                        />
                    )}
                    classes={{
                        root: classes.root,
                        option: classes.option,
                        input: classes.input,
                        noOptions: classes.input,
                        paper: classes.paper,
                        listbox: classes.listbox,
                    }}
                />
            ) : (
                <Autocomplete
                    getOptionLabel={(option) => option.name ?? ""}
                    options={options ? options : []}
                    fullWidth
                    onChange={!inputChange ? (event, value) => localOnChange(value) : ""}
                    onInputChange={(event, value) => onChange(value)}
                    noOptionsText={noOptionsText ? noOptionsText : "بی نتیجه"}
                    inputValue={value?.name ? value.name : value}
                    clearOnBlur={!inputChange}
                    value={value?.name && value}
                    disabled={otherProps?.disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={props.className}
                            onKeyDown={(e) => e.keyCode == 32 && addInput(e.target.value)}
                            required={required}
                            label={label}
                            helperText={otherProps?.helperText}
                            error={otherProps?.error}
                            disabled={otherProps?.disabled}
                            variant={props.variant}
                            {...inputParams}
                        />
                    )}
                    classes={{
                        root: classes.root,
                        option: classes.option,
                        input: classes.input,
                        noOptions: classes.input,
                        paper: classes.paper,
                        listbox: classes.listbox,
                    }}
                />
            )}
        </>
    );
};

export default SearchListInput;
