import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment-jalaali";

const styles = makeStyles({
    root: {
        padding: 10,
        margin: 0,
        width: "100%",
        // marginBottom: 10,
        // borderRadius: 7,
        position: "relative",
        // boxShadow: "3px 3px 4px 2px #dfdfdf"
    },
    content: {
        padding: 10,
        margin: 0,
        width: "70%",
        marginBottom: 10,
        borderRadius: 7,
        position: "relative",
        backgroundColor: "#eee",
    },
    rightTriangle: {
        width: 0,
        height: 0,
        borderTop: "0px solid transparent",
        borderRight: "35px solid #dbf7c6;",
        borderBottom: "40px solid transparent",
        position: "absolute",
        top: 10,
        left: -25,
    },
    leftTriangle: {
        width: 0,
        height: 0,
        borderTop: "0px solid transparent",
        borderLeft: "35px solid #eee",
        borderBottom: "40px solid transparent",
        position: "absolute",
        top: 10,
        right: -25,
    },
});

const Message = ({ data }) => {
    const classes = styles();
    const user = useSelector((state) => state.user);
    const isMe = user.id === data.user_id;
    return (
        <Grid container className={classes.root} style={{direction: isMe ? "rtl" : "ltr"}}>
            <div
                className={isMe ? classes.rightTriangle : classes.leftTriangle}
            ></div>
            <Grid item>
                <Grid
                    container
                    className={classes.content}
                    style={
                        isMe
                            ? {
                                  borderTopRightRadius: 0,
                                  backgroundColor: "#dbf7c6",
                              }
                            : { borderTopLeftRadius: 0, direction: "ltr" }
                    }
                >
                    <Grid item xs={12}>
                        <Typography variant="h4">{`${
                            data.user?.name ?? user.name
                        } ${data.user?.surname ?? user.surname}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{textAlign: "right"}}>{data.message}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{
                                // direction: "ltr",
                                padding: 10,
                                textAlign: isMe ? "left" : "right",
                            }}
                        >
                            {moment(
                                data.created_at,
                                "YYYY/MM/DD HH:mm:ss"
                            ).format("jYYYY/jMM/jDD HH:mm:ss")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Message;
