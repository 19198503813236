import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import currencySeparator from "../../shared/currencySeparator";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    th: {
        fontFamily: "IRANSansBold",
    },
    totalContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 40
    }
});

const PaymentTable = ({data, amounts, daysPerWeek}) => {
    const classes = useStyles();
    const [Rows, setRows] = React.useState([]);
React.useEffect(() => {
    let rows = []
    if (data.exercise){
        rows = [...rows, { type: "برنامه تمرین", price: (amounts.exercise_amount * daysPerWeek) + parseInt(amounts.exercise_base_amount)}]
    }
    if (data.diet){
        let hasDiet = data.diet.find(item => (item.id !== 'NEW_YEAR' && item.id !== 'RAMEZAN'));
        if (hasDiet) {
            rows.push({type: "رژیم غذایی", price: amounts.diet_amount});
        }
        data.diet.map(item => {
            if (item.id === 'NEW_YEAR' || item.id === 'RAMEZAN'){
                rows.push({type: `رژیم غذایی ${item.name}`, price: amounts.other_amount});
            }
        })
        // rows = [...rows, { type: "رژیم غذایی", price: data.other_diet ? parseInt(amounts.other_amount) + parseInt(amounts.diet_amount) : amounts.diet_amount}]
    }
    setRows(rows);
}, [data])

    const calculate = () => {
        let total = 0;
        Rows.map((row) => {
            total = total + parseInt(row.price);
        });
        return currencySeparator(total);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    align="center"
                                    className={classes.th}
                                >
                                    درخواست
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.th}
                                >
                                    مبلغ (تومان)
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Rows.map((row) => (
                                <TableRow key={row.type}>
                                    <TableCell align="center">
                                        {row.type}
                                    </TableCell>
                                    <TableCell align="center">
                                        {currencySeparator(row.price)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} className={classes.totalContainer}>
                <Typography variant="h3" component="h3">
                    مبلغ قابل پرداخت :
                </Typography>
                <Typography variant="h3" component="h3" style={{marginRight: 20}}>
                    {calculate() + " تومان"}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PaymentTable;
