import React from "react";
import { Grid, Typography, makeStyles, TextField } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { addUserData } from "../../../Redux/actions/userActions";
import Button from "../../../Components/UI/Buttons/Button";
import { addRoutineApi, getRoutineApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import { toEnNumber } from "../../../shared/toEnNumber";
import Inputs from "./Inputs";
import Meal from "../../../Components/Surfaces/Meal/Meal";
import styles from "../style";

const Routine = () => {
    const dispatch = useDispatch();
    const classes = styles();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const [formData, setFormData] = React.useState(user.routine_info);
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(Inputs(formData), inputChangedHandler);

    const [data, loading, callApi] = useApi();
    const submitForm = async () => {
        const data = await callApi(addRoutineApi, formData, "/profile/diet-info");
        if (data) {
            dispatch(addUserData({routine_info: data.data}))
        }
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            عادت های روزانه
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formElements}
                        </Grid>
                        <Grid container spacing={3} className={classes.meal}>
                            <Typography variant="h4" component="h4" className={classes.mealTitle}>
                                وعده های غذایی
                            </Typography>
                            <Grid item xs={12}>
                                <Meal
                                    label="صبحانه"
                                    showTime
                                    inputValue={formData.breakfast}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "breakfast")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Meal
                                    label="نهار"
                                    showTime
                                    inputValue={formData.lunch}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "lunch")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Meal
                                    label="شام"
                                    showTime
                                    inputValue={formData.dinner}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "dinner")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Meal
                                    label="میان وعده"
                                    inputValue={formData.mid_meal}
                                    changeInput={(value) => inputChangedHandler(value ? true : false, "mid_meal")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="توضیحات(مصرف هر مواد غذایی غیر از آب میان وعده محسوب میشود)"
                                    multiline
                                    rows={4}
                                    value={formData.description ?? ""}
                                    onChange={(e) => inputChangedHandler(e.target.value, "description")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Routine;
