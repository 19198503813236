import React from "react";
import Cropper from 'react-easy-crop'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Dialog, DialogActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SingleUploadCard from "../../Surfaces/Cards/SingleUploadCard";

const useStyles = makeStyles({
    container: {width: "100%", height: '50vh', position: 'relative'}
});

const ImageCropper = ({onChange, label, value, ratio, id,error, shape = 'rect', style}) => {
    const styles = useStyles();
    const cropInitState = {zoom: 1, crop: {x: 0, y: 0}};
    const [config, setConfig] = React.useState(cropInitState)
    const [image, setImage] = React.useState(value);
    const [openDialog, setOpenDialog] = React.useState(false);
    const setCrop = crop => setConfig(state => ({...state, crop}))
    const setZoom = zoom => setConfig(state => ({...state, zoom}))
    const setCoords = React.useCallback((area, coords) => setConfig(state => ({...state, coords})), []);
    const uploadImage = img => {
        setImage(URL.createObjectURL(img))
        setConfig(cropInitState);
        setOpenDialog(true);
    }
    const getCroppedImg = () => {
        const innerImage = new Image();
        innerImage.src = image;

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const maxSize = Math.max(innerImage.width, innerImage.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))
        canvas.width = safeArea
        canvas.height = safeArea

        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.translate(-safeArea / 2, -safeArea / 2)

        ctx.drawImage(innerImage, safeArea / 2 - innerImage.width * 0.5, safeArea / 2 - innerImage.height * 0.5)
        const data = ctx.getImageData(0, 0, safeArea, safeArea)
        canvas.width = config.coords.width
        canvas.height = config.coords.height

        ctx.putImageData(
            data,
            Math.round(0 - safeArea / 2 + innerImage.width * 0.5 - config.coords.x),
            Math.round(0 - safeArea / 2 + innerImage.height * 0.5 - config.coords.y)
        )
        const finalImage = new Image();
        finalImage.src = canvas.toBlob(res => {
            onChange(res);
            setOpenDialog(false);
        }, 'image/jpeg');
    }
    return (<>
        <SingleUploadCard label={label} onChange={uploadImage} value={value} accept="image/*" id={id} style={style} error={error}/>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
            <div className={styles.container}>
                <Cropper
                    maxZoom={20} image={image} crop={config.crop} cropShape={shape} zoom={config.zoom} aspect={ratio}
                    onCropComplete={setCoords} onCropChange={setCrop} onZoomChange={setZoom}/>
            </div>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="secondary">لغو</Button>
                <Button onClick={getCroppedImg} color="primary" variant="contained">تایید</Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default ImageCropper