import { SET_REQUEST_DATA, REMOVE_REQUEST_DATA } from "../actions/requestActions";

const alert = (state = {}, action) => {
    switch (action.type) {
        case SET_REQUEST_DATA:
            state = action.data;
            return state;
        case REMOVE_REQUEST_DATA:
            return {};
        default:
            return state;
    }
};

export default alert;