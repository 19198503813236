const personalInfoInputs = (defaults) => {
    return {
        goal: {
            elementType: "text-field",
            elementConfig: {
                label: "هدف",
                variant: "outlined",
                value: defaults.goal ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default personalInfoInputs;
