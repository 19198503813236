import { Card } from "@material-ui/core";
import React from "react";
import { Line, defaults } from "react-chartjs-2";

defaults.font.family = 'IRANSans';

const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
        {
            label: "نمودار وزن نسبت به روز",
            data: [12, 19, 3, 5, 2, 3],
            fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgba(255, 99, 132, 0.2)",
        },
    ],
};

const LineChart = () => (
    <Card style={{ padding: 20 }}>
        <Line
            width={100}
            height={300}
            data={data}
            options={{
                maintainAspectRatio: false,
            }}
        />
    </Card>
);

export default LineChart;
