const personalInfoInputs = (defaults) => {
    return {
        has_exercises: {
            elementType: "switch",
            elementConfig: {
                label: "تمرین ورزشی میکنم",
                variant: "outlined",
                value: defaults.has_exercises,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        exercises: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                variant: "outlined",
                value: defaults.exercises ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    }}

    export default personalInfoInputs;