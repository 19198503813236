import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

const style = makeStyles({
    root: {
        display: "flex",
        justifyContent: "flex-end",
    },
    Btn: {
        backgroundColor: "#2A52AB",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#7e9ee3",
        },
    },
});

const AddButton = ({ title, link, onClick, fullwidth }) => {
    const classes = style();
    return (
        <Grid item xs={12} className={classes.root}>
            <Button
                component={link && Link}
                to={link}
                fullWidth={fullwidth}
                className={classes.Btn}
                variant="contained"
                startIcon={<Add />}
                onClick={onClick ?? null}
            >
                {title}
            </Button>
        </Grid>
    );
};

export default AddButton;
