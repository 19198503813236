import React, { useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const styles = makeStyles({
    root: {
        margin: 0,
    },
    image: {
        width: "100%",
        cursor: "pointer",
    },
});
const RequestDetilesImages = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const classes = styles();
    const images = [data.analysis_image, data.front_image, data.back_image];
    const openImage = (index) => {
        setOpen(!open);
        setPhotoIndex(index);
    };
    return (
        <Grid container spacing={3} className={classes.root}>
            {data.analysis_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.analysis_image} target="_blank">
                        <img title="آنالیز" className={classes.image} src={data.analysis_image} />
                    </a>
                </Grid>
            )}
            {data.front_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.front_image} target="_blank">
                        <img title="جلو" className={classes.image} src={data.front_image} />
                    </a>
                </Grid>
            )}
            {data.back_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.back_image} target="_blank">
                        <img title="پشت" className={classes.image} src={data.back_image} />
                    </a>
                </Grid>
            )}

            {data.front_open_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.front_open_image} target="_blank">
                        <img title="جلو دست باز" className={classes.image} src={data.front_open_image} />
                    </a>
                </Grid>
            )}
            {data.side_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.side_image} target="_blank">
                        <img title="بغل" className={classes.image} src={data.side_image} />
                    </a>
                </Grid>
            )}
            {data.side_open_image != "https://radiet.ir/api/storage" && (
                <Grid item xs={3}>
                    <a href={data.side_open_image} target="_blank">
                        <img title="بغل دست باز" className={classes.image} src={data.side_open_image} />
                    </a>
                </Grid>
            )}

            {data?.documents?.map((doc) => (
                <Grid item xs={3}>
                    <a href={doc.path} target="_blank">
                        <img className={classes.image} src={doc.path} title={doc.type === "examination" ? "آزمایش" : "سونوگرافی"} />
                    </a>
                </Grid>
            ))}

            {open && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
        </Grid>
    );
};

export default RequestDetilesImages;
