import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { addUserData } from "../../../Redux/actions/userActions";
import { addPersonalApi, getPersonalApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import Button from "../../../Components/UI/Buttons/Button";

import formCreator from "../../../shared/formCreator";
import personalInfoInputs from "./personalInfoInputs";
import styles from "../style";

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = React.useState(user.personal_info);
  const inputChangedHandler = (value, inputIdentifier) => {
    setFormData({ ...formData, [inputIdentifier]: value });
  };
  let formElements = formCreator(
    personalInfoInputs(formData),
    inputChangedHandler
  );
  const [data, loading, callApi] = useApi();
  const submitForm = async () => {
    const data = await callApi(
      addPersonalApi,
      formData,
      "/profile/marrid-info",
      null,
      "button"
    );
    if (data) {
      dispatch(addUserData({ personal_info: data.data }));
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              اطلاعات شخصی
            </Typography>
          </Grid>
          {formElements}
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              className={classes.nextBtn}
              disableElevation
              onClick={() => submitForm()}
              variant="contained"
              color="primary"
              title="بعدی"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
