import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../Components/UI/Buttons/Button";
import { fetchRequestData } from "../../../Redux/actions/requestActions";
import { addMeasuresApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import { showDialog } from "../../../Redux/actions/dialogActions";
import DialogContent from "./DialogContent";
import formCreator from "../../../shared/formCreator";
import Inputs from "./Inputs";
import styles from "../style";

const Measures = () => {
    const classes = styles();
    const dispatch = useDispatch();
    const request = useSelector((state) => state.request);
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({
        request_id: requestId,
        disablity: false,
    });
    React.useEffect(() => {
        dispatch(fetchRequestData(requestId));
    }, []);
    React.useEffect(() => {
        setFormData({ ...request, request_id: requestId });
    }, [request]);
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formData.disablity
        ? formCreator(Inputs(formData), inputChangedHandler)
        : formCreator(Inputs(formData), inputChangedHandler).splice(0, 6);

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(addMeasuresApi, formData, `/profile/request/${requestId}/alergies`);
    };
    const showModal = () => {
        dispatch(showDialog(<DialogContent />));
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            اندازه گیری
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            disableElevation
                            size="small"
                            className={classes.infoBtn}
                            onClick={showModal}
                            variant="contained"
                            startIcon={<InfoOutlinedIcon />}
                            title="راهنما اندازه گیری"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formElements}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Measures;
