import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";

import UploadCard from "../../Surfaces/Cards/UploadCard";
import FilePreview from "../Image/FilePreview";
import { showAlert } from "../../../Redux/actions/snackbarActions";

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
    },
    helperText: {
        display: "flex",
        justifyContent: "center",
    },
}));

const FileUploader = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const {
        onChange,
        onRemove,
        uploadedFiles,
        helperText,
        label,
        maxFilesCount,
        style,
        id,
    } = props;
    return (
        <Grid container spacing={2} style={{border: "solid 1px #ccc"}}>
            <Grid item xs={12}>
                <UploadCard
                    id={id}
                    style={style}
                    label={label ?? "افزودن اسناد"}
                    onChange={(event) => {
                        for (let i = 0; i < event.target.files.length; ++i) {
                            const file = event.target.files[i];
                            if (file.size > 5242880) {
                                dispatch(
                                    showAlert(
                                        `حجم فایل ورودی بیشتر از ۵ مگابایت است`,
                                        "error"
                                    )
                                );
                                return;
                            }
                        }
                        if (
                            uploadedFiles.length + event.target.files.length <=
                            maxFilesCount
                        )
                            onChange(event);
                        else
                            dispatch(
                                showAlert(
                                    `حداکثر تعداد فایل ${maxFilesCount} است`,
                                    "error"
                                )
                            );
                    }}
                />
                <Typography
                    className={classes.helperText}
                    variant="caption"
                    color="textSecondary"
                >
                    {helperText}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.previewContainer}>
                <Grid container>
                    {uploadedFiles?.map((file, index) => {
                        return (
                            <Grid item xs={4} key={index}>
                                <FilePreview
                                    src={file.link}
                                    label={file.name}
                                    // onClick={() => onRemove(index)}
                                    onClick={() =>
                                        onRemove(file.id ? file : index)
                                    }
                                    type={file.mime_type}
                                    height={100}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FileUploader;
