import React from "react";
import jMoment from "moment-jalaali";
import JalaliUtils from "@date-io/jalaali";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton, InputAdornment } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";

import EventIcon from "@material-ui/icons/Event";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";

jMoment.loadPersian({ dialect: "persian-modern" });

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#8b94a5",
        },
    },
}));

const CustomDatePicker = (props) => {
    const classes = useStyles();
    const { value, onChange, label, type, views, ...otherProps } = props;

    const dateFormat = (date) => {
        if (date) {
            if (views && views[0] === "year" && views.length === 1)
                return date.format("jYYYY");
            return date.format("jYYYY/jMM/jDD");
        }
        return "";
    };

    return (
        <MuiPickersUtilsProvider utils={JalaliUtils}>
            {type === "datePicker" && (
                <DatePicker
                    autoOk
                    {...otherProps}
                    label={label}
                    value={value}
                    // onChange={onChange}
                    onChange={(date) => onChange(date.format("YYYY-MM-DD"))}
                    className={classes.root}
                    views={views !== undefined ? views : undefined}
                    variant="inline"
                    inputVariant="outlined"
                    maxDateMessage=""
                    minDateMessage=""
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {value && (
                                    <IconButton
                                        size="small"
                                        onClick={() => onChange(null)}
                                    >
                                        <ClearIcon style={{ fontSize: 20 }} />
                                    </IconButton>
                                )}
                                <IconButton size="small">
                                    <EventIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        error: otherProps.error,
                    }}
                    helperText={otherProps.helperText}
                    labelFunc={(date) => dateFormat(date)}
                />
            )}
            {type === "timePicker" && (
                <TimePicker
                    variant="inline"
                    inputVariant="outlined"
                    label={label}
                    value={value}
                    onChange={onChange}
                    minutesStep={5}
                />
            )}
        </MuiPickersUtilsProvider>
    );
};

export default CustomDatePicker;
