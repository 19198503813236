import React from "react";
import {
    Card,
    CardActionArea,
    Grid,
    Typography,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    makeStyles,
    CircularProgress
} from "@material-ui/core";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showDialog, hideDialog } from "../../../Redux/actions/dialogActions";
import { getTemplatesApi } from "../../../API/Forms";
import useApi from "../../../Hooks/UseApi";
import UploadCard from "../../../Components/Surfaces/Cards/SingleUploadCard";

const styles = makeStyles({
    label: {
        border: "dashed 2px #ccc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: 10,
        padding: 10,
        cursor: "pointer",
    },
    image: {
        width: "100%",
        height: "auto",
    },
});

const ModalForms = ({ requestId, addResponse }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const [data, loading, callApi] = useApi();
    const [Responce, setResponce] = React.useState(null);
    const ChangeImage = (e) => {
        setResponce(e.target.files[0]);
    };
    const submitForm = () => {
        const formData = new FormData();
        formData.append("response", Responce, "image.png");
        formData.append("request_id", requestId); //other param
        // formData.append("template_id", templateId); //other param
        axios
            .post("/requests/response/", formData)
            .then((response) => {
                console.log(response);
                dispatch(hideDialog());
                addResponse(response.data.data);
                console.log(response);
            })
            .catch((error) => {
                console.log(error.response);
            });
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <label htmlFor="uploadFile" className={classes.label}>
                    {Responce ? (
                        <img
                            className={classes.image}
                            src={URL.createObjectURL(Responce)}
                        />
                    ) : (
                        <>
                            <Typography>تصویر پاسخ</Typography>
                            <BackupOutlinedIcon />
                        </>
                    )}
                </label>
                <input
                    id="uploadFile"
                    type="file"
                    onChange={ChangeImage}
                    style={{ display: "none" }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                >
                    {loading ? <CircularProgress /> : "ثبت"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default ModalForms;
