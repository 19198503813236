import React from "react";
import { Button, Grid } from "@material-ui/core";
import SunEditor from "suneditor-react";

const ContactUs = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} style={{direction: "ltr"}}>
                <SunEditor
                    setOptions={{
                        height: 500,
                        buttonList: [
                            ["undo", "redo"],
                            ["fontSize", "formatBlock"],
                            ["bold", "underline", "italic"],
                            ["fontColor", "hiliteColor"],
                            ["align", "horizontalRule", "list", "table"],
                            ["link", "image"],
                            ["fullScreen", "showBlocks", "codeView"],
                        ],
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary">ذخیره</Button>
            </Grid>
        </Grid>
    );
};

export default ContactUs;

