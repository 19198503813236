const Inputs = (defaults) => {
    return {
        image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "تصویر مقاله",
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.image,
        },
        cetegory: {
            elementType: "checkbox-group",
            elementConfig: {
                label: "دسته بندی",
                options: defaults.Categories,
                value: defaults.cetegory ?? [],
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.cetegory,
        }
    };
};

export default Inputs;
