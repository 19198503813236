const WomanDetailsInputs = (defaults) => {
    return {
        menstruation: {
            elementType: "switch",
            elementConfig: {
                label: "آیا قاعده میشوید؟",
                variant: "outlined",
                value: defaults.menstruation,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        regular_menstruation: {
            elementType: "switch",
            elementConfig: {
                label: "آیا قاعدگی شما منظم است؟",
                variant: "outlined",
                value: defaults.regular_menstruation,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
    };
};

export default WomanDetailsInputs;
