import React from "react";
import {
    Grid,
    Typography,
    makeStyles,
    Button,
    Fade,
    TextField,
} from "@material-ui/core";
import ShowableSwitch from "../../UI/Inputs/ShowableSwitch";
import { Label } from "@material-ui/icons";

const Meal = ({
    label,
    showTime,
    field,
    switchValue,
    inputValue,
    changeInput,
}) => {
    const [Switch, setSwitch] = React.useState(false);
    React.useEffect(() => {
        setSwitch(inputValue ? true : false );
    }, [inputValue]);

    const changeSwitch = (checked) => {
        setSwitch(checked);
    };
    return (
        <Grid container>
            <Grid
                item
                xs={6}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ShowableSwitch
                    label={label}
                    value={Switch}
                    onChange={(checked) => changeSwitch(checked)}
                />
            </Grid>
            {showTime && (
                <Grid item xs={6}>
                    <Fade in={Switch}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <TextField
                                label="ساعت مصرف (۲۴ساعت)"
                                value={inputValue ?? ""}
                                onChange={(e) => changeInput(e.target.value)}
                                variant="outlined"
                            />
                        </div>
                    </Fade>
                </Grid>
            )}
        </Grid>
    );
};

export default Meal;
