import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import useApi from "../../../../Hooks/UseApi";
import { getVideosApi } from "../../../../API/video";
import { showDialog } from "../../../../Redux/actions/dialogActions";
import DataTable from "../../../../Components/Tables/DataTables";
import ActionButtons from "../../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../../Components/UI/Buttons/AddButton";
import AddVideo from "./AddVideo";

const Videos = () => {
    const dispatch = useDispatch();
    const [Videos, setVideos] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);
    const HeaderData = [
        { field: "title", title: "عنوان" },
        { field: "link", title: "لینک" },
        // { field: "category", title: "دسته بندی" },
        { field: "action", title: "حذف" },
    ];
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getVideosApi, { page: page });
        setLastPage(data.meta.last_page)
        setVideos(data.data);
    }, [page]);

    const editModal = () => {};
    const showAddModal = () => {
        dispatch(showDialog(<AddVideo />, false, 'md'));
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <AddButton title="اضافه کردن ویدیو" onClick={showAddModal} />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title="لیست ویدیو ها"
                    headData={HeaderData}
                    data={Videos}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                edit: () => editModal(item),
                            }}
                            remove
                        />
                    )}
                    lastPage={lastPage}
                    changePage={(page) => setPage(page)}
                />
            </Grid>
        </Grid>
    );
};

export default Videos;
