import axios from "axios";

export const registerApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/auth/register/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const loginApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/auth/login/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });
