import React from "react";
import { Grid } from "@material-ui/core";
import PayedRequest from "./PayedRequest";

const Requests = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <PayedRequest />
            </Grid>
        </Grid>
    );
};

export default Requests;
