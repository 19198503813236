import React from "react";
import { makeStyles, Card, CardActionArea, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles((theme) => ({
    card: {
        // border: "2px dashed #b8bdcc",
        color: "#b8bdcc",
        boxShadow: "none",
        height: 120,
    },
    actionArea: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        height: "100%",
        width: "100%",
    },
    input: {
        display: "none",
    },
}));

const UploadCard = (props) => {
    const classes = useStyles();
    const { label, onChange, style, id } = props;
    // console.log({key})
    return (
        <Card className={classes.card} style={{...style}}>
            <input
                // accept="*/*"
                accept="image/* , .zip , .pdf , .ppt , .pptx , .doc , .docx, .xml , application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document ,
                .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className={classes.input}
                id={id}
                multiple
                type="file"
                onChange={onChange}
            />
            <label htmlFor={id} style={{ width: "100%", height: "100%" }}>
                <CardActionArea className={classes.actionArea} component="span">
                    <AddIcon color="inherit" style={{ fontSize: 40 }} />
                    <Typography variant="body2" color="inherit" >
                        {label}
                    </Typography>
                </CardActionArea>
            </label>
        </Card>
    );
};

export default UploadCard;
