import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import img1 from "../../../assets/images/measures/01.jpg";
import img2 from "../../../assets/images/measures/02.jpg";
import img3 from "../../../assets/images/measures/03.jpg";
import img4 from "../../../assets/images/measures/04.jpg";
import img5 from "../../../assets/images/measures/05.jpg";
import img6 from "../../../assets/images/measures/06.jpg";

const styles = makeStyles({
    thubnailContainer: {
        position: "absolute",
        bottom: 0,
        backgroundColor: "#0000008f",
    },
    thubnail: {
        // width: "50%",
        cursor: "pointer",
    },
    imageContainer: {
        display: "flex",
        justifyContent: "center",
    },
});
const DialogContent = () => {
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const [Image, setImage] = React.useState(img1);
    return (
        <Grid container>
            <Grid item xs={12} className={classes.imageContainer}>
                <img style={{width: isMobile ? "100%" : "40%"}} src={Image} />
            </Grid>
            <Grid item xs={12} style={{position: "relative"}}>
                <Grid container spacing={3} className={classes.thubnailContainer}>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img1} onClick={() => setImage(img1)} />
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img2} onClick={() => setImage(img2)} />
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img3} onClick={() => setImage(img3)} />
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img4} onClick={() => setImage(img4)} />
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img5} onClick={() => setImage(img5)} />
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.thubnail} style={{width: isMobile ? "100%" : "50%"}} src={img6} onClick={() => setImage(img6)} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DialogContent;
