import React from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";

import Button from "../../../Components/UI/Buttons/Button"
import { addTestsApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import getFormData from "../../../shared/getFormData";
import Inputs from "./Inputs";
import styles from "../style";

const Documents = () => {
    const classes = styles();
    const {requestId} = useParams();
    const [formData, setFormData] = React.useState({request_id: requestId});
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    const onRemove = (index, id) => {
        let doc = [...formData[id]];
        doc.splice(index, 1);
        setFormData({ ...formData, [id]: doc });
    };
    let formElements = formCreator(
        Inputs({ ...formData, onRemove }),
        inputChangedHandler
    );

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(addTestsApi, getFormData(formData), `/profile/request/${requestId}/sickness`);
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            بارگذاری مدارک
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            className={classes.uploadContainer}
                        >
                            <Typography
                                className={classes.uploadContainerTitle}
                            >
                                بارگذاری آزمایش
                            </Typography>
                            {formElements[0]}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Grid
                            container
                            spacing={3}
                            className={classes.uploadContainer}
                        >
                            <Typography
                                className={classes.uploadContainerTitle}
                            >
                                بارگذاری سونوگرافی
                            </Typography>
                            {formElements[1]}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20, padding: 0 }}>
                        <TextField
                            id="medicines"
                            label="نام دارو های مصرفی/دوز مصرف/ زمان مصرف"
                            variant="outlined"
                            fullWidth
                            onChange={(e) =>
                                inputChangedHandler(e.target.value, e.target.id)
                            }
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                        <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Documents;
