import React from "react";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import clsx from "clsx";

import Aparat from "../../Components/UI/Icons/Aparat";

const styles = makeStyles({
    social: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    socialIcon: {
        margin: 10,
        color: "#8b8b8b",
    },
    button: {
        backgroundColor: "#00000030"
    }
});

const footerSocial = ({ className }) => {
    const classes = styles();
    return (
        <Grid
            item
            xs={12}
            className={
                className ? clsx(classes.social, className) : classes.social
            }
        >
            <div className={classes.socialIcon}>
                <IconButton
                    component="a"
                    href="https://www.instagram.com/radiet.ir/"
                    target="blank"
                    className={classes.button}
                >
                    <FeatherIcon style={{ color: "#fff" }} icon="instagram" />
                </IconButton>
            </div>
            <div className={classes.socialIcon}>
                <IconButton
                    component="a"
                    href="https://mobile.twitter.com/radiet_ir"
                    target="blank"
                    className={classes.button}
                >
                    <FeatherIcon style={{ color: "#fff" }} icon="twitter" />
                </IconButton>
            </div>
            <div className={classes.socialIcon}>
                <IconButton
                    component="a"
                    href="https://www.aparat.com/Amirhossein.KhalkhaliRad"
                    target="blank"
                    className={classes.button}
                >
                    <Aparat style={{ color: "#fff", width: 27, height: 27 }} />
                </IconButton>
            </div>
        </Grid>
    );
};

export default footerSocial;
