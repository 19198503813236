import React from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    makeStyles,
} from "@material-ui/core";
import clsx from "clsx"

const styles = makeStyles({
    container: {
        padding: 10,
    },
    root: {
        height: 50,
        borderRadius: 15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "solid 1px #ccc",
        transition: "all .3s",
    },
    ActionArea: {
        textAlign: "center",
        height: "100%",
    },
    active: {
        backgroundColor: "#BBEE92",
        boxShadow: "0 5px 7px 2px #00000040",
        transition: "all .3s",
    },
});

const SingleItem = ({data, addType}) => {
    const classes = styles();
    const [Active, setActive] = React.useState(false)
    const selectType = (id) => {
        addType(id, Active)
        setActive(!Active);
    }
    return (
        <Grid item xs={4}>
            <Card className={Active ? clsx(classes.root, classes.active) : classes.root}>
                <CardActionArea
                    onClick={() => selectType(data.id)}
                    className={classes.ActionArea}
                >
                    <Typography>{data.name}</Typography>
                </CardActionArea>
            </Card>
        </Grid>
    );
}

export default SingleItem;
