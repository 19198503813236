import React from "react";
import { Grid, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { hideDialog } from "../../../../Redux/actions/dialogActions";
import { addTemplateApi } from "../../../../API/Forms";
import useApi from "../../../../Hooks/UseApi";
import formCreator from "../../../../shared/formCreator";
import getFormData from "../../../../shared/getFormData";
import CatInputs from "./CatInputs";
import styles from "../style";

const AddCategory = ({ groupId }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({ group_id: groupId });
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(CatInputs(formData), inputChangedHandler);

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(addTemplateApi, getFormData(formData));
        dispatch(hideDialog());
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={3}
                            className={classes.uploadContainer}
                        >
                            {formElements}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.FooterBtnContainer}>
                        <Button
                            disableElevation
                            onClick={submitForm}
                            variant="contained"
                            color="primary"
                        >
                            ذخیره
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddCategory;

