import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Input, InputLabel, MenuItem, Select, Chip, FormControl } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        height: "100%",
    },
    option: {
        fontSize: 14,
        width: "100%",
        minHeight: 40,
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        zIndex: 10,
    },
    chip: {
        margin: 2,
        maxWidth: "100%",
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 400,
            width: 200,
        },
    },
};

const MultipleSelect = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { value, label, options, onChange, noOptionLabel } = props;

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple
                value={value}
                onChange={onChange}
                input={<Input />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                label={options.find((option) => option.value === value).name}
                                className={classes.chip}
                                onDelete={() => {}}
                                onClick={() => {}}
                            />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {options.length > 0 ? (
                    options.map((option, index) => (
                        <MenuItem key={index} value={option.value} className={classes.option}>
                            {option.name}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>{noOptionLabel ? noOptionLabel : "بی نتیجه"} </MenuItem>
                )}
            </Select>
        </FormControl>
    );
};

export default MultipleSelect;
