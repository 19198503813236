const translator = (field) => {
    const translate = {
        name: "نام",
        surname: "نام خانوادگی",
        mobile: "موبایل",
        birthday: "تاریخ تولد",
        job: "شغل",
        sex: "جنسیت",
        address: "آدرس",
        partner_height: "قد همسر",
        partner_weight: "وزن همسر",
        children: "تعداد فرزندان",
        child_above_4kg: "فرزند بالای ۴ کیلو",
        partner_complains: "همسرتان از وضعیت جسمانی شما راضی است؟",
        regular_menstruation: "قاعدگی منظم",
        smoking: "سیگار کشیدن",
        drinking: "مشروب",
        breakfast: "ساعت صبحانه",
        lunch: "ساعت ناهار",
        dinner: "ساعت شام",
        had_diet: "سابقه رژیم قبلی",
        desire_weight: "چه وزنی را برای خود مناسب میدانید؟",
        experienced_desire_weight: "آیا آن را تجربه کرده اید؟",
        self_score: "امتیاز به بدن خود در آینه",
        goal: "هدف",
        disablity: "معلولیت",
        height: "قد",
        weight: "وزن",
        waist: "دور شکم",
        hip: "دور باسن",
        wrist: "دور مچ",
        alergies: "آلرژی",
        medicines: "دارو های مصرفی",
        diseases: "بیماری ها",
        exercises: "تمرین های ورزشی",
        ulna: "ulna",
        
        PENDING: "در انتظار پرداخت",
        PAID: "پرداخت شده",
        IN_PROGRESS: "در حال انجام",
        EDITED: "ویرایش شده",
        DONE: "دریافت شده",
    }
    return translate[field];
}

export default translator;