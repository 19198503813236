import axios from "axios";

export const getAllRequestTypesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/requests/types/all")
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addTypesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/types/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addRequestTypesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getMyRequestsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/requests", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addMeasuresApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/measures/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addAlergiesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/alergies/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addTestsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/documents/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const removeTestsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .delete("/profile/requests/documents", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addSicknessApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/diseases/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addExerciseingApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/exercises/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addBodyImagesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/body-images/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getAllRequestsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/requests", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addSummaryApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/requests/summarize/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getAllSummaryApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/requests/summary", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getAmountApi = () =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/requests/amounts")
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const checkoutApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/checkout/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const sendNotificationApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/requests/send-notification/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const singleRequestData = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/requests", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const updateRequestData = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/profile/requests/update/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

    export const removeRequest = (data) =>
    new Promise((resolve, reject) => {
        axios
            .delete(`/requests/${data.id}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

    
