import React from "react";
import { Grid, Button, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import HomeIcon from '@material-ui/icons/Home';

import Avatar from "./Avatar";

const AdminHeader = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const history = useHistory();
    return (
        <Grid container>
            <Grid item xs={isMobile ? 6 : 10}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/")}
                >
                    {isMobile ? <HomeIcon /> : "بازگشت به صفحه اصلی"}
                    
                </Button>
            </Grid>
            <Grid item xs={isMobile ? 6 : 2}>
                <Avatar itemsColor="#000" />
            </Grid>
        </Grid>
    );
};

export default AdminHeader;
