const Inputs = (defaults) => {
    return {
        title: {
            elementType: "text-field",
            elementConfig: {
                label: "عنوان",
                variant: "outlined",
                value: defaults.title,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        category: {
            elementType: "search-list-input",
            elementConfig: {
                label: "دسته بندی",
                options: [
                    { name: "دسته ۱", value: 1 },
                    { name: "دسته ۲", value: 2 },
                ],
                variant: "outlined",
                value: defaults.category,
                multiple: true
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        link: {
            elementType: "text-field",
            elementConfig: {
                label: "لینک",
                variant: "outlined",
                value: defaults.link,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        description: {
            elementType: "text-field",
            elementConfig: {
                label: "توضیحات",
                variant: "outlined",
                value: defaults.description,
                multiline: true,
                rows: 4
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        
    };
};

export default Inputs;
