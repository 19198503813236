import { makeStyles } from "@material-ui/core";

export default makeStyles({
    pageContainer: {},
    addTemplateBtn: {
        height: 100,
    },
    FooterBtnContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    cardActionArea: {
        padding: 10
    },
    media: {
        height: 550,
    },
});
