import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";
import { useLocation, useHistory, Redirect } from "react-router-dom";

import {register, login} from "../../../Redux/actions/userActions";
import {loginApi} from "../../../API/auth";

const styles = makeStyles({
  title: {
    textAlign: "center",
    margin: 10,
  },
  subtitle: {
    textAlign: "center",
    margin: 10,
    fontSize: 18,
  },
});

const Verification = () => {
  const classes = styles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [formData, setFormData] = React.useState({});
  const [countDown, setCountDown] = React.useState(state?.counter ?? 120);
  React.useEffect(() => {
    setTimeout(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
    }, 1000);
  });
  const submitForm = () => {
    let data = {...formData, mobile: state.mobile};
      dispatch(state.type === "login" ? login(data, history) : register(data, history));
  };
  
  const resend = () => {
    loginApi({otp: true, mobile: state.mobile})
      .then((response) => {
        console.log("111")
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (!state){
    return <Redirect to='/auth/login'/>;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          {`کد ارسال شده به شماره موبایل ${state?.mobile} را وارد نمایید`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} style={{ padding: isMobile ? "100px 10%" : "100px 25%" }}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              تایید کد
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => setFormData({ code: e.target.value })}
              fullWidth
              label="کد تایید"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={submitForm}
              fullWidth
              variant="contained"
              color="primary"
            >
              ارسال کد
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={countDown !== 0}
              onClick={resend}
              fullWidth
              variant="contained"
              color="primary"
            >
              {`${countDown} تا ارسال مجدد`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Verification;
