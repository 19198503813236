import React from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DataTable from "../../../Components/Tables/DataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import { getUsersApi } from "../../../API/users";
import useApi from "../../../Hooks/UseApi";
import { showDialog } from "../../../Redux/actions/dialogActions";
import RemoveUser from "./RemoveUser";
import ChangePassword from "./ChangePassword";

const Users = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector((state) => state.user);
    const queryString = window.location;
    const url = new URL(queryString);
    const page = url.searchParams.get("page");
    const HeaderData = [
        { field: "id", title: "شماره کاربری" },
        { field: "name", title: "نام" },
        { field: "surname", title: "نام خانوادگی" },
        { field: "mobile", title: "شماره موبایل" },
        { field: "created_at", title: "تاریخ ثبت نام", type: "date" },
        { field: "action", title: "عملیات" }
    ];
    const [search, setSearch] = React.useState("");
    const [users, setUsers] = React.useState({});
    const [statistics, setStatistics] = React.useState({});

    const [data, loading, callApi] = useApi();
    React.useEffect(() => {
        submit();
    }, [page]);

    const submit = async () => {
        let res = await callApi(getUsersApi, { page, search });
        setUsers(res);
    };

    const submitSearch = (e) => {
        e.preventDefault();
        submit();
    };
    const deleteModal = (item) => {
        dispatch(showDialog(<RemoveUser id={item.id} remove={(id) => blockUser(id)} />, false, "sm"));
    };
    const blockUser = (id) => {
        let newData = users.data.filter((user) => user.id != id);
        setUsers({ ...users, data: newData });
        setStatistics({ ...statistics, users: statistics.users - 1 });
    };
    const changePassModal = (item) => {
        dispatch(showDialog(<ChangePassword id={item.id} userName={`${item.name} ${item.surname}`} />, false, "sm"));
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={submitSearch}>
                    <TextField placeholder="جستجو" variant="outlined" onChange={(e) => setSearch(e.target.value)} />
                    <Button variant="contained" type="submit" color="primary" style={{ marginRight: 10 }}>
                        ثبت
                    </Button>
                </form>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title={`لیست کاربران (تعداد کل: ${users.total ?? 0})`}
                    headData={HeaderData}
                    data={users?.data ?? []}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                block: () => deleteModal(item),
                                add: () => history.push(`/dashboard/requests/add/${item.id}/new`),
                                show: () => history.push(`/dashboard/users/unBlocked/${item.id}`),
                                resetPass: () => changePassModal(item)
                            }}
                            block={user.role === "superadmin" || user.role === "admin" || user.role === "writer"}
                            resetPass={user.role === "superadmin"}
                            add={user.role === "writer" || user.role === "superadmin" || user.role === "admin"}
                            show={user.role === "writer" || user.role === "superadmin" || user.role === "admin"}
                        />
                    )}
                    lastPage={users.last_page ?? 1}
                    currentPage={page}
                    route="/dashboard/users/unBlocked"
                />
            </Grid>
        </Grid>
    );
};

export default Users;
