import React from "react";
import { Card, CardContent } from "@material-ui/core";
import "./style.css";
const HomeVideo = ({ link }) => {
  console.log({link})
    return (
        <Card style={{ backgroundColor: "#196B3C", borderRadius: 32 }}>
            <CardContent>
                {link && (
                    <div className="h_iframe-aparat_embed_frame">
                        <span
                            style={{ display: "block", paddingTop: "57%" }}
                        ></span>
                        <iframe
                            src={link}
                            allowFullScreen="true"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                        ></iframe>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default HomeVideo;
