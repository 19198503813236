import { combineReducers } from "redux";
import snackbarReducers from "./snackbarReducers";
import dialogReducer from "./dialogReducer";
import loadingReducers from "./loadingReducers";
import userReducers from "./userReducers";
import requestReducers from "./requestReducers";

export default combineReducers({
  snackbar: snackbarReducers,
  dialog: dialogReducer,
  loading: loadingReducers,
  user: userReducers,
  request: requestReducers
});
