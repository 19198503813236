import React from "react";
import { Card, CardContent, Grid, Typography, TextField } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import Button from "../../../../Components/UI/Buttons/Button";
import { showAlert } from "../../../../Redux/actions/snackbarActions";
import { updateProfileApi } from "../../../../API/profile";
import { getUserProfileApi } from "../../../../API/users";
import getFormData from "../../../../shared/getFormData";
import { singleRequestData, updateRequestData, removeTestsApi } from "../../../../API/request";
import useApi from "../../../../Hooks/UseApi";
import formCreator from "../../../../shared/formCreator";
import Meal from "../../../../Components/Surfaces/Meal/Meal";
import { toEnNumber } from "../../../../shared/toEnNumber";
import styles from "../../../Profile/style";

import personalInfoInputs from "./personalInfoInputs";
import marridInfoInputs from "./marridInfoInputs";
import WomanDetailsInputs from "./WomanDetailsInputs";
import RoutineInputs from "./RoutineInputs";
import DietInfoInputs from "./DietInfoInputs";
import GoalInputs from "./GoalInputs";

const AddRequestByWriter = () => {
    const classes = styles();
    const { id } = useParams();
    console.log({ id });
    const [formData, setFormData] = React.useState({ user_id: id });
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let res = await callApi(getUserProfileApi, { id });
        setFormData({
            ...formData,
            name: res.name,
            surname: res.surname,
            mobile: res.mobile,
            goal: res.goal,
            ...res.personal_info,
            ...res.woman_info,
            ...res.diet_info,
            ...res.marrage_info,
            ...res.routine_info,
        });
    }, []);

    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };

    let personalInfoFormElements = formCreator(personalInfoInputs(formData), inputChangedHandler);
    let marridInfoElements = formCreator(marridInfoInputs(formData), inputChangedHandler);
    let WomanDetailsElements = formCreator(WomanDetailsInputs(formData), inputChangedHandler);
    let RoutineElements = formCreator(RoutineInputs(formData), inputChangedHandler);
    let DietInfoElements = formCreator(DietInfoInputs(formData), inputChangedHandler);
    let GoalElements = formCreator(GoalInputs(formData), inputChangedHandler);

    const submitForm = (redirect) => {
        Object.keys(formData).map((obj) => {
            if (formData[obj] === null) delete formData[obj];
        });
        callApi(updateProfileApi, formData, redirect);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    اطلاعات شخصی
                                </Typography>
                            </Grid>
                            {personalInfoFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    اطلاعات تاهل
                                </Typography>
                            </Grid>
                            {formData.marrid ? marridInfoElements : marridInfoElements.splice(0, 1)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    اطلاعات قاعدگی
                                </Typography>
                            </Grid>
                            {WomanDetailsElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    عادت های روزانه
                                </Typography>
                            </Grid>
                            {RoutineElements}
                        </Grid>
                        <Grid container spacing={3} className={classes.meal}>
                            <Typography variant="h4" component="h4" className={classes.mealTitle}>
                                وعده های غذایی
                            </Typography>
                            <Grid item xs={6}>
                                <Meal
                                    label="صبحانه"
                                    showTime
                                    inputValue={formData.breakfast}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "breakfast")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Meal
                                    label="نهار"
                                    showTime
                                    inputValue={formData.lunch}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "lunch")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Meal
                                    label="شام"
                                    showTime
                                    inputValue={formData.dinner}
                                    changeInput={(value) => inputChangedHandler(toEnNumber(value), "dinner")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Meal
                                    label="میان وعده"
                                    inputValue={formData.mid_meal}
                                    changeInput={(value) => inputChangedHandler(value ? true : false, "mid_meal")}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="توضیحات(مصرف هر مواد غذایی غیر از آب میان وعده محسوب میشود)"
                                    multiline
                                    rows={4}
                                    value={formData.description ?? ""}
                                    onChange={(e) => inputChangedHandler(e.target.value, "description")}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    اطلاعات رژیم
                                </Typography>
                            </Grid>
                            {DietInfoElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    هدف
                                </Typography>
                            </Grid>
                            {GoalElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => submitForm(`/dashboard/requests/add/${id}/new`)}
                    title="ثبت تغییرات و ایجاد درخواست"
                    loading={loading}
                />
            </Grid>
            <Grid item xs={6}>
                <Button
                    fullWidth
                    style={{ backgroundColor: "#7FB5FF", color: "#000" }}
                    variant="contained"
                    onClick={() => submitForm("/dashboard/users/unBlocked")}
                    title="ثبت تغییرات و بازگشت"
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
};

export default AddRequestByWriter;
