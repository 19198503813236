import React from "react";
import { Switch, Route } from "react-router-dom";
import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import SideBar from "../Components/SideBar/SideBar";
import BottomTab from "../Components/SideBar/BottomTab";
import AdminHeader from "../Components/Header/AdminHeader";
import { userRoutes, writerRoute, adminRoute, assistantRoute, CMSRoute } from "./dashboardRoutes";

const style = makeStyles({});

const DashboardRoute = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const user = useSelector((state) => state.user);
    const setRoute = () => {
        if (user.role === "admin" || user.role === "superadmin") {
            return [...adminRoute, ...CMSRoute];
        }
        if (user.role === "writer") {
            return [...writerRoute, ...CMSRoute];
        }
        if (user.role === "user") {
            return userRoutes;
        }
        if (user.role === "assistant") {
            return assistantRoute;
        }
    };
    return (
        <Grid container>
            {isMobile ? (
                <BottomTab routes={setRoute()}/>
            ) : (
                <Grid item style={{ width: "20%" }}>
                    <SideBar routes={setRoute()} />
                </Grid>
            )}
            <Grid item style={{ backgroundColor: "#f7f7f7", width: isMobile ? "100%" : "80%", marginBottom: isMobile ? 50 : 0}}>
                <Grid container style={{ backgroundColor: "#fff", padding: 10 }}>
                    <AdminHeader />
                </Grid>
                <Grid container style={{ padding: 20 }}>
                    <Grid item xs={12}>
                        <Switch>
                            {setRoute().map((route, key) => (
                                <Route exact={key === 0} path={route.path} component={route.component} />
                            ))}
                        </Switch>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardRoute;
