const personalInfoInputs = (defaults) => {
    return {
        name: {
            elementType: "text-field",
            elementConfig: {
                label: "نام",
                variant: "outlined",
                value: defaults.name,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        email: {
            elementType: "text-field",
            elementConfig: {
                label: "ایمیل",
                variant: "outlined",
                value: defaults.email,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        tel: {
            elementType: "text-field",
            elementConfig: {
                label: "شماره تماس",
                variant: "outlined",
                value: defaults.tel,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        subject: {
            elementType: "text-field",
            elementConfig: {
                label: "موضوع",
                variant: "outlined",
                value: defaults.subject,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        message: {
            elementType: "text-field",
            elementConfig: {
                label: "پیام",
                variant: "outlined",
                value: defaults.message,
                multiline: true,
                rows: 4,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default personalInfoInputs;
