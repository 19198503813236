import React from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";

import SearchListInput from "../Inputs/SearchListInput";

const ProvinceFields = (props) => {
    const {
        provinceGrid = { xs: 12, sm: 6, md: 4, lg: 4 },
        townGrid = { xs: 12, sm: 6, md: 4, lg: 4 },
        cityGrid = { xs: 12, sm: 6, md: 4, lg: 4 },
        provinceFieldName = "province",
        townFieldName = "town",
        cityFieldName = "city",
        values = { province: null, town: null, city: null },
        onChange,
        mapper = (value) => value,
        ...otherProps
    } = props;

    const provinces = useSelector((store) => store.geo);
    const province = provinces.find((item) => mapper(item) === values[provinceFieldName]);
    const town = province?.towns.find((item) => mapper(item) === values[townFieldName]);
    const city = town?.cities.find((item) => mapper(item) === values[cityFieldName]);

    return (
        <>
            <Grid item {...provinceGrid}>
                <SearchListInput
                    label="استان"
                    options={provinces}
                    value={province ?? values[provinceFieldName]}
                    onChange={(value) =>
                        onChange({
                            [provinceFieldName]: mapper(value) ?? value,
                            [townFieldName]: null,
                            [cityFieldName]: null,
                        })
                    }
                    {...otherProps}
                />
            </Grid>
            <Grid item {...townGrid}>
                <SearchListInput
                    label="شهرستان"
                    options={province?.towns ?? []}
                    value={town ?? values[townFieldName]}
                    onChange={(value) =>
                        onChange({ ...values, [townFieldName]: mapper(value) ?? value, [cityFieldName]: null })
                    }
                    {...otherProps}
                />
            </Grid>
            <Grid item {...cityGrid}>
                <SearchListInput
                    label="شهر"
                    options={town?.cities ?? []}
                    value={city ?? values[cityFieldName]}
                    onChange={(value) => onChange({ ...values, [cityFieldName]: mapper(value) ?? value })}
                    {...otherProps}
                />
            </Grid>
        </>
    );
};

export default ProvinceFields;
