import React, { useEffect, useState } from "react";
import { Grid, Typography, makeStyles, IconButton } from "@material-ui/core";
import { Close, Save, Mic } from "@material-ui/icons";
import MicRecorder from "mic-recorder-to-mp3";

import { formatMinutes, formatSeconds } from "../../shared/format-time";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const styles = makeStyles({
  container: {
    backgroundColor: "#e8e8e8",
    padding: 10,
    borderRadius: 40,
    // marginBottom: 20
  },
  timeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    boxShadow: "0px 0px 9px 2px #bfbfbf",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    backgroundColor: "#fee5ea",
    color: "#f62d56",
    marginRight: 5,
  },
  saveBtn: {
    backgroundColor: "#7FB5FF",
    color: "#fff",
  },
  startBtn: {
    backgroundColor: "#BBEE92",
    color: "#559F19",
  },
});

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
};

const Recorder = ({ setNewVoice }) => {
  const classes = styles();
  const [recorderData, setRecorderData] = useState({
    isRecording: false,
    blobURL: "",
    isBlocked: false,
  });
  const [recorderState, setRecorderState] = useState(initialState);
  useEffect(() => {
    const MAX_RECORDER_TIME = 5;
    let recordingInterval = null;

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          if (
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0
          ) {
            clearInterval(recordingInterval);
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < 59
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };

          if (prevState.recordingSeconds === 59)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            };
        });
      }, 1000);
    else clearInterval(recordingInterval);

    return () => clearInterval(recordingInterval);
  });

  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.mediaDevices?.getUserMedia() ||
      navigator.msGetUserMedia;
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setRecorderData({ ...recorderData, isBlocked: false });
      },
      () => {
        console.log("Permission Denied");
        setRecorderData({ ...recorderData, isBlocked: true });
      }
    );
  }, []);

  const startRecording = () => {
    if (recorderData.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setRecorderData({ ...recorderData, isRecording: true });
          setRecorderState({ ...recorderState, initRecording: true });
        })
        .catch((e) => console.error(e));
    }
  };
  const saveRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setRecorderData({ ...recorderData, isRecording: false });
        setRecorderState({
          ...recorderState,
          initRecording: false,
          recordingMinutes: 0,
          recordingSeconds: 0,
        });
        setNewVoice(buffer, blob);
      })
      .catch((e) => console.log(e));
  };
  const cancelRecording = () => {
    Mp3Recorder.stop();
    setRecorderData({ ...recorderData, isRecording: false });
    setRecorderState({
      ...recorderState,
      initRecording: false,
      recordingMinutes: 0,
      recordingSeconds: 0,
    });
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={3} className={classes.timeContainer}>
        <Typography
          style={{ direction: "ltr" }}
          variant="h3"
          component="h3"
        >{`${formatMinutes(recorderState.recordingMinutes)} : ${formatSeconds(
          recorderState.recordingSeconds
        )}`}</Typography>
      </Grid>
      <Grid item xs={9} className={classes.btnContainer}>
        {recorderData.isRecording && (
          <IconButton className={classes.cancelBtn} onClick={cancelRecording}>
            <Close color="secondary" />
          </IconButton>
        )}
        {recorderData.isRecording ? (
          <IconButton
            className={classes.saveBtn}
            title="Save recording"
            // disabled={recordingSeconds === 0}
            onClick={saveRecording}
          >
            <Save />
          </IconButton>
        ) : (
          <IconButton
            className={classes.startBtn}
            title="Start recording"
            onClick={startRecording}
          >
            <Mic />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Recorder;
