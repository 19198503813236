import React from "react";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";

const URLImage = (props) => {
    const [image] = useImage(props.src);
    const imageRef = React.useRef(null);
    return (
        <Image
            image={image}
            x={props.x}
            y={props.y}
            width={window.innerWidth - 400}
            height={1700}
            ref={imageRef}
        />
    );
};

export default URLImage;
