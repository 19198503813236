import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const styles = makeStyles({
    root: {
        border: "solid 1px",
        borderRadius: 30,
        padding: "15px 20px",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
    icon: {
        position: "absolute",
        left: 2,
        fontSize: 50,
        top: 1,
    },
});
const Item = ({ title, value }) => {
    const classes = styles();
    return (
        <Grid container>
            <Grid item xs={12} className={classes.root}>
                {value ? <CheckCircleOutlineIcon className={classes.icon} color="primary" /> : ""}
                <Typography variant="h4" component="h4">
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Item;
