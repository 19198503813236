import React from "react";
import { Grid, TextField, InputAdornment, makeStyles } from "@material-ui/core";

import SearchListInput from "../Components/UI/Inputs/SearchListInput";
import SelectInput from "../Components/UI/Inputs/SelectInput";
import MultipleSelect from "../Components/UI/Inputs/MultipleSelect";
import DatePicker from "../Components/UI/Inputs/DatePicker";
import FileUploader from "../Components/UI/Inputs/FileUploader";
import SingleUploadCard from "../Components/Surfaces/Cards/SingleUploadCard";
import RadioButton from "../Components/UI/Inputs/RadioButton";
import ShowableSwitch from "../Components/UI/Inputs/ShowableSwitch";
import CheckBoxGroup from "../Components/UI/Inputs/CheckboxGroup";

import ProvinceFields from "../Components/UI/Fields/ProvinceFields";
import StoreField from "../Components/UI/Fields/StoreField.jsx";

import { toEnNumber } from "./toEnNumber";
import currencySeparator, { currencyFixer } from "./currencySeparator";
import ImageCropper from "../Components/UI/Inputs/ImageCropper";

const style = makeStyles({
    inputText: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#8b94a5",
        },
    },
});
const inputCreator = (type, value, onChange, props, onRemove, key) => {
    const classes = style();
    switch (type) {
        case "switch":
            return (
                <ShowableSwitch
                    value={value}
                    onChange={(value) => onChange(value)}
                    {...props}
                />
            );
        case "checkbox-group":
            return (
                <CheckBoxGroup
                    value={value}
                    onChange={(value) => onChange(value)}
                    {...props}
                />
            );
        case "store-field":
            return (
                <StoreField
                    fullWidth
                    value={value}
                    onChange={(value) => onChange(value)}
                    {...props}
                />
            );
        case "province-fields":
            return (
                <ProvinceFields
                    {...props}
                    values={value}
                    onChange={(value) => onChange(value)}
                    mapper={(value) => value?.id}
                />
            );
        case "text-field":
            return (
                <TextField
                    className={classes.inputText}
                    fullWidth
                    value={value}
                    onChange={(event) =>
                        onChange(toEnNumber(event.target.value))
                    }
                    {...props}
                />
            );
        case "price-field":
            return (
                <TextField
                    fullWidth
                    className={classes.inputText}
                    value={value ? currencySeparator(value) : 0}
                    onChange={(event) =>
                        onChange(currencyFixer(event.target.value))
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                تومان
                            </InputAdornment>
                        ),
                    }}
                    {...props}
                />
            );
        case "count-text-field":
            return (
                <TextField
                    fullWidth
                    value={value}
                    style={{ marginBottom: -20 }}
                    onChange={(event) => {
                        if (event.target.value.length <= 255)
                            onChange(toEnNumber(event.target.value));
                    }}
                    helperText={value.length + ` از ۲۵۵ کاراکتر`}
                    FormHelperTextProps={{
                        style: {
                            fontSize: 10,
                            textAlign: "left",
                            color: "#b8bdcc",
                            lineHeight: 3,
                        },
                    }}
                    {...props}
                />
            );
        case "select-input":
            return (
                <SelectInput
                    fullWidth
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    {...props}
                />
            );
        case "multiple-select":
            return (
                <MultipleSelect
                    fullWidth
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                    {...props}
                />
            );
        case "search-list-input":
            return (
                <SearchListInput
                    className={classes.inputText}
                    fullWidth
                    value={value}
                    onChange={(value) => onChange(value)}
                    {...props}
                />
            );
        case "date-picker":
            return (
                <DatePicker
                    fullWidth
                    type="datePicker"
                    value={value}
                    onChange={(date) => onChange(date)}
                    {...props}
                />
            );
        case "time-picker":
            return (
                <DatePicker
                    fullWidth
                    type="timePicker"
                    value={value}
                    onChange={(date) => onChange(date)}
                    {...props}
                />
            );
        case "radio-button":
            return (
                <RadioButton
                    fullWidth
                    value={value}
                    onChange={(date) => onChange(date)}
                    {...props}
                />
            );
        case "file-uploader":
            let newFiles = [...value];
            newFiles = value.map((file) => {
                if (file?.link !== undefined) return file;
                return {
                    id: file.id,
                    link: URL.createObjectURL(file),
                    name: file.name,
                    mime_type: file.type,
                };
            });
            return (
                <FileUploader
                    {...props}
                    label={props.label}
                    helperText="حداکثر حجم فایل 5 مگابایت"
                    maxFilesCount={10}
                    uploadedFiles={newFiles}
                    onChange={(event) =>
                        onChange([...value, ...event.target.files])
                    }
                    onRemove={(val) => {
                        onRemove(val);
                        // onChange(value.filter((file) => file.id !== val.id));
                    }}
                    id={key}
                />
            );
        case "single-file-uploader":
            return (
                <SingleUploadCard
                    {...props}
                    value={value}
                    onChange={onChange}
                />
            );
        case "image-cropper":
            return (
                <ImageCropper {...props} value={value} onChange={onChange} />
            );
        default:
            return;
    }
};

const formCreator = (controls, inputChangedHandler) => {
    let formElements = [];
    for (let key in controls) {
        formElements.push({
            id: key,
            config: controls[key],
        });
    }
    formElements = formElements.map((formElement) => {
        if (formElement.config.hide) return "";
        return (
            <Grid
                item
                xs={12}
                key={formElement.id}
                {...formElement.config.gridConfig}
            >
                {inputCreator(
                    formElement.config.elementType,
                    formElement.config.value,
                    (value) => inputChangedHandler(value, formElement.id),
                    { ...formElement.config.elementConfig, id: formElement.id },
                    (val) => formElement.config.onRemove(val),
                    formElement.id
                )}
            </Grid>
        );
    });

    return formElements;
};

export default formCreator;
