import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { setUserData } from "../Redux/actions/userActions";
import { showLoading, hideLoading } from "../Redux/actions/loadingActions";

const AuthHoc = (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [loaded, setLoaded] = React.useState(false);
    React.useEffect(() => {
        dispatch(showLoading());
        axios
            .get(`/auth/check-login`)
            .then((response) => {
                setLoaded(true);
                dispatch(hideLoading());
                dispatch(setUserData(response.data.data));
            })
            .catch((error) => {
                dispatch(hideLoading());
                setLoaded(true);
                console.log(error);
            });
    }, []);

    return loaded && props.children;
};

export default AuthHoc;
