import React from "react";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

const style = makeStyles({
  cardRoot: {
    width: 170,
    borderRadius: 25,
  },
  cardContent: {
    paddingBottom: 16,
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
  },
});
const HomeStatistics = ({ icon, count, text }) => {
  const classes = style();
  return (
    <Card className={classes.cardRoot}>
      <CardContent className={classes.cardContent}>
        <Grid container direction="column">
          <Grid item xs={12} className={classes.centerAlign}>
            {icon}
          </Grid>
          <Grid item xs={12} className={classes.centerAlign}>
            <Typography component="h2" variant="h2">
              {count}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.centerAlign}>
            <Typography component="h4" variant="h4">
              {text}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HomeStatistics;
