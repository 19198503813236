import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import SupervisedUserCircleRoundedIcon from "@material-ui/icons/SupervisedUserCircleRounded";
import InstagramIcon from "@material-ui/icons/Instagram";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MainContainer from "../Container/MainContainer";
import background from "../../assets/images/Vector3.svg";
import HomeStatistics from "../Statistics/HomeStatistics";
import useApi from "../../Hooks/UseApi";
import { siteStatistics } from "../../API/statistics";

const style = makeStyles({
    sectionRoot: {
        position: "relative",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 100,
    },
    centerAlign: {
        display: "flex",
        justifyContent: "center",
    },
    icon: {
        fontSize: 70,
        color: "#196B3C",
    },
    sectionContainer: {
        position: "relative",
        bottom: 80,
    },
});

const Section5 = () => {
    const classes = style();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const [statistics, setStatistics] = React.useState({});
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let res = await callApi(siteStatistics);
        setStatistics(res ? res.data : {});
    }, []);

    return (
        <Grid
            item
            xs={12}
            className={classes.sectionRoot}
            style={{ height: isMobile ? 500 : 225 }}
        >
            <MainContainer>
                <Grid
                    container
                    spacing={3}
                    className={classes.sectionContainer}
                >
                    <Grid item md={3} xs={6} className={classes.centerAlign}>
                        <HomeStatistics
                            icon={
                                <AccessibilityIcon className={classes.icon} />
                            }
                            count={statistics.exercise}
                            text="برنامه تمرینی"
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className={classes.centerAlign}>
                        <HomeStatistics
                            icon={<FastfoodIcon className={classes.icon} />}
                            count={statistics.diet}
                            text="رژیم غذایی"
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className={classes.centerAlign}>
                        <HomeStatistics
                            icon={
                                <SupervisedUserCircleRoundedIcon
                                    className={classes.icon}
                                />
                            }
                            count={statistics.users}
                            text="کاربر فعال"
                        />
                    </Grid>
                    <Grid item md={3} xs={6} className={classes.centerAlign}>
                        <HomeStatistics
                            icon={<InstagramIcon className={classes.icon} />}
                            count="21K"
                            text="اعضای اینستاگرام"
                        />
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default Section5;
