import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
const style = makeStyles({
  menuItem: {
    marginLeft: 50,
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
  },
});

const Menu = () => {
  const user = useSelector((state) => state.user);

  const classes = style();
  return (
    <>
      <div className={classes.menuItem}>
        <Link to="/">صفحه نخست</Link>
      </div>
      {user && (
        <>
          <div className={classes.menuItem}>
            <Link
              to={
                user.confirm_profile
                  ? "/profile/request/new/types"
                  : "/profile/personal-info"
              }
            >
              ثبت درخواست
            </Link>
          </div>
          <div className={classes.menuItem}>
            <Link to="/dashboard/diet-requests">مشاهده رژیم</Link>
          </div>
        </>
      )}
      <div className={classes.menuItem}>
        <Link to="/aboute-us">درباره ما</Link>
      </div>
      <div className={classes.menuItem}>
        <Link to="/contact-us">تماس با ما</Link>
      </div>
    </>
  );
};

export default Menu;
