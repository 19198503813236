import React from "react";
import { Grid, TextField, Button, IconButton, Typography, makeStyles } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import useApi from "../../../Hooks/UseApi";
import { addMessageApi, getMessagesApi } from "../../../API/ticket";
import Message from "./Message";

const styles = makeStyles({
    headerItem: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
    },
});

const TicketMessages = () => {
    const classes = styles();
    const user = useSelector((state) => state.user);
    const { ticketId } = useParams();
    const history = useHistory();
    const myRef = React.useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

    const [formData, setFormData] = React.useState({ ticket_id: ticketId });
    const [ticket, setTicket] = React.useState({});
    const [userData, setUserData] = React.useState({});
    const [messages, setMessages] = React.useState([]);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getMessagesApi, { ticket_id: ticketId });
        setTicket(data);
        setMessages(data.messages);
        setUserData(data.user);
        executeScroll();
    }, []);
    const handleSubmit = async () => {
        let data = await callApi(addMessageApi, formData);
        setMessages([...messages, data.data]);
        setFormData({ ...formData, message: "" });
        executeScroll();
    };
    return (
        <Grid container spacing={3} style={{ backgroundColor: "#fff", border: "solid 1px #ccc" }}>
            <Grid item xs={12} style={{ backgroundColor: "#F9D371" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} className={classes.headerItem}>
                        <Typography variant="h4" component="h4">{`موضوع : ${ticket?.title ?? ""}`}</Typography>
                    </Grid>

                    {user.role !== "user" && (
                        <>
                            <Grid item xs={12} md={3} className={classes.headerItem}>
                                <Typography variant="h4" component="h4">{`نام : ${userData?.name ?? ""} ${userData?.surname ?? ""}`}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.headerItem}>
                                <Typography variant="h4" component="h4">{`موبایل : ${userData?.mobile ?? ""}`}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3} className={classes.headerItem}>
                                <Button variant="contained" color="primary" component={Link} to={`/dashboard/reponses/${ticket.request_id}`}>
                                    مشاهده پرونده
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    padding: "20px 45px",
                    height: "calc(100vh - 250px)",
                    overflow: "scroll",
                }}
            >
                {messages?.map((msg) => (
                    <Message data={msg} />
                ))}
                <div ref={myRef}></div>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    label="پیام"
                    variant="outlined"
                    value={formData.message ?? ""}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                style={{
                                    backgroundColor: "#BBEE92",
                                    color: "#559F19",
                                    margin: "0 5px",
                                    minWidth: "unset",
                                    transform: "rotate(180deg)",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                <SendIcon />
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default TicketMessages;
