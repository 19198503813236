import React from "react";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { addUserData } from "../../../Redux/actions/userActions";
import Button from "../../../Components/UI/Buttons/Button";
import { addDietApi, getDietApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import ShowableSwitch from "../../../Components/UI/Inputs/ShowableSwitch";
import styles from "../style";

const Diet = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = React.useState(user.diet_info);
  const inputChangedHandler = (value, inputIdentifier) => {
    setFormData({ ...formData, [inputIdentifier]: value });
  };

  const [data, loading, callApi] = useApi();
  const submitForm = async () => {
    const data = await callApi(addDietApi, formData, "/profile/goal");
    if (data) {
      dispatch(addUserData({ diet_info: data.data }));
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              اطلاعات رژیم
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ShowableSwitch
              label="سابقه رژیم قبلی دارید؟"
              value={formData.had_diet}
              onChange={(checked) => inputChangedHandler(checked, "had_diet")}
            />
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography className={classes.rowTitle}>
              چه وزنی را برای خود مناسب میدانید؟
            </Typography>
            <TextField
              variant="outlined"
              type="number"
              className={classes.input}
              value={formData.desire_weight ?? ""}
              onChange={(e) =>
                inputChangedHandler(e.target.value, "desire_weight")
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">KG</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ShowableSwitch
              label="آیا تاکنون آن را تجربه کرده اید؟"
              value={formData.experienced_desire_weight}
              onChange={(checked) =>
                inputChangedHandler(checked, "experienced_desire_weight")
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography className={classes.rowTitle}>
              به بدنتان در آینه بدون چهره چه نمره ای میدهید؟(۱ تا ۱۰)
            </Typography>
            <TextField
              className={classes.input}
              variant="outlined"
              type="number"
              value={formData.self_score ?? ""}
              onChange={(e) =>
                inputChangedHandler(e.target.value, "self_score")
              }
            />
          </Grid>
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              className={classes.nextBtn}
              disableElevation
              onClick={() => submitForm()}
              variant="contained"
              color="primary"
              title="بعدی"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Diet;
