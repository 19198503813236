import React from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";

import LoginForm from "./LoginForm";
const styles = makeStyles({
  title: {
    textAlign: "center",
    margin: 10,
  },
  subtitle: {
    textAlign: "center",
    margin: 10,
    fontSize: 18,
  },
});

const Login = () => {
  const classes = styles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes.title} component="h1" variant="h1">
          به رادایت خوش آمدید
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LoginForm />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.subtitle}>
          در صورت نداشتن حساب کاربری روی دکمه ثبت نام کلیک نمایید
        </Typography>
      </Grid>
      <Grid item xs={12} style={{display: "flex", justifyContent: "center", marginBottom: 30}}>
        {/* <Link to="/auth/register">ثبت نام کنید</Link> */}
        <Button
        style={{width: 150}}
          component={Link}
          to="/auth/register"
          variant="contained"
          color="secondary"
        >
          ثبت نام
        </Button>
      </Grid>
    </Grid>
  );
};

export default Login;
