const personalInfoInputs = (defaults) => {
    return {
        had_diet: {
            elementType: "switch",
            elementConfig: {
                label: "سابقه رژیم قبلی دارید؟",
                variant: "outlined",
                value: defaults.had_diet,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        desire_weight: {
            elementType: "text-field",
            elementConfig: {
                label: "چه وزنی را برای خود مناسب میدانید؟",
                variant: "outlined",
                value: defaults.desire_weight ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        experienced_desire_weight: {
            elementType: "switch",
            elementConfig: {
                label: "آیا تاکنون آن را تجربه کرده اید؟",
                variant: "outlined",
                value: defaults.experienced_desire_weight,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        self_score: {
            elementType: "text-field",
            elementConfig: {
                label: "به بدنتان در آینه بدون چهره چه نمره ای میدهید؟(۱ تا ۱۰)",
                variant: "outlined",
                value: defaults.self_score ?? "",
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
    }}

    export default personalInfoInputs;