const personalInfoInputs = (defaults) => {
    return {
        examinations: {
            elementType: "file-uploader",
            elementConfig: {
                label: "بارگذاری آزمایش ها",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: defaults.examinations ?? [],
            onRemove: (item) => defaults.onRemove(item, "examinations"),
        },
        ultrasounds: {
            elementType: "file-uploader",
            elementConfig: {
                label: "بارگذاری سونوگرافی",
            },
            gridConfig: {
                xs: 12,
                md: 6,
            },
            value: defaults.ultrasounds ?? [],
            onRemove: (item) => defaults.onRemove(item, "ultrasounds"),
        },
        medicines: {
            elementType: "text-field",
            elementConfig: {
                label: "نام دارو های مصرفی/دوز مصرف/ زمان مصرف",
                variant: "outlined",
                value: defaults.medicines ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    };
};

export default personalInfoInputs;
