import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";

import DataTable from "../../../Components/Tables/DataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import { getBlockedUsersApi } from "../../../API/users";
import useApi from "../../../Hooks/UseApi";
import { showDialog } from "../../../Redux/actions/dialogActions";
import RemoveUser from "./RemoveUser";

const Users = () => {
    const dispatch = useDispatch();
    const queryString = window.location;
    const url = new URL(queryString);
    const page = url.searchParams.get("page");
    const HeaderData = [
        { field: "id", title: "شماره کاربری" },
        { field: "name", title: "عنوان" },
        { field: "surname", title: "عنوان" },
        { field: "mobile", title: "شماره موبایل" },
        { field: "created_at", title: "تاریخ ثبت نام", type: "date" },
        { field: "action", title: "لغو بلاک" },
    ];
    const [users, setUsers] = React.useState({});

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let res = await callApi(getBlockedUsersApi, { page });
        console.log(res);
        setUsers(res);
    }, [page]);

    const unBlock = (item) => {
        dispatch(
            showDialog(
                <RemoveUser unblock id={item.id} remove={(id) => UnBlockUser(id)} />,
                false,
                "sm"
            )
        );
    }

    const UnBlockUser = (id) => {
        let newData = users.data.filter((user) => user.id != id);
        setUsers({ ...users, data: newData });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    title={`لیست کاربران`}
                    headData={HeaderData}
                    data={users.data ?? []}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                unblock: () => unBlock(item),
                            }}
                            unblock={true}
                        />
                    )}
                    lastPage={users.last_page ?? 1}
                    currentPage={page}
                    route="/dashboard/users/unBlocked"
                />
            </Grid>
        </Grid>
    );
};

export default Users;

