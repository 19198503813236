const personalInfoInputs = (defaults) => {
    return {
        smoking: {
            elementType: "switch",
            elementConfig: {
                label: "سیگار میکشم",
                variant: "outlined",
                value: defaults.smoking,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        drinking: {
            elementType: "switch",
            elementConfig: {
                label: "مشروب میخورم",
                variant: "outlined",
                value: defaults.drinking,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
    }}

    export default personalInfoInputs;