import React from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";

import useApi from "../../../Hooks/UseApi";
import { sendNotificationApi } from "../../../API/request";

function SendNotification(props) {
    const [data, loading, callApi] = useApi();
    const [message, setMessage] = React.useState("در اسرع وقت جهت تکمیل پرونده به شماره 09111368417 روی واتساپ پیام دهید.");
    const submitForm = async () => {
        let data = await callApi(sendNotificationApi, { request_id: props.data.id, message: message }, null , null, "loading");
        setMessage("");
    };
    return (
        <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12}>
                <TextField fullWidth value={message} label="پیام" variant="outlined" multiline rows={2} onChange={(e) => setMessage(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={submitForm}>
                    ارسال
                </Button>
            </Grid>
        </Grid>
    );
}

export default SendNotification;
