import axios from "axios";

export const SET_REQUEST_DATA = 'SET_REQUEST_DATA';
export const REMOVE_REQUEST_DATA = 'REMOVE_REQUEST_DATA';

export const setRequestData = (data) => ({type: SET_REQUEST_DATA, data});
export const removeRequestData = () => ({type: REMOVE_REQUEST_DATA});

export const fetchRequestData = (id) => (dispatch, getState) => {
    axios
        .get(`/profile/requests?id=${id}`)
        .then((response) => {
            dispatch(setRequestData(response.data.data));
        })
        .catch((error) => {
            console.log(error);
        });
};
