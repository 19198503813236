import React from "react";
import {
    Card,
    CardActionArea,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

const style = makeStyles({
    root: {
        borderRadius: 20,
        color: "#fff"
    },
    CardActionArea: {
        padding: 20,
    },
    row: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        fontSize: 25,
        fontFamily: "IRANSansBold",
        textAlign: 'center'
    },
    description: {
        fontSize: 20,
    },
});
const InfoCard = ({ data }) => {
    const classes = style();
    return (
        <Card className={classes.root} style={{backgroundColor: data.color}}>
            <CardActionArea className={classes.CardActionArea}>
                <Grid container>
                    <Grid item xs={12} className={classes.row}>
                        {data.icon}
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.row}>
                        <Typography className={classes.description}>
                            {data.value}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

export default InfoCard;
