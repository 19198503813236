import React from "react";
import {
    Button,
    Menu,
    MenuItem,
    Typography,
    IconButton,
    ListItemIcon,
    makeStyles,
} from "@material-ui/core";
import {
    ExpandMore,
    ExpandLess,
    AccountCircle,
    ExitToApp,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import avatar from "../../assets/images/avatar.jpg";
import {logoutApi} from "../../Redux/actions/userActions";

const style = makeStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    showMenuIcon: {
        color: "#fff",
    },
    userName: {
        color: "#fff",
    },
    avatar: {
        width: 50,
        borderRadius: "50%",
        marginLeft: 16,
    },
});

const AvatarMenu = ({ user, itemsColor }) => {
    const classes = style();
    const history = useHistory();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (route) => {
        handleClose();
        history.push(route);
    }

    const logout = () => {
        dispatch(logoutApi())
    }

    return (
        <div className={classes.container}>
            <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <ExpandMore className={classes.showMenuIcon} style={{color: itemsColor}} />
            </IconButton>
            <Typography
                className={classes.userName}
                style={{color: itemsColor}}
            >{`${user.name} ${user.surname}`}</Typography>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={(e) => handleNavigate("/dashboard/statistic")}>
                    <ListItemIcon>
                        <AccountCircle fontSize="small" />
                    </ListItemIcon>
                    <Typography>حساب کاربری</Typography>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    <Typography>خروج</Typography>
                </MenuItem>
            </Menu>
            <img className={classes.avatar} src={avatar} />
        </div>
    );
};

export default AvatarMenu;
