import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { hideDialog } from "../../../../Redux/actions/dialogActions";
import useApi from "../../../../Hooks/UseApi";
import {removeTemplateApi} from "../../../../API/Forms";


const RemoveForm = ({ templateId, remove }) => {
    const dispatch = useDispatch();

    const [data, loading, callApi] = useApi();
    const submitForm = async () => {
        let data = await callApi(removeTemplateApi, {template_id: templateId});
        if (data) {
            remove(templateId);
        }
        dispatch(hideDialog());
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4" style={{textAlign: "center"}}>آیا از حذف این فرم اطمینان دارید؟</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                        fullWidth
                            disableElevation
                            onClick={() => dispatch(hideDialog())}
                            variant="contained"
                            color="primary"
                        >
                            بستن
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                        fullWidth
                            disableElevation
                            onClick={submitForm}
                            variant="contained"
                            color="secondary"
                        >
                            حذف
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RemoveForm;



