import React from "react";
import { Grid, Typography, Collapse } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { addUserData } from "../../../Redux/actions/userActions";
import Button from "../../../Components/UI/Buttons/Button";
import { addWomenInfoApi, getWomenInfoApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import ShowableSwitch from "../../../Components/UI/Inputs/ShowableSwitch";
import styles from "../style";

const WomanDetails = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = React.useState(user.woman_info);
  const inputChangedHandler = (value, inputIdentifier) => {
    setFormData({ ...formData, [inputIdentifier]: value });
  };

  const [data, loading, callApi] = useApi();
  const submitForm = async () => {
    const data = await callApi(
      addWomenInfoApi,
      formData,
      "/profile/routine-info"
    );
    if (data) {
      dispatch(addUserData({ woman_info: data.data }));
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              اطلاعات قاعدگی
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ShowableSwitch
              label="آیا قاعده میشوید؟"
              value={formData.menstruation}
              onChange={(checked) => {
                if (!checked) {
                  setFormData({});
                } else {
                  inputChangedHandler(checked, "menstruation");
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Collapse in={formData.menstruation}>
              <ShowableSwitch
                label="آیا قاعدگی شما منظم است؟"
                value={formData.regular_menstruation}
                onChange={(checked) =>
                  inputChangedHandler(checked, "regular_menstruation")
                }
              />
            </Collapse>
          </Grid>
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              className={classes.nextBtn}
              disableElevation
              onClick={() => submitForm()}
              variant="contained"
              color="primary"
              title="بعدی"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WomanDetails;
