import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useLocation, useParams } from "react-router-dom";

import Button from "../../../Components/UI/Buttons/Button";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import { updateProfileApi } from "../../../API/profile";
import getFormData from "../../../shared/getFormData";
import { singleRequestData, updateRequestData, removeTestsApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import translator from "../../../shared/translator";

import MeasuresInputs from "./MeasuresInputs";
import AlergiesInputs from "./AlergiesInputs";
import DocumentsInputs from "./DocumentsInputs";
import SicknessInputs from "./SicknessInputs";
import ExercisesInputs from "./ExercisesInputs";
import AnalyzeInputs from "./AnalyzeInputs";

const EditRequest = () => {
    const { requestId } = useParams();
    const [formData, setFormData] = React.useState({});
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(singleRequestData, { id: requestId });
        let examinations = [];
        data.documents.map((doc) => {
            if (doc.type === "examination") examinations.push({ link: doc.path, id: doc.id });
        });
        let ultrasounds = [];
        data.documents.map((doc) => {
            if (doc.type === "ultrasound") ultrasounds.push({ link: doc.path, id: doc.id });
        });
        setFormData({ ...data, request_id: data.id, examinations, ultrasounds });
    }, []);
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    const onRemove = (index, id) => {
        console.log(index, id);
        if (typeof index === "string") {
            let doc = [...formData[id]];
            doc.splice(index, 1);
            setFormData({ ...formData, [id]: doc });
        } else {
            callApi(removeTestsApi, { document_id: index.id })
                .then((res) => {
                    let docs = [...formData[id]];
                    let newDocs = docs.filter((doc) => doc.id !== index.id);
                    setFormData({ ...formData, [id]: newDocs });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    let MeasuresFormElements = formCreator(MeasuresInputs(formData), inputChangedHandler);
    let AlergiesFormElements = formCreator(AlergiesInputs(formData), inputChangedHandler);
    let DocumentsFormElements = formCreator(DocumentsInputs({ ...formData, onRemove }), inputChangedHandler);
    let SicknessFormElements = formCreator(SicknessInputs(formData), inputChangedHandler);
    let ExercisesFormElements = formCreator(ExercisesInputs(formData), inputChangedHandler);
    let AnalyzeFormElements = formCreator(AnalyzeInputs(formData), inputChangedHandler);

    const submitForm = () => {
        console.log(formData);
        callApi(updateRequestData, getFormData(formData), "/dashboard/diet-requests");
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h4" component="h4">
                                    نوع درخواست
                                </Typography>
                                <Typography>{`${formData.diet ? "رژیم" : ""} ${formData.exercise ? "تمرین" : ""}`}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h4" component="h4">
                                    نوع رژیم
                                </Typography>
                                <Typography>
                                    {formData.types?.map((type) => (
                                        <span>{type.name}.</span>
                                    ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography variant="h4" component="h4">
                                    وضعیت
                                </Typography>
                                <Typography>{translator(formData.status)}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    اندازه گیری
                                </Typography>
                            </Grid>
                            {formData.disablity ? MeasuresFormElements : MeasuresFormElements.splice(0, 6)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    آلرژی
                                </Typography>
                            </Grid>
                            {formData.has_alergies ? AlergiesFormElements : AlergiesFormElements.splice(0, 1)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    بیماری
                                </Typography>
                            </Grid>
                            {formData.has_diseases ? SicknessFormElements : SicknessFormElements.splice(0, 1)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    جلسات تمرین در هفته
                                </Typography>
                            </Grid>
                            {formData.has_exercises ? ExercisesFormElements : ExercisesFormElements.splice(0, 1)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    مدارک
                                </Typography>
                            </Grid>
                            {DocumentsFormElements}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3">
                                    تصاویر
                                </Typography>
                            </Grid>
                            {formData.exercise ? AnalyzeFormElements : AnalyzeFormElements.splice(0, 1)}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Button fullWidth variant="contained" color="primary" onClick={() => submitForm()} title="ثبت تغییرات" loading={loading} />
            </Grid>
        </Grid>
    );
};

export default EditRequest;
