import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const styles = makeStyles({
    imageContainer: {
        display: "flex",
        justifyContent: "center",
    },
    image: {
        width: 200,
        borderRadius: "50%",
        border: "solid 3px #fff",
    },
    name: {
        textAlign: "center",
        color: "#74701A"
    },
    position: {
        textAlign: "center",
        color: "#74701A"
    },
});
const CowWorker = ({ data }) => {
    const classes = styles();
    return (
        <Grid container >
            <Grid item xs={12} className={classes.imageContainer}>
                <img className={classes.image} src={data.image} />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    className={classes.name}
                    variant="h3"
                    component="h3"
                >
                    {data.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    className={classes.position}
                    variant="h6"
                    component="h6"
                >
                    {data.position}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CowWorker;
