import React from "react";
import { useSelector } from "react-redux";
import MuiBackdrop from "@material-ui/core/Backdrop";
import { makeStyles, CircularProgress } from "@material-ui/core";

const styles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#fff",
        flexDirection: "column",
    },
    progress: {
        marginTop: 20,
        color: "#196B3C",
    },
}));

const Backdrop = (props) => {
    const classes = styles();
    const loading = useSelector(state => ({...state.loading}))
    return (
        <MuiBackdrop className={classes.backdrop} open={loading.fullScreenLoading}>
            <CircularProgress className={classes.progress}/>
        </MuiBackdrop>
    );
};

export default Backdrop;
