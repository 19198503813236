import React from "react";
import {
    Grid,
    Card,
    CardContent,
    makeStyles,
    TextField,
    Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import formCreator from "../../../shared/formCreator";
import filterInput from "./filterInput";

const style = makeStyles({
    root: {
        marginTop: 20,
        boxShadow: "0 0 20px 0 #d7dee366",
        width: "100%",
    },
});
const Filter = () => {
    const classes = style();
    const [formData, setFormData] = React.useState({});
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(
        filterInput(formData),
        inputChangedHandler
    );

    return (
        <Card className={classes.root}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Grid container spacing={3}>
                            {formElements}
                            {/* <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="از تاریخ"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="تا تاریخ"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="نوع درخواست"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="شماره موبایل"
                                    variant="outlined"
                                />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            startIcon={<SearchIcon />}
                        >
                            فیلتر
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Filter;
