import React from "react";
import axios from "axios";
import { Button, Grid, TextField, Typography, makeStyles, Collapse } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { loginApi } from "../../../API/auth";
import { showLoading, hideLoading } from "../../../Redux/actions/loadingActions";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import ShowableSwitch from "../../../Components/UI/Inputs/ShowableSwitch";
import { setUserData } from "../../../Redux/actions/userActions";
import { toEnNumber } from "../../../shared/toEnNumber";

const styles = makeStyles({
    title: {
        textAlign: "center",
    },
    registerLink: {
        textDecoration: "underline",
        color: "#196B3C",
    },
});

const LoginForm = () => {
    const classes = styles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    const history = useHistory();
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({ otp: true });
    const inputChangedHandler = (value, inputIdentifier) => {
        let data = value;
        if (inputIdentifier === "mobile") {
            data = toEnNumber(value);
        }
        setFormData({ ...formData, [inputIdentifier]: data });
    };
    const submitForm = () => {
        dispatch(showLoading());
        loginApi(formData)
            .then((response) => {
                dispatch(hideLoading());
                if (formData.otp) {
                    history.push({
                        pathname: "/auth/verification",
                        state: {
                            ...formData,
                            counter: response.counter,
                            type: "login",
                        },
                    });
                } else {
                    dispatch(setUserData(response.data));
                    history.push("/");
                }
            })
            .catch((err) => {
                console.log(err.data)
                dispatch(hideLoading());
                if (err?.data.errors) {
                    let errorData = Object.keys(err.data.errors).map((error) => <Typography>{err.data.errors[error]}</Typography>);
                    dispatch(showAlert(errorData, "error"));
                } else if (err?.data.message) {
                    dispatch(showAlert(err.data.message, "error"));
                }
            });
    };
    return (
        <Grid container spacing={3} style={{ padding: isMobile ? "50px 10%" : "50px 25%" }}>
            <Grid item xs={12}>
                <Typography variant="h2" component="h2" className={classes.title}>
                    ورود
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <ShowableSwitch
                    label="ورود با کد تایید"
                    style={{
                        diplay: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    labelStyle={{ margin: 0 }}
                    value={formData.otp}
                    onChange={(checked) => inputChangedHandler(checked ? 1 : 0, "otp")}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField onChange={(e) => inputChangedHandler(e.target.value, "mobile")} fullWidth label="شماره موبایل" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={!formData.otp}>
                    <TextField
                        onChange={(e) => inputChangedHandler(e.target.value, "password")}
                        fullWidth
                        label="رمز عبور"
                        variant="outlined"
                        type="password"
                    />
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={submitForm} fullWidth variant="contained" color="primary">
                    {formData.otp ? "ارسال کد" : "ورود"}
                </Button>
            </Grid>
            
        </Grid>
    );
};

export default LoginForm;
