import React from "react";
import { Button, Grid, IconButton, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import DataTable from "../../../Components/Tables/DataTables";
import MobileDataTables from "../../../Components/Tables/MobileDataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../Components/UI/Buttons/AddButton";

import useApi from "../../../Hooks/UseApi";
import { getMyRequestsApi } from "../../../API/request";
import translator from "../../../shared/translator";

const DietRequest = () => {
  const history = useHistory();
  const queryString = window.location;
  const url = new URL(queryString);
  const page = url.searchParams.get("page");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const user = useSelector((state) => state.user);
  const HeaderData = [
    { field: "request_code", title: "شماره درخواست" },
    { field: "types", title: "نوع رژیم", type: "array" },
    { field: "created_at", title: "تاریخ", type: "date" },
    { field: "state", title: "وضعیت" },
    { field: "action", title: "ویرایش" },
    { field: "show", title: "مشاهده پاسخ" },
  ];

  const mobileHeaderData = [
    { field: "request_code", title: "شماره درخواست" },
    { field: "types", title: "نوع رژیم", type: "array" },
    { field: "created_at", title: "تاریخ", type: "date" },
    { field: "state", title: "وضعیت" },
  ];

  const [requests, setRequests] = React.useState([]);
  const [data, loading, callApi] = useApi();
  React.useEffect(async () => {
    let data = await callApi(getMyRequestsApi, { page });
    setRequests(data);
  }, [page]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <AddButton
          title="درخواست رژیم جدید"
          fullwidth={isMobile}
          link={
            user.confirm_profile
              ? "/profile/request/new/types"
              : "/profile/personal-info"
          }
        />
      </Grid>
      <Grid item xs={12}>
        {isMobile ? (
          <MobileDataTables
            title="لیست رژیم ها"
            headData={mobileHeaderData}
            headerMobileField="created_at"
            data={requests?.data ?? []}
            action={(item) => (
              //   <ActionButtons
              //     functions={{
              //       edit: () =>
              //         history.push(`/dashboard/diet-requests/edit/${item.id}`),
              //     }}
              //     edit={
              //       item.status === "DONE" || item.status === "IN_PROGRESS"
              //         ? false
              //         : true
              //     }
              //   />
              <Grid container>
                {item.status === "DONE" ||
                item.status === "IN_PROGRESS" ? null : (
                  <Grid item xs>
                    <Button
                      style={{ borderRadius: 0, backgroundColor: "#4879e7", color: "#fff" }}
                      fullWidth
                      onClick={() =>
                        history.push(`/dashboard/diet-requests/edit/${item.id}`)
                      }
                    >
                      ویرایش
                    </Button>
                  </Grid>
                )}
                <Grid item xs>
                  <Button
                    style={{ borderRadius: 0, backgroundColor: item.status === "DONE" ? "#BBEE92" : "#ccc" }}
                    fullWidth
                    onClick={() =>
                      item.status === "DONE"
                        ? history.push(`/dashboard/diet-requests/${item.id}`)
                        : null
                    }
                  >
                    {item.status === "DONE" ? "مشاهده پاسخ" : "پاسخی درج نشده"}
                  </Button>
                </Grid>
              </Grid>
            )}
            arrayFields={[{ name: "types", field: ["name"], seprator: "،" }]}
            state={(item) => (
              <div>
                {translator(item.status)}
                {item.status === "PENDING" && (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginRight: 10, height: 30 }}
                    onClick={() =>
                      history.push(`/profile/request/${item.id}/payment`)
                    }
                  >
                    صفحه پرداخت
                  </Button>
                )}
              </div>
            )}
            lastPage={requests.meta?.last_page ?? 1}
            currentPage={page}
            route="/dashboard/diet-requests"
          />
        ) : (
          <DataTable
            title="لیست رژیم ها"
            headData={HeaderData}
            headerMobileField="created_at"
            data={requests?.data ?? []}
            action={(item) => (
              <ActionButtons
                functions={{
                  edit: () =>
                    history.push(`/dashboard/diet-requests/edit/${item.id}`),
                }}
                edit={
                  item.status === "DONE" || item.status === "IN_PROGRESS"
                    ? false
                    : true
                }
              />
            )}
            arrayFields={[{ name: "types", field: ["name"], seprator: "،" }]}
            state={(item) => (
              <div>
                {translator(item.status)}
                {item.status === "PENDING" && (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    style={{ marginRight: 10, height: 30 }}
                    onClick={() =>
                      history.push(`/profile/request/${item.id}/payment`)
                    }
                  >
                    صفحه پرداخت
                  </Button>
                )}
              </div>
            )}
            show={(item) => {
              if (item.status === "DONE") {
                return (
                  <IconButton
                    style={{
                      backgroundColor: "#BBEE92",
                      color: "#559F19",
                    }}
                    onClick={() =>
                      history.push(`/dashboard/diet-requests/${item.id}`)
                    }
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                );
              } else {
                return "هیچ جوابی درج نشده";
              }
            }}
            lastPage={requests.meta?.last_page ?? 1}
            currentPage={page}
            route="/dashboard/diet-requests"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DietRequest;
