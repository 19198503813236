import React from "react";
import { Grid } from "@material-ui/core";

import Tab from "../../../Components/Tabs/Tab";
import Answered from "./Answered";
import UnAnswered from "./UnAnswered";

const Reponses = () => {
    const tabData = [
        { title: "در انتظار پاسخ", component: <UnAnswered /> , route: "/dashboard/reponses/unAnswred"},
        { title: "پاسخ داده شده", component: <Answered />, route: "/dashboard/reponses/answred" },
    ];
    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <UnAnswered /> */}
                <Tab data={tabData} />
            </Grid>
        </Grid>
    );
};

export default Reponses;
