import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";

const AudioItem = ({ path }) => {
//  const [audio, setAudio] = React.useState({ path, audioContext: null });
//  useEffect(() => {
//    const AudioContext = window.AudioContext || window.webkitAudioContext;
//    const audioContext = new AudioContext();
//    const audioElement = document.querySelector("audio");
//    setAudio({ ...audio, audioContext, audioElement: audioElement });
 //   const track = audioContext.createMediaElementSource(audioElement);
 //   track.connect(audioContext.destination);
 //   console.log(path);
  //}, []);

  return (
    <Grid item md={12} xs={12}>
      <audio
        style={{ width: "100%", borderRadius: 20 }}
        controls
        src={path}
      />
      {/* <Button
        fullWidth
        variant="contained"
        color="primary"
        title="Play"
        onClick={() => audio.audioElement.play()}
      /> */}
    </Grid>
  );
};

export default AudioItem;
