import axios from "axios";

export const SET_USER_DATA = "SET_USER_DATA";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const LOGOUT = "LOGOUT";

export const setUserData = (data) => ({ type: SET_USER_DATA, data });
export const addUserData = (data) => ({ type: ADD_USER_DATA, data });
export const logout = () => ({ type: LOGOUT });

export const checkLogin = () => (dispatch, getState) => {
    axios
        .get(`/auth/check-login`)
        .then((response) => {
            console.log(response);
            dispatch(setUserData(response.data.data));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const login = (data, history) => (dispatch, getState) => {
    axios
        .post(`/auth/login/validate/`, data)
        .then((response) => {
            console.log(response);
            dispatch(setUserData(response.data.data));
            history.push("/");
        })
        .catch((error) => {
            console.log(error);
        });
};

export const register = (data, history) => (dispatch, getState) => {
    axios
        .post(`/auth/register/validate/`, data)
        .then((response) => {
            console.log(response);
            dispatch(setUserData(response.data.data));
            history.push("/");
        })
        .catch((error) => {
            console.log(error);
        });
};

export const logoutApi = () => (dispatch, getState) => {
    axios
        .post(`/auth/logout/`)
        .then((response) => {
            console.log(response);
            dispatch(logout());
        })
        .catch((error) => {
            console.log(error);
        });
};
