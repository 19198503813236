import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import AuthHoc from "../Hoc/AuthHoc";
import AuthRoute from "./AuthRoute";
import HomeRoute from "./HomeRoute";
import PageNotFound from "../Pages/NotFound/PageNotFound";
import MaintenanceMode from "../Pages/MaintenanceMode/MaintenanceMode";
import Dialog from "../Components/Dialog/Dialog";
import ProfileRoute from "./ProfileRoute";
import DashboardRoute from "./DashboardRoute";

import { useSelector } from "react-redux";
// const PageNotFound = React.lazy(() => import("../pages/404/PageNotFound"));

const MainRoute = () => {
    const user = useSelector((state) => state.user);
    return (
        <React.Suspense>
            <AuthHoc>
                <Dialog />
                <BrowserRouter basename="/">
                    <Switch>
                        {user && (
                            <Route
                                path="/dashboard"
                                component={DashboardRoute}
                            />
                        )}
                        {user && (
                            <Route path="/profile" component={ProfileRoute} />
                        )}

                        <Route path="/auth" component={AuthRoute} />
                        <Route path="/" component={HomeRoute} />
                        {/* <Route path="/" component={MaintenanceMode} /> */}

                        {/* <Route path="*" component={PageNotFound} /> */}
                    </Switch>
                </BrowserRouter>
            </AuthHoc>
        </React.Suspense>
    );
};

export default MainRoute;
