import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Home from "../Pages/Home/Home";
import AbouteUs from "../Pages/AbouteUs/AbouteUs";
import ContactUs from "../Pages/ContactUs/ContactUs";
import SingleArticle from "../Pages/SingleArticle/SingleArticle";
import Articles from "../Pages/Articles/Articles";
import VideoCats from "../Pages/VideoCats/VideoCats";
import Videos from "../Pages/Videos/Videos";
import Rules from "../Pages/Rules/Rules";
import { useSelector } from "react-redux";

import Header from "../Components/Header/Header";
import BottomAppBar from "../Components/AppBar/BottomAppBar";
import Footer from "../Components/Footer/Footer";

const HomeRoute = () => {
    const user = useSelector((state) => state.user);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Grid container>
            <Header />
            <Grid item xs={12}>
                <Switch>
                    <Route path="/aboute-us" component={AbouteUs} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route
                        path="/articles/:articleId"
                        component={SingleArticle}
                    />
                    <Route path="/articles" component={Articles} />
                    {/* <Route path="/video-categories/:CategoryId" component={Videos} />
                        <Route path="/video-categories" component={VideoCats} /> */}
                    <Route path="/videos" component={Videos} />
                    <Route path="/rules" component={Rules} />
                    <Route path="/" component={Home} />
                </Switch>
            </Grid>
            {(isMobile && user) && <BottomAppBar />}
            <Footer />
        </Grid>
    );
};

export default HomeRoute;
