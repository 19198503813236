import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch } from "react-redux";

import useApi from "../../Hooks/UseApi";
import { getVideosApi } from "../../API/video";
import { showDialog } from "../../Redux/actions/dialogActions";
import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import Video from "../../Components/Video/VideoCat";
import headerImg from "../../assets/images/headerImg1.jpg";
import Content from "../../Components/Video/DialogVideoPlayer";

const style = makeStyles({
    pagination: {
        margin: 20,
        display: "flex",
        justifyContent: "center",
    },
    icon: {
        fontSize: 50,
    },
});
const Videos = () => {
    const classes = style();
    const dispatch = useDispatch();
    const [Videos, setVideos] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);
    const showVideo = (data) => {
        dispatch(showDialog(<Content data={data} />));
    };

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getVideosApi, { limit: 12, page: page });
        setLastPage(data.meta.last_page)
        setVideos(data.data);
    }, [page]);
    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Grid container>
            <PageHeader title="ویدیو ها" image={headerImg} />
            <MainContainer>
                <Grid container spacing={3}>
                    {Videos.map((video) => (
                        <Video
                            data={video}
                            icon={
                                <PlayCircleOutlineIcon
                                    className={classes.icon}
                                />
                            }
                            onClick={() => showVideo(video)}
                        />
                    ))}
                    <Grid xs={12}>
                        <Pagination
                            className={classes.pagination}
                            count={lastPage}
                            color="primary"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default Videos;
