import React from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    Grid,
    Typography,
    makeStyles,
} from "@material-ui/core";
import SingleItem from "./SingleItem";

const styles = makeStyles({
    container: {
        padding: 10,
    },
    root: {
        height: 50,
        borderRadius: 15,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "solid 1px #ccc",
    },
    ActionArea: {
        textAlign: "center",
        height: "100%",
    },
});
const AccordionContent = ({ data, addType }) => {
    const classes = styles();
    return (
        <Grid container spacing={3} className={classes.container}>
            {data.map((item) => (
                <SingleItem data={item} addType={(id, active) => addType(id, active)}/>
            ))}
        </Grid>
    );
};

export default AccordionContent;
