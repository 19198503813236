import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { Close, Save, Mic } from "@material-ui/icons";

import { formatMinutes, formatSeconds } from "../../shared/format-time";
import "./styles.css";

const styles = makeStyles({
  container: {
    backgroundColor: "#e8e8e8",
    padding: 10,
    borderRadius: 40,
    // marginBottom: 20
  },
  timeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    boxShadow: "0px 0px 9px 2px #bfbfbf",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    backgroundColor: "#fee5ea",
    color: "#f62d56",
    marginRight: 5
  },
  saveBtn: {
    backgroundColor: "#7FB5FF",
    color: "#fff",
  },
  startBtn: {
    backgroundColor: "#BBEE92",
    color: "#559F19",
  },
});

export default function RecorderControls({ recorderState, handlers }) {
  const classes = styles();
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
  const { startRecording, saveRecording, cancelRecording } = handlers;

  return (
    <Grid container className={classes.container} >
      <Grid
        item
        xs={3}
        className={classes.timeContainer}
      >
        <Typography
          style={{ direction: "ltr" }}
          variant="h3"
          component="h3"
        >{`${formatMinutes(recordingMinutes)} : ${formatSeconds(
          recordingSeconds
        )}`}</Typography>
      </Grid>
      <Grid item xs={9} className={classes.btnContainer}>
        {initRecording && (
          <IconButton className={classes.cancelBtn} onClick={cancelRecording}>
            <Close color="secondary" />
          </IconButton>
        )}
        {initRecording ? (
          <IconButton
            className={classes.saveBtn}
            title="Save recording"
            // disabled={recordingSeconds === 0}
            onClick={saveRecording}
          >
            <Save />
          </IconButton>
        ) : (
          <IconButton
            className={classes.startBtn}
            title="Start recording"
            onClick={startRecording}
          >
            <Mic />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
