import React from "react";
import { Card, CardContent, Grid, Typography, makeStyles, IconButton } from "@material-ui/core";
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import DataTable from "../../../Components/Tables/DataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import { getAllSummaryApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import translator from "../../../shared/translator";
import { Link } from "react-router-dom";

const styles = makeStyles({
    root: {
        padding: 20,
    },
    rowContainer: {
        position: "relative",
        padding: 20,
    },
    rowTitle: {
        backgroundColor: "#ccc",
        padding: "10px 10px",
    },
});
const PastRequestes = ({ mobile, requestId }) => {
    const classes = styles();
    const HeaderData = [
        { field: "name", title: "نام" },
        { field: "surname", title: "نام خانوادگی" },
        { field: "mobile", title: "شماره موبایل" },
        { field: "reqStatus", title: "وضعیت" },
        { field: "reqType", title: "نوع درخواست" },
        { field: "payed_at", title: "تاریخ پرداخت", type: "dateTime" },
        { field: "started_at", title: "تاریخ ارسال رژیم", type: "dateTime" },
        { field: "action", title: "مشاهده و پاسخ" },
    ];
    const [Requests, setRequests] = React.useState([]);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllSummaryApi, { status: "DONE", search: mobile });
        setRequests(data.data.filter(req => req.request_id !== requestId));
    }, []);

    return (
        <Grid container spacing={2} className={classes.rowContainer}>
            <Grid item xs={12}>
                <DataTable
                    headData={HeaderData}
                    data={Requests}
                    action={(item) => (
                        <IconButton
                            component={Link}
                            to={`/dashboard/reponses/${item.request_id}`}
                            target="_blank"
                            className={classes.actionButton}
                            style={{ backgroundColor: "#BBEE92", color: "#559F19" }}
                        >
                            <RemoveRedEyeIcon />
                        </IconButton>
                    )}
                    reqStatus={(item) => translator(item.status)}
                    reqType={(item) => `${item.diet ? "رژیم" : ""} ${(item.diet && item.exercise) ? "+" : ""} ${item.exercise ? "تمرین" : ""}`}
                    // lastPage={summaries.meta?.last_page ?? 1}
                    // currentPage={page}
                    // route="/dashboard/reponses/answred"
                />
            </Grid>
        </Grid>
    );
};

export default PastRequestes;
