import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import { useDispatch } from "react-redux";

import useApi from "../../../../Hooks/UseApi";
import { getAllPostsApi } from "../../../../API/product";
import { showDialog } from "../../../../Redux/actions/dialogActions";
import DataTable from "../../../../Components/Tables/DataTables";
import ActionButtons from "../../../../Components/UI/Buttons/ActionButtons";
import AddButton from "../../../../Components/UI/Buttons/AddButton";

const Articles = () => {
    const [Posts, setPosts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);
    const HeaderData = [
        { field: "title", title: "عنوان" },
        { field: "categories", title: "دسته بندی", type: "array" },
        { field: "action", title: "عملیات" },
    ];
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllPostsApi, { page: page });
        setPosts(data.data);
        setLastPage(data.meta.last_page);
    }, [page]);
    const editModal = () => {};
    const removeModal = () => {};
    return (
        <Grid container>
            <Grid item xs={12}>
                <AddButton
                    title="اضافه کردن مقاله"
                    link="/dashboard/articles/new-article"
                />
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title="لیست ویدیو ها"
                    headData={HeaderData}
                    data={Posts}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                edit: () => editModal(item),
                                remove: () => removeModal(item),
                            }}
                            edit
                            remove
                        />
                    )}
                    arrayFields={[
                        { name: "categories", field: ["name"], seprator: "،" },
                    ]}
                    lastPage={lastPage}
                    changePage={(page) => setPage(page)}
                />
            </Grid>
        </Grid>
    );
};

export default Articles;
