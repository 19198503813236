import React from "react";
import {
    Grid,
    Button,
    TextField,
    MenuItem,
    Typography,
    makeStyles,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import axios from "axios";

import CustomAccordion from "../../../Components/Surfaces/CustomAccordion/CustomAccordion";
import RequestDetiles from "../SingleRequest/RequestDetiles";
import RequestDetilesImages from "../SingleRequest/RequestDetilesImages";

import { getAllGroupsApi } from "../../../API/Forms";
import {
    getSummaryApi,
    getResponsesApi,
    submitResponseApi,
} from "../../../API/response";
import { getMyRequestsApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import { showDialog, hideDialog } from "../../../Redux/actions/dialogActions";
import ModalForms from "../SingleReponse/ModalForms";

const styles = makeStyles({
    root: {
        padding: 20,
    },
    rowContainer: {
        // border: "solid 1px #ccc",
        position: "relative",
        padding: 20,
    },
    rowTitle: {
        backgroundColor: "#fff",
        position: "absolute",
        top: -12,
        padding: "0 10px",
    },
});
const RequestDetilesForms = ({ title, request }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const [groups, setGroups] = React.useState([]);
    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllGroupsApi);
        setGroups(data);
    }, []);
    const showForms = (groupId) => {
        dispatch(
            showDialog(<ModalForms groupId={groupId} navigate={handleSelect} />)
        );
    };

    const handleSelect = (form) => {
        axios
            .post("/requests/response/", {
                response: form.path,
                request_id: request.id,
                template_id: form.id,
            })
            .then((response) => {
                console.log(response);
                // history.goBack();
            })
            .catch((error) => {
                console.log(error.response);
            });
    };
    return (
        <Grid container spacing={2} className={classes.rowContainer}>
            <Typography
                className={classes.rowTitle}
                variant="h4"
                component="h4"
            >
                {title}
            </Typography>

            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {groups.map((group) => (
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.prescriptionForms}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => showForms(group.id)}
                            >
                                اضافه کردن فرم {group.name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RequestDetilesForms;
