import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import useApi from "../../Hooks/UseApi";
import { getAllPostsApi } from "../../API/product";
import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import Article from "../../Components/Article/Article";
import headerImg from "../../assets/images/headerImg1.jpg";

const style = makeStyles({
    pagination: {
        margin: 20,
        display: "flex",
        justifyContent: "center",
    },
});

const Articles = () => {
    const classes = style();
    const [Posts, setPosts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);

    const [data, loading, callApi] = useApi();
    React.useEffect(async () => {
        let data = await callApi(getAllPostsApi, { limit: 12, page: page });
        setPosts(data.data);
        setLastPage(data.meta.last_page)
    }, [page]);

    const handleChange = (event, value) => {
        setPage(value);
    };
    return (
        <Grid container>
            <PageHeader title="مقالات" image={headerImg} />
            <MainContainer>
                <Grid container style={{ marginTop: 50 }} spacing={3}>
                    {Posts.map((article) => (
                        <Article data={article} />
                    ))}
                    <Grid xs={12}>
                        <Pagination
                            className={classes.pagination}
                            count={lastPage}
                            color="primary"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default Articles;
