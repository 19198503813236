import { SHOW_DIALOG, HIDE_DIALOG } from "../actions/dialogActions";

const initialState = {
    show: false,
    data: {},
    footer: true,
    size: 'lg'
}
const dialog = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_DIALOG:
            state.show = true;
            state.data = action.data;
            state.footer = action.footer;
            state.size = action.size;
            return state;
        case HIDE_DIALOG:
            state.show = false;
            return state;
        default:
            return state;
    }
};

export default dialog;