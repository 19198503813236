import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import HomeBanner from "../../Components/Home/HomeBanner";
import Section2 from "../../Components/Home/Section2";
import Section3 from "../../Components/Home/Section3";
import Section4 from "../../Components/Home/Section4";
import Section5 from "../../Components/Home/Section5";
import Section6 from "../../Components/Home/Section6";

const Home = () => {
//   useEffect(() => {
//     navigator.getUserMedia =
//       navigator.getUserMedia ||
//       navigator.webkitGetUserMedia ||
//       navigator.mediaDevices.getUserMedia() ||
//       navigator.mozGetUserMedia ||
//       navigator.msGetUserMedia;
//     navigator.getUserMedia(
//       { audio: true },
//       () => {
//         console.log("Permission Granted");
//         // setRecorderData({ ...recorderData, isBlocked: false });
//       },
//       () => {
//         console.log("Permission Denied");
//         // setRecorderData({ ...recorderData, isBlocked: true });
//       }
//     );
//   }, []);
  return (
    <Grid container>
      <HomeBanner />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Section6 /> */}
    </Grid>
  );
};

export default Home;
