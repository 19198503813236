import axios from "axios";

export const getSummaryApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/requests/summary", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const removeSummaryApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .delete(`/requests/summary/${data.id}`)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getResponsesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/profile/requests/responses", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const submitResponseApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/requests/submit-response/", data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const removeResponseApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .delete("/requests/remove-response/", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const removeResponseAudioApi = (data) => new Promise((resolve, reject) => {
    axios
        .delete("/requests/remove-audio/", { params: { ...data } })
        .then((response) => {
            resolve(response.data.data);
        })
        .catch((error) => {
            reject(error.response);
        });
});
