export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_BTN_LOADING = 'SHOW_BTN_LOADING';
export const HIDE_BTN_LOADING = 'HIDE_BTN_LOADING';

export const showLoading = () => ({type: SHOW_LOADING});
export const hideLoading = () => ({type: HIDE_LOADING});

export const showBtnLoading = () => ({type: SHOW_BTN_LOADING});
export const hideBtnLoading = () => ({type: HIDE_BTN_LOADING});
