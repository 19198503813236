import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import VideoCat from "../Video/VideoCat";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
    },
}));

const SingleAccordions = ({ data, content, contentField }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography
                            variant="h3"
                            component="h3"
                            className={classes.heading}
                        >
                            {data.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            {content(data[contentField])}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
    );
};

export default SingleAccordions;
