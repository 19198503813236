import React from "react";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import DataTable from "../../../Components/Tables/DataTables";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import { getAllSummaryApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import translator from "../../../shared/translator";

const Answered = () => {
    const queryString = window.location;
    const url = new URL(queryString);
    const page = url.searchParams.get("page");
    const history = useHistory();
    const [summaries, setSummaries] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [data, loading, callApi] = useApi();
    React.useEffect(() => {
        fetchData();
    }, [page]);
    const fetchData = async () => {
        let data = await callApi(getAllSummaryApi, {status: "DONE", page, search});
        setSummaries(data);
    }
    const submitSearch = (e) => {
        e.preventDefault();
        fetchData();
    }
    const HeaderData = [
        { field: "number", title: "ردیف" },
        { field: "name", title: "نام" },
        { field: "surname", title: "نام خانوادگی" },
        { field: "mobile", title: "شماره موبایل" },
        { field: "reqStatus", title: "وضعیت" },
        { field: "payed_at", title: "تاریخ پرداخت", type: "dateTime" },
        { field: "started_at", title: "تاریخ ارسال رژیم", type: "dateTime" },
        { field: "action", title: "مشاهده و پاسخ" },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <form onSubmit={submitSearch}>
                    <TextField
                        placeholder="جستجو"
                        variant="outlined"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <Button variant="contained" type="submit" color="primary" style={{marginRight: 10}} >ثبت</Button>
                </form>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    title={`لیست درخواست ها پاسخ داده شده (${summaries?.meta?.total ?? 0})`}
                    headData={HeaderData}
                    data={summaries.data ?? []}
                    number={(item, key) => (page - 1) * 10 + key}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                show: () => history.push(`/dashboard/reponses/${item.request_id}`),
                            }}
                            show
                        />
                    )}
                    reqStatus={(item) => translator(item.status)}
                    lastPage={summaries.meta?.last_page ?? 1}
                    currentPage={page}
                    route="/dashboard/reponses/answred"
                />
            </Grid>
        </Grid>
    );
};

export default Answered;
