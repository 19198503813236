import axios from "axios";

export const getAllCatApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/contents/categories", { params: { ...data } })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addPostCatApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/contents/categories/", data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addPostApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/contents/posts/", data)
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getAllPostsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/contents/posts", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });
