import React from "react";
import {
    Grid,
    Typography,
    Collapse,
    TextField,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Button from "../../../Components/UI/Buttons/Button"
import { addBodyImagesApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import formCreator from "../../../shared/formCreator";
import getFormData from "../../../shared/getFormData";
import Inputs from "./Inputs";
import styles from "../style";

const Analyze = () => {
    const classes = styles();
    const { requestId } = useParams();
    const request = useSelector((state) => state.request);
    const [formData, setFormData] = React.useState({ request_id: requestId });
    const inputChangedHandler = (value, inputIdentifier) => {
        setFormData({ ...formData, [inputIdentifier]: value });
    };
    let formElements = formCreator(
        Inputs({ ...formData }),
        inputChangedHandler
    );

    const [data, loading, callApi] = useApi();
    const submitForm = () => {
        callApi(
            addBodyImagesApi,
            getFormData(formData),
            request.exercise
                ? `/profile/request/${requestId}/body-images`
                : `/profile/request/${requestId}/payment`
        );
    };
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={3} className={classes.pageContainer}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h2"
                            component="h2"
                            className={classes.title}
                        >
                            آنالیز ترکیب بدن
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            {formElements}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.BtnContainer}>
                    <Button
                            className={classes.nextBtn}
                            disableElevation
                            onClick={() => submitForm()}
                            variant="contained"
                            color="primary"
                            title="بعدی"
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Analyze;
