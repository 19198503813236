import React from "react";
import "./style.css";
const HomeVideo = ({ data }) => {
    return (
        <div className="h_iframe-aparat_embed_frame">
            <span style={{ display: "block", paddingTop: "57%" }}></span>
            <iframe
                src={data.link}
                // title="چتر نجات؛ برنامه‌ای متفاوت برای کسب مهارت‌های امنیتی"
                allowFullScreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
            ></iframe>
        </div>
    );
};

export default HomeVideo;
