import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

const style = makeStyles({
    header: {
        height: 300,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        backgroundColor: "#0000008c",
        color: "#fff",
        padding: "5px 25px",
        borderRadius: 20,
    },
});
const PgaeHeader = ({ title, subtitle, image }) => {
    const classes = style();
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                className={classes.header}
                style={{ backgroundImage: `url(${image})` }}
            >
                <Typography
                    className={classes.title}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
                <Typography>{subtitle}</Typography>
            </Grid>
        </Grid>
    );
};

export default PgaeHeader;
