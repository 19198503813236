import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import PageHeader from "../../Components/Header/PgaeHeader";
import MainContainer from "../../Components/Container/MainContainer";
import headerImg from "../../assets/images/headerImg1.jpg";

const style = makeStyles({
    text: {
        margin: 10,
        fontSize: 20,
    },
});
const Rules = () => {
    const classes = style();
    return (
        <Grid container>
            <PageHeader title="قوانین و مقررات" image={headerImg} />
            <MainContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3">
                            تعریف مشتری یا کاربر
                        </Typography>
                        <Typography className={classes.text}>
                            مشتری یا کاربر به شخصی گفته میشود که با اطلاعات
                            کاربری خود که در فرم ثبت نام درج کرده است ، به ثبت
                            سفارش یا هرگونه استفاده از خدمات مجموعه رادایت اقدام
                            کند.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱-در صورت نقص در ارسال مدارک پزشکی و لیست داروها
                            مسئولیت بر عهده کاربر میباشد ومجموعه هیچ گونه تعهدی
                            ندارد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۲-ذکر هر گونه بیماری زمینه ای (کودکی و بزرگسالی)
                            ،مصرف هر گونه مکمل ( چربی سوز ، بدنسازی ،مکمل آهن،
                            مولتی ویتامین‌ها و…) الزامی میباشد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۳-ذکر هر گونه فعالیت ورزشی ( نوع ورزش، تعداد جلسات
                            در هفته ، نام مربی) الزامی میباشد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۴-مبلغ قابل پرداخت بابت تجویز رژیم ، طراحی تمرین و
                            پاسخ به سوالات مربوط به رژیم برای یک ماه میباشد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۵-مبلغ پرداخت شده فقط برای داوطلب ثبت نام شده میباشد
                            و قابل انتقال به فرد دیگری نیست.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۶-هر کاربر ملزم به استفاده از یک شماره موبایل برای
                            ثبت نام میباشد.( شماره پرونده شما همان شماره موبایل
                            ثبت شده میباشد.) *لطفا از ثبت نام با چندین شماره
                            پرهیز نمایید.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۷-از ثبت کردن درخواست تکراری پرهیز نمایید.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۸-بعد از مرحله پرداخت تغییرات در درخواست نوع رژیم
                            امکان پذیر نمیباشد، لطفا دقت فرمایید.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۹-ورود کاربر به وب سایت رادایت و وب سایت های وابسته
                            ( پنل های آموزشی) هنگام استفاده از پروفایل شخصی ،
                            پروموشن‌ها ، ویدئو های رسانه‌ تصویری و سایر خدمات
                            ارائه شده توسط وب‌سایت رادایت به معنای آگاه بودن و
                            پذیرفتن شرایط و قوانین و همچنین نحوه استفاده از
                            سرویس ها و خدمات است.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۰-ساعت پاسخگویی و خدمات از ۱۰ صبح الی ۱۰ شب
                            میباشد.مجموعه رادایت خارج از ساعت کاری قادر به
                            پاسخگویی نمیباشد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۱-طراحی رژیم یا برنامه تمرین شما 10 الی 14 روز کاری زمان
                            میبرد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۲-اگر کاربر سایت هستید از فرستادن هر گونه اطلاعات
                            بر روی واتس اپ خودداری فرمایید و هر گونه درخواست یا
                            سوال بر روی پروفایل شخصی خود در بخش پرسش و پاسخ ثبت
                            نمایید.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۳-شماره واتس اپ فقط جهت ارتباط ضروری میباشد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۴-ارسال هر گونه عکس نا متعارف و استفاده از هر گونه
                            الفاظ رکیک پیگرد قانونی دارد.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.text}>
                            ۱۵-مجموعه رادایت به اطلاعات خصوصی اشخاصی که از خدمات
                            سایت استفاده میکنند، احترام گذاشته و از آن محافظت
                            میکند و متعهد میشود در حد توان از حریم شخصی شما دفاع
                            کند.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3">
                            نمایندگی
                        </Typography>
                        <Typography className={classes.text}>
                            مجموعه رادایت ، هیچگونه نمایندگی یا شعبه ای ندارد و
                            تنها آدرس وب‌سایت و آدرس درج شده در وب‌سایت متعلق به
                            این مجموعه است. در صورت فروش هر گونه محصولی توسط
                            اشخاص یا مجموعه های دیگر با استفاده از نام مجموعه
                            رادایت غیر قانونی و خلاف قوانین بوده و مسئولیتی در
                            مورد وجه پرداختی شما به اینگونه افراد سودجو وجود
                            ندارد.
                        </Typography>
                    </Grid>
                </Grid>
            </MainContainer>
        </Grid>
    );
};

export default Rules;
