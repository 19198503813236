import React from "react";
import { Link } from "react-router-dom";
import { Grid, makeStyles, Divider, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import logo from "../../assets/images/logo.png";
import Menu from "../AdminMenu/Menu";
import {
  userRoutes,
  writerRoute,
  adminRoute,
  CMSRoute,
  assistantRoute,
} from "../../Routes/dashboardRoutes";

const style = makeStyles({
  root: {
    boxShadow: "0 0 11px 2px #00000029",
    height: "100%",
    position: "fixed",
    width: "20%",
    overflow: "scroll",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
    borderBottom: "solid 1px #ccc",
    height: 150,
  },
  logo: {
    width: 100,
  },
  menuContainer: {
    margin: 0,
    width: "100%",
  },
  title: {
    color: "#757575",
  },
});

const SideBar = ({ routes }) => {
  const user = useSelector((state) => state.user);
  const setRoute = () => {
    if (user.role === "admin" || user.role === "superadmin") {
      return adminRoute;
    }
    if (user.role === "writer") {
      return writerRoute;
    }
    if (user.role === "user") {
      return userRoutes;
    }
    if (user.role === "assistant") {
      return assistantRoute;
    }
  };
  const classes = style();
  return (
    <Grid className={classes.root} container>
      <Grid item xs={12} className={classes.logoContainer}>
        <Link to="/">
          <img className={classes.logo} src={logo} />
        </Link>
      </Grid>
      <Grid item xs={12} style={{ height: "calc(100% - 150px)" }}>
        <Grid container className={classes.menuContainer} spacing={3}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" className={classes.title}>
              پنل کاربری
            </Typography>
            <Menu nav={setRoute()} />
          </Grid>
          <Grid item xs={12}>
            {(user.role === "admin" ||
              user.role === "superadmin" ||
              user.role === "writer") && (
              <>
                <Typography
                  component="h4"
                  variant="h4"
                  className={classes.title}
                >
                  محتوای سایت
                </Typography>
                <Menu nav={CMSRoute} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SideBar;
