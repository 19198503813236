import React from "react";
import { makeStyles } from "@material-ui/core";

const Styles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 55,
    backgroundColor: "#f1f2f5",
    borderRadius: 4,
    position: "relative",
    borderRadius: 30,
  },
  radioLabel: {
    fontSize: 15,
    position: "absolute",
    top: -25,
    left: 12,
    color: "#7b7b7b",
  },
  radioInput: {
    display: "none",
    "&:checked + label": {
      backgroundColor: "#709aeb",
      transition: "all 0.3s",
      borderRadius: 30,
      color: "#fff"
    },
  },
  label: {
    height: 55,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // borderRadius: 6,
    cursor: "pointer",
    // transition: "all 0.3s",
    // border: "solid 2px #f1f2f5",
  },
});
const RadioButton = (props) => {
  const classes = Styles();
  const { options, id, onChange, value, label } = props;
  const elWidth = 100 / options.length + "%";

  return (
    <div className={classes.container}>
      <span className={classes.radioLabel}>{label}</span>
      {options.map((option) => (
        <div style={{ width: elWidth }}>
          <input
            checked={option.value == value}
            id={option.value}
            value={option.value}
            className={classes.radioInput}
            type="radio"
            name={id}
            onChange={(e) => onChange(e.target.value)}
          />
          <label className={classes.label} htmlFor={option.value}>
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
