import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Menu from "./Menu";
import Avatar from "./Avatar";
import logo from "../../assets/images/logo.png";

const style = makeStyles({
    headerRoot: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        width: "100%",
        zIndex: 10,
    },
    logo: {
        width: 60,
        margin: "5px 20px",
    },
    menuContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    avatarContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
});
const Header = () => {
    const classes = style();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    return (
        <Grid item xs={12} className={classes.headerRoot}>
            <Grid container>
                <Grid item xs={6} md={2}>
                    <img src={logo} className={classes.logo} />
                </Grid>
                {!isMobile && (
                    <Grid item xs={8} className={classes.menuContainer}>
                        <Menu />
                    </Grid>
                )}
                <Grid item xs={6} md={2} className={classes.avatarContainer}>
                    <Avatar />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;
