import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Grid,
  makeStyles,
  Card,
  Button,
  Typography,
  CardContent,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import FooterSocial from "../Components/SocialMedia/footerSocial";
import Login from "../Pages/Auth/Login/Login";
import Register from "../Pages/Auth/Register/Register";
import Verification from "../Pages/Auth/Verification/Verification";
import backImg from "../assets/images/Rectangle29.jpg";

const styles = makeStyles({
  backImg: {
    backgroundImage: `url(${backImg})`,
    height: "100vh",
  },
  cardRoot: {
    height: "100%",
    position: "absolute",
    // width: "50%",
    transition: "all 1s",
    borderRadius: 0,
    overflow: "scroll",
  },
  socialText: {
    textAlign: "center",
    color: "#8b8b8b",
  },
  socialContainer: {
    // position: "absolute",
    bottom: 0,
  },
  social: {
    justifyContent: "center",
  },
  socialIcon: {
    margin: 10,
    color: "#8b8b8b",
  },
});
const AuthRoute = () => {
  const classes = styles();
  const [position, setPosition] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Grid container>
      <Grid item xs={12} className={classes.backImg}>
        <Card
          className={classes.cardRoot}
          style={{ right: isMobile ? "0%" : "50%" }}
        >
          <CardContent>
            <Switch>
              <Route path="/auth/login" component={Login} />
              <Route path="/auth/register" component={Register} />
              <Route path="/auth/verification" component={Verification} />
              <Route path="/auth">
                <Redirect to="/auth/login"></Redirect>
              </Route>
            </Switch>
            <Grid container className={classes.socialContainer}>
              <Grid item xs={12}>
                <Typography className={classes.socialText}>
                  ما را در شبکه های اجتماعی دنبال کنید
                </Typography>
              </Grid>
              <FooterSocial className={classes.social} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AuthRoute;
