import React from "react";
import { Button, Grid, IconButton } from "@material-ui/core";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { showDialog } from "../../../Redux/actions/dialogActions";
import ActionButtons from "../../../Components/UI/Buttons/ActionButtons";
import { getAllRequestsApi } from "../../../API/request";
import useApi from "../../../Hooks/UseApi";
import DataTable from "../../../Components/Tables/DataTables";
import RemoveRequest from "./RemoveRequest";

const PayedRequest = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [requests, setRequests] = React.useState([]);
    // const [page, setPage] = React.useState(1);
    const [data, loading, callApi] = useApi();
    const queryString = window.location;
    const url = new URL(queryString);
    const page = url.searchParams.get("page");
    React.useEffect(async () => {
        let data = await callApi(getAllRequestsApi, {status: "PAID", page});
        setRequests(data);
    }, [page]);
    const HeaderData = [
        { field: "request_code", title: "شماره درخواست" },
        { field: "name", title: "نام" },
        { field: "surname", title: "نام خانوادگی" },
        { field: "mobile", title: "شماره موبایل" },
        { field: "requestType", title: "نوع درخواست" },
        { field: "payed_at", title: "تاریخ پرداخت", type: "dateTime" },
        { field: "payedType", title: "نوع پرداخت" },
        { field: "action", title: "عملیات" },
    ];

    const deleteModal = (item) => {
        dispatch(showDialog(<RemoveRequest id={item.id} remove={(id) => removeRequest(id)} />, false, "sm"));
    };

    const removeRequest = (id) => {
        console.log({id})
        let newData = requests.data.filter((req) => req.id != id);
        setRequests({ ...requests, data: newData });
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <DataTable
                    title={`لیست درخواست های پرداخت شده (${requests?.meta?.total ?? 0})`}
                    headData={HeaderData}
                    data={requests.data ?? []}
                    requestType={(item) => `${item.diet ? 'رژیم' : ''} ${item.exercise ? 'ورزشی' : ''}`}
                    payedType={(item) => item.payed_at ? "آنلاین" : "حضوری"}
                    action={(item) => (
                        <ActionButtons
                            functions={{
                                edit: () => history.push(`/dashboard/requests/add/3/${item.id}`),
                                show: () => history.push(`/dashboard/requests/${item.id}`),
                                remove: () => deleteModal(item)
                            }}
                            edit={user.role === "writer" || user.role === "superadmin" || user.role === "admin"}
                            show={user.role === "superadmin" || user.role === "admin" || user.role === "writer"}
                            remove={user.role === "superadmin" || user.role === "admin" || user.role === "writer"}
                        />
                    )}
                    currentPage={page}
                    route="/dashboard/requests"
                    lastPage={requests.meta?.last_page ?? 1}
                    changePage={(linkPage) => history.push(`/dashboard/requests?page=${linkPage}`)}
                />
            </Grid>
        </Grid>
    );
};

export default PayedRequest;
