import * as React from "react";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import axios from "axios";

import { showDialog, hideDialog } from "../../../Redux/actions/dialogActions";
import RecorderControls from "../../../Components/recorder-controls/RecorderControls";
import RecordingsList from "../../../Components/recordings-list/RecordingsList";
import Recorder from "../../../Components/Recorder/Recorder";
import UseRecorder from "../../../Hooks/UseRecorder";
import useRecordingsList from "../../../Hooks/UseRecordingsList";
import { useState } from "react";

const UploadResponceVoice = ({ requestId, addResponse }) => {
  const dispatch = useDispatch();
  const { recorderState, ...handlers } = UseRecorder();
  const { audio, audioBlob } = recorderState;
  const { recordings, deleteAudio } = useRecordingsList(audio);
  const [loading, setLoading] = React.useState(false);
  const [voice, setVoice] = React.useState(null);
  const [voiceData, setVoiceData] = React.useState(null);

  const submitForm = () => {
    // let file = new File([voice], "recording.mp3");
    const file = new File(voiceData.buffer, "me-at-thevoice.mp3", {
      type: voiceData.blob.type,
      lastModified: Date.now(),
    });

    const formData = new FormData();
    formData.append("audio", file, { type: "audio/mp3" });
    formData.append("request_id", requestId); //other param
    setLoading(true);
    axios
      .post("/requests/response-voice/", formData)
      .then((response) => {
        dispatch(hideDialog());
        addResponse(response.data.data);
        console.log(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
      });
  };

  const handleSetVoice = (buffer, blob) => {
    const blobURL = URL.createObjectURL(blob);
    setVoice(blobURL);
    setVoiceData({ buffer, blob });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h2" style={{ textAlign: "center" }}>
          ضبط صدا
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Recorder
          setNewVoice={(buffer, blob) => handleSetVoice(buffer, blob)}
        />
        {/* <RecorderControls recorderState={recorderState} handlers={handlers} />
        <RecordingsList audio={audio} /> */}
      </Grid>
      <Grid item xs={12}>
        {voice && <audio style={{width: "100%"}} src={voice} controls="controls" />}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          disabled={!voice}
          onClick={submitForm}
          onLoad={true}
        >
          {loading ? <CircularProgress /> : "ذخیره"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default UploadResponceVoice;
