import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useHistory, useLocation } from "react-router";

import HomeIcon from "@material-ui/icons/Home";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import AssignmentIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles({
    root: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: 9999,
        fontSize: 12,
        boxShadow: "0 0 10px 3px #0000004d",
        "& .Mui-selected": {
            color: "#559B1C",
            fontSize: "0.8rem",
        },
    },
});

const BottomTab = ({ routes }) => {
    const location = useLocation();
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = React.useState("/");
    const changeTab = (event, newValue) => {
        setValue(newValue);
        history.push(newValue);
    };
    return (
        <BottomNavigation value={value} onChange={changeTab} showLabels className={classes.root}>
            {routes.map((route) => {
                let Icon = route.icon;
                if (route.show) {
                    return (
                        <BottomNavigationAction
                            // className={location.pathname.includes(route.path) ? "Mui-selected" : ""}
                            value={route.path}
                            label={route.name}
                            icon={<Icon />}
                        />
                    );
                }
            })}
        </BottomNavigation>
    );
};

export default BottomTab;
