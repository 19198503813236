import {HIDE_ALERT, SHOW_ALERT} from "../actions/snackbarActions";

const alert = (state = {show: false, alertType: '', alertMsg: ''}, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return ({
                show: true,
                alertType: action.alertType,
                alertMsg: action.alertMsg
            });
        case HIDE_ALERT:
            return ({...state, show: false})
        default:
            return state;
    }
}

export default alert
