import {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_BTN_LOADING,
    HIDE_BTN_LOADING,
} from "../actions/loadingActions";

const initialState = {
    fullScreenLoading: false,
    btnLoading: false,
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            state.fullScreenLoading = true;
            return state;
        case HIDE_LOADING:
            state.fullScreenLoading = false;
            return state;
        case SHOW_BTN_LOADING:
            console.log(1)
            state.btnLoading = true;
            return state;
        case HIDE_BTN_LOADING:
            state.btnLoading = false;
            return state;
        default:
            return state;
    }
};

export default alert;
