import React from "react";
import { Grid, Typography, Divider, makeStyles } from "@material-ui/core";

const style = makeStyles({
  titleContainer: {
    margin: "10px 0"
  },
  title: {
    color: "#C6C847"
  },
  divider: {
    backgroundColor: "#C6C847",
    height: 3,
    width: 70,
    marginTop: 5
  }
})
const FooterTitle = ({ label }) => {
  const classes = style();
  return (
    <Grid item xs={12} className={classes.titleContainer}>
      <Typography className={classes.title} component="h3" variant="h3">{label}</Typography>
      <Divider className={classes.divider} />
    </Grid>
  );
};

export default FooterTitle;
