import React from "react";
import { Grid } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";

import InfoCard from "./InfoCard";
import LineChart from "../../../Components/Charts/LineChart";

const Dashboard = () => {
    const infoDatas = [
        {
            title: "کل درخواست ها",
            value: 6,
            icon: <RotateLeftIcon style={{ fontSize: "4rem" }} />,
            color: "#A03C78",
        },
        {
            title: "درخواست های تکمیل شده",
            value: 4,
            icon: <AllInclusiveIcon style={{ fontSize: "4rem" }} />,
            color: "#50CB93",
        },
    ];
    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={10}
                    style={{ margin: 0, width: "100%" }}
                >
                    {infoDatas.map((info) => (
                        <Grid item md={6} sm={12} xs={12}>
                            <InfoCard data={info} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12} style={{padding: 35}}>
                <LineChart />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
