const personalInfoInputs = (defaults) => {
    return {
        goal: {
            elementType: "text-field",
            elementConfig: {
                label: "هدفتان از شروع رژیم/تمرین چیست؟",
                variant: "outlined",
                value: defaults.goal ?? "",
                multiline: true,
                rows: 2,
            },
            gridConfig: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
    }}

    export default personalInfoInputs;