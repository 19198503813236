import React from "react";
import { Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core";
import moment from "moment";
import momentj from "moment-jalaali";
import translator from "../../../shared/translator";
import SingleField from "./SingleField";

const styles = makeStyles({
    root: {
        padding: 20,
    },
    rowContainer: {
        // border: "solid 1px #ccc",
        position: "relative",
        padding: 20,
    },
    rowTitle: {
        backgroundColor: "#ccc",
        // position: "absolute",
        padding: "10px 10px",
    },
});
const RequestDetiles = ({ title, data }) => {
    const classes = styles();
    const setValue = (value) => {
        if (value === true) {
            return "بله";
        }
        if (value === false) {
            return "خیر";
        }
        if (value == null || value == "") {
            return "ندارم";
        }
        return value;
    };

    const arrayToString = (array, field) => {
        let string = "";
        array.map((item, key) => {
            string = string + item[field] + "،";
        });
        return string;
    };
    return (
        <Grid container spacing={2} className={classes.rowContainer}>
            {data.diet ? <SingleField label="نوع رژیم" value={`${arrayToString(data.types, "name")}`} /> : ""}
            
            <SingleField label="وضعیت درخواست" value={`${setValue(data.status)}`} />
            <SingleField label="مبلغ پرداخت شده" value={`${setValue(data.amount)} تومان`} />
            <SingleField label="آلرژی" value={`${setValue(data.alergies)}`} />
            {data.exercise ? <SingleField label="در هفته میخواهم تمرین کنم" value={`${data.days_per_week} روز`} /> : ""}
            <SingleField label="رژیم" value={`${setValue(data.diet)}`} />
            <SingleField label="تمرین" value={`${setValue(data.exercise)}`} />

            <SingleField label="معلولیت" value={`${setValue(data.disablity)}`} />
            <SingleField label="بیماری" value={`${setValue(data.diseases)}`} />
            <SingleField label="جلسات تمرین در هفته" value={`${setValue(data.exercises)}`} />
            <SingleField label="قد" value={`${setValue(data.height)}`} />
            <SingleField label="وزن" value={`${setValue(data.weight)}`} />
            <SingleField label="دور باسن" value={`${setValue(data.hip)}`} />
            <SingleField label="دور شکم" value={`${setValue(data.waist)}`} />
            <SingleField label="دور مچ" value={`${setValue(data.wrist)}`} />
            <SingleField label="ulna" value={`${setValue(data.ulna)}`} />
            <SingleField label="دارو های مصرفی" value={`${setValue(data.medicines)}`} />
        </Grid>
    );
};

export default RequestDetiles;
