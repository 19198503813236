import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    borderRadius: 20,
  },
  media: {
    height: 180,
  },
  title: {
    position: "absolute",
    top: 0,
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#00000094",
    color: "#fff",
    flexDirection: "column",
  },
});

const VideoCat = ({ data, icon, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item md={3}>
      <Card className={classes.root}>
        <CardActionArea
          onClick={() => onClick ? onClick() : history.push(`/video-categories/${data.id}`)}
        >
          <CardMedia
            className={classes.media}
            image={data.image}
            title="Contemplative Reptile"
          />
          <div className={classes.title}>
            {icon}
            <Typography variant="h3" component="h3">
              {data.title}
            </Typography>
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default VideoCat;
