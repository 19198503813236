import { SET_USER_DATA, ADD_USER_DATA, LOGOUT } from "../actions/userActions";

const initialState = null;
const dialog = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            state = { ...action.data };
            return state;
        case ADD_USER_DATA:
            state = { ...state, ...action.data };
            return state;
        case LOGOUT:
            state = null;
            return state;
        default:
            return state;
    }
};

export default dialog;
