import axios from "axios";

export const getAllGroupsApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/requests/template-groups", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const addTemplateApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .post("/admin/requests/templates/", data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const removeTemplateApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .delete("/admin/requests/templates/", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });

export const getTemplatesApi = (data) =>
    new Promise((resolve, reject) => {
        axios
            .get("/requests/templates", { params: { ...data } })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error.response);
            });
    });
