const Inputs = (defaults) => {
    return {
        analysis_image: {
            elementType: "single-file-uploader",
            elementConfig: {
                label: "بارگذاری تصویر آنالیز ترکیب بدن",
            },
            gridConfig: {
                xs: 12,
            },
            value: defaults.analysis_image,
        },
    };
};

export default Inputs;
