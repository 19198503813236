import { Typography, makeStyles, Divider } from "@material-ui/core";
import React from "react";

const style = makeStyles({
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginBottom: 30,
  },
  title: {
    textAlign: "center",
    color: "#196B3C",
  },
  divider: {
    height: 3,
    width: 100,
    backgroundColor: "#196B3C",
    marginTop: 10,
  },
});
const Title = ({ label }) => {
  const classes = style();
  return (
    <div className={classes.titleContainer}>
      <Typography component="h2" variant="h2" className={classes.title}>
        {label}
      </Typography>
      <Divider className={classes.divider} />
    </div>
  );
};

export default Title;
