import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../Components/UI/Buttons/Button";
import { showAlert } from "../../../Redux/actions/snackbarActions";
import { addUserData } from "../../../Redux/actions/userActions";
import { addGoalApi, getGoalApi } from "../../../API/profile";
import useApi from "../../../Hooks/UseApi";
import styles from "../style";

const Diet = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = React.useState({ goal: user.goal });
  const inputChangedHandler = (e) => {
    console.log(e.target.checked);
    if (e.target.id === "confirm_profile") {
      setFormData({ ...formData, [e.target.id]: e.target.checked });
    } else {
      setFormData({
        ...formData,
        [e.target.id]: e.target.value ?? e.target.checked,
      });
    }
  };

  const [data, loading, callApi] = useApi();
  const submitForm = async () => {
    if (formData.confirm_profile) {
      const data = await callApi(
        addGoalApi,
        formData,
        "/dashboard/diet-requests"
      );
      if (data) {
        dispatch(addUserData({ confirm_profile: 1, goal: data.data.goal }));
      }
    } else {
      dispatch(showAlert("تایید پروفایل اجباری است", "error"));
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3} className={classes.pageContainer}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h2" className={classes.title}>
              هدف
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="goal"
              fullWidth
              variant="outlined"
              label="هدف از شروع و توضیحات مورد نیاز"
              value={formData.goal ?? ""}
              multiline
              rows={10}
              onChange={inputChangedHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="confirm_profile"
                  checked={formData.rules}
                  onChange={(e) => inputChangedHandler(e)}
                  name="checkedB"
                  color="primary"
                />
              }
              label="اطلاعات پروفایل خود را تایید می نمایم"
            />
          </Grid>
          <Grid item xs={12} className={classes.BtnContainer}>
            <Button
              disableElevation
              onClick={() => submitForm()}
              variant="contained"
              color="primary"
              title="ثبت تغییرات"
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Diet;