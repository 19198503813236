import React from "react";
import {
    makeStyles,
    Grid,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    articleRoot: {
        display: "flex",
        justifyContent: "center",
    },
    root: {
        maxWidth: 345,
        borderRadius: 20,
        width: "100%",
    },
    media: {
        height: 180,
    },
    description: {
        fontFamily: "IRANSans",
    },
    actionAria: {
        justifyContent: "flex-end",
    },
    showBtn: {
        color: "#196B3C",
        fontSize: 18,
        marginRight: 10,
    },
});

const Article = ({ data }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Grid item xs={12} md={3} className={classes.articleRoot}>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={data.image_path}
                        title={data.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {data.title}
                        </Typography>
                        <Typography
                            className={classes.description}
                            variant="p"
                            color="textSecondary"
                            component="p"
                        >
                            {data.extra_content}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.actionAria}>
                    <Button
                        className={classes.showBtn}
                        color="primary"
                        onClick={() => history.push(`/articles/${data.id}`)}
                    >
                        مشاهده
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default Article;
