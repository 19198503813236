import React from "react";
import { Button, Grid, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import AvatarMenu from "./AvatarMenu";

const style = makeStyles({
    loginContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 20
    },
    loginBtn: {
        borderRadius: 30,
        // padding: "0 30px",
        width: 100,
    },
    registerBtn: {
        borderRadius: "30px 0 0 30px",
        // padding: "0 30px",
        backgroundColor: "#FFE162",
        width: 100,
        "&:hover": {
            backgroundColor: "#D29D2B"
        }
    },
});
const Avatar = ({ itemsColor }) => {
    const user = useSelector((state) => state.user);
    const classes = style();
    const history = useHistory();
    return (
        <Grid container>
            <Grid item xs={12} className={classes.loginContainer}>
                {user ? (
                    <AvatarMenu itemsColor={itemsColor} user={user} />
                ) : (
                    <>
                        {/* <Button
                            className={classes.registerBtn}
                            disableElevation
                            variant="contained"
                            onClick={() => history.push("/auth/register")}
                        >
                            ثبت نام
                        </Button> */}
                        <Button
                            className={classes.loginBtn}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => history.push("/auth/login")}
                        >
                            ورود
                        </Button>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default Avatar;
