import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, makeStyles } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Title from "../UI/Title";
import MainContainer from "../Container/MainContainer";
import CarouselComment from "../Comments/CarouselComment";
import { comments } from "./data";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination]);

const style = makeStyles({
  swiper: {
    padding: "10px 10px 40px",
    "& .swiper-pagination-bullet": {
        width: 12,
        height: 12,
    }
  },
});
const Section6 = () => {
  const classes = style();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  console.log({isMobile})
  return (
    <MainContainer>
      <Grid item xs={12} style={{ margin: 0 }}>
        <Title label="پیام های کاربران" />
        <Swiper
          className={classes.swiper}
          spaceBetween={30}
          slidesPerView={isMobile ? 1 : 2}
          pagination={{ clickable: true }}
          onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {comments.map((comment) => (
            <SwiperSlide>
              <CarouselComment data={comment} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </MainContainer>
  );
};

export default Section6;
