import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import SingleAccordion from "./SingleAccordion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Accordions = ({ data, contentField, content }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((acc) => (
        <SingleAccordion data={acc} contentField={contentField} content={(item) => content(item)} />
      ))}
    </div>
  );
};

export default Accordions;
