import React from "react";
import { Grid } from "@material-ui/core";

import useApi from "../../Hooks/UseApi";
import { getAllPostsApi } from "../../API/product";
import MainContainer from "../Container/MainContainer";
import Title from "../UI/Title";
import Article from "../Article/Article";
import { articles } from "./data.js";

const Section4 = () => {
  const [data, loading, callApi] = useApi();
  const [Posts, setPosts] = React.useState([])
  React.useEffect(async () => {
      let data = await callApi(getAllPostsApi, {limit: 4});
      setPosts(data ? data.data : []);
  }, []);
  return (
    <MainContainer>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Title label="مقالات" />
        <Grid container spacing={3}>
          {Posts.map((article) => (
            <Article data={article} key={article.id} />
          ))}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default Section4;
