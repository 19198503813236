import React from 'react'

function HomeSetting() {
    return (
        <div>
            HomeSetting
        </div>
    )
}

export default HomeSetting
